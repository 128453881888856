import { FieldProps, FormContact1Props } from "../../../props";
import style from "../../form-contact.module.scss";
import React, { useState } from "react";
import { RHFInputText } from "~/components/non-editable/hook-form/input-text";
import { usePathname } from "next/navigation";
import { SubmitHandler, useForm } from "react-hook-form";
import { setCookie } from "cookies-next";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";

export const FormContactVariant02 = (props: FormContact1Props) => {
  const [toast, setToast] = useState<any>(null);
  const variationId = props?.variation?.currentVariationId || "01";
  const currentVariation = props?.variation?.variations?.find(
    (template: any) => template.id === variationId
  );

  let imageUrls: any = [];
  let randomImageUrl = "";

  if (currentVariation.id === "02") {
    const imageKeys = Object.keys(currentVariation);

    const imageKeysFiltered = imageKeys.filter(
      (key) =>
        currentVariation[key].fieldType === "image" || key.includes("srcImg")
    );

    imageUrls = imageKeysFiltered.map((key) => currentVariation[key].value);

    const randomIndex = Math.floor(Math.random() * imageUrls.length);

    randomImageUrl = imageUrls[randomIndex];
  }

  const pathname = usePathname();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setCookie("@user-datalayer", {
      fullName: data.name,
      email: data.email,
      phone: data.cellphone,
    });

    data.workflowId = currentVariation?.n8nWorkflowId?.value;
    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      data.originUrl = `${protocol}//${hostname}${
        port ? ":" + port : ""
      }${pathname}`;
    }

    setToast({
      message: "Dados enviados com sucesso! Aguarde nosso contado.",
      type: "success",
    });

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } catch (error) {
      console.error(error);
    }
    reset();
    //todo: notificar ok
    setToast(null);
  };

  return (
    <div
      className={`${style["variant2__container"]} variant2__container-${props.id}`}
      style={{ backgroundImage: `url(${randomImageUrl})` }}
    >
      <div
        className={`${style["variant2__neutral-bg"]} variant2__neutral-bg-${props.id}`}
      />
      <div
        className={`${style["variant2__content"]} variant2__content-${props.id}`}
      >
        <div
          className={`${style["variant2__form"]} variant2__form-${props.id}`}
        >
          {typeof currentVariation.formName.value != "undefined" && (
            <h1
              className={`${style["variant2__form__title"]} variant2__form__title-${props.id}`}
            >
              {currentVariation.formName.value}
            </h1>
          )}

          {typeof currentVariation.description.value != "undefined" && (
            <div
              dangerouslySetInnerHTML={{
                __html: currentVariation.description.value,
              }}
            />
          )}
        </div>
        <div
          className={`${style["variant2__form__card"]} variant2__form__card-${props.id}`}
        >
          <form
            className={`${style["variant2__form__card__content"]} variant2__form__card__content-${props.id}`}
            onSubmit={(e) => {
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
          >
            {currentVariation &&
              currentVariation.fields &&
              currentVariation.fields.value &&
              currentVariation.fields.value.map(
                (item: FieldProps, index: number) => {
                  if (item.maxLength && item.maxLength < 150) {
                    return (
                      <React.Fragment key={item.name + index + Math.random()}>
                        <RHFInputText
                          placeholder={item.placeholder}
                          key={item.name}
                          name={item.name}
                          label={item.label}
                          type={item.type}
                          register={register}
                          props={{
                            required: item.required,
                            minLength: item.minLength as number,
                            maxLength: item.maxLength as number,
                          }}
                        />
                        {errors[item.name] && (
                          <span
                            key={item.name}
                            className={`${style["variant2__form__error-text"]} variant2__form__error-text-${props.id}`}
                          >
                            {errors[item.name]?.message as string}
                          </span>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <div key={item.name + index + Math.random()}>
                        <label
                          htmlFor={item.name}
                          className={`${style["variant2__form__card__label"]} variant2__form__card__label-${props.id}`}
                        >
                          {item.label}
                        </label>
                        <textarea
                          id={item.name}
                          cols={30}
                          rows={3}
                          placeholder={item.placeholder}
                          key={item.name}
                          className={`${style["variant2__form__card__textarea"]} variant2__form__card__textarea-${props.id}`}
                          {...register(item.name, {
                            required: {
                              value: item.required as boolean,
                              message: "Campo obrigatório",
                            },
                            minLength: {
                              value: item.minLength as number,
                              message: `Tamanho mínimo: ${item.minLength}`,
                            },
                            maxLength: {
                              value: item.maxLength as number,
                              message: `Tamanho máximo: ${item.maxLength}`,
                            },
                          })}
                        />
                      </div>
                    );
                  }
                }
              )}
            <button
              type="submit"
              className={`${style["variant2__form__card__button"]} variant2__form__card__button-${props.id}`}
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
      {toast && <PlatformsToast message={toast.message} type={toast.type} />}
    </div>
  );
};
