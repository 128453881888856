import { FieldParams } from "~/constants";
import { ComponentsBaseProps, Vehicle } from "~/types";

export enum ContactActionType {
  whatsApp = "whatsApp",
  cellphone = "cellphone",
}

export enum ContactActionButtonType {
  default = "default",
  pulsing = "pulsing",
}

export interface ContactActionProps {
  actionType: ContactActionType;
  buttonType: ContactActionButtonType;
  required: boolean;
  label: string;
  placeholder: string;
  receiver: string;
  link: string;
  regex: string | null;
}

export interface InventoryOptionsProps {
  showDataLayerForm: FieldParams;
  useCustomDetails?: FieldParams;
  showMakeFilter: FieldParams;
  textAboveVehiclePrice: FieldParams;
  workflowId: FieldParams;
  galeryType: FieldParams;
  showVehiclePrice: FieldParams;
  showVehicleKm: FieldParams;
  quantityPerPage: FieldParams;
  showModelFilter: FieldParams;
  showCategoryFilter: FieldParams;
  showYearFilter: FieldParams;
  showPriceFilter: FieldParams;
  showKmFilter: FieldParams;
  showSort: FieldParams;
  showSortByYear: FieldParams;
  showSortByPrice: FieldParams;
  showSortByKm: FieldParams;
  showPagination: FieldParams;
  increaseVehiclePriceInPercentage: FieldParams;
  increaseVehiclePriceAmount: FieldParams;
  emailToSendLead: FieldParams;
}

export interface InventoryProps extends ComponentsBaseProps {
  shouldRenderDetail?: boolean;
  showPrice?: boolean;
  contactActions: ContactActionProps[];
  components?: string[];
  data: {
    vehicle?: Vehicle;
    vehicleList?: Vehicle[];
    filteredList: Vehicle[];
    asideList?: Vehicle[];
    shouldRenderDetail: boolean;
    site: {
      subdomain: string;
      customDomain?: string;
      preferences: WebsitePreference[];
    };
    page?: {
      currentSlug: string[];
    };
  };
  options: InventoryOptionsProps;
  cardVariants: FieldParams;
  detailsVariants: FieldParams;
}

export interface WebsitePreference {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  value: string;
  type: WebsitePreferenceType;
  siteId: string;
}

export enum WebsitePreferenceType {
  localSeoSlug = "localSeoSlug",
  inventoryPathName = "inventoryPathName",
}
