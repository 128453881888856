// Para popular o array abaixo, foi feita a consulta no resources e nomes que continham "-", "/", "+", foram substituidos por " ", para que entre na categoria de nomes compostos

export const compositeMotorcycleModels = [
  "Sxt 16.5",
  "Dakar 30.0",
  "Elefant 16.5",
  "Elefant 27.5",
  "Elefantré 16.5",
  "Elefantré 16.5 Es",
  "Elefantré 30.0",
  "Elefantré 30.0 Es",
  "Ex 27.5",
  "Sst 13.5",
  "Sxt 27.5 E",
  "Sxt 27.5 Ex",
  "Tchau 50",
  "City 50",
  "City 90",
  "Super City 125",
  "Force 50",
  "Force 90",
  "Junior 50",
  "Super City 150",
  "Kahena St 1600",
  "Loncin Lx 250",
  "Area 51",
  "Rs 250",
  "Rs 50",
  "Rsv Mille",
  "Rx 50",
  "Scarabeo Brigth",
  "Scarabeo Classic",
  "Scarabeo De Luxe",
  "Sr Racing",
  "Sr Stealth",
  "Sr Www",
  "Dorsoduro 750",
  "Scarabeo 200",
  "Scarabeo 500",
  "Rsv 1000 R",
  "Rsv4 Factory",
  "Rs 125",
  "Scarabeo 300",
  "Rsv4 R",
  "Sxv 450",
  "Scarabeo 50T",
  "Sr 150",
  "Az 1",
  "Classic 150",
  "Dominar 160",
  "Dominar 200",
  "Dominar 400",
  "Rr 2T 300",
  "Rr 200",
  "Rr 2T 250",
  "Rr 300 Racing",
  "Rr 4T 350",
  "Rr 4T 390",
  "Rr 4T 430",
  "Db6 Delirio",
  "Db6r Delirio",
  "Db8 Biposto",
  "Tesi 3D",
  "Tesi 3D Evo",
  "Db7 Oronero",
  "F 650 Gs Dakar",
  "K 1200 S",
  "K 1200 Lt",
  "F 650 Gs",
  "R 1200 Rt",
  "R 1200 Gs",
  "R 1200 Gs Adventure",
  "K 1200 R",
  "F 650",
  "F 650 Cs",
  "K 1100 Rs",
  "K 1200 Gt",
  "K 1200 Rs",
  "R 1100 Gs",
  "R 1100 Gs 75",
  "R 1100 R",
  "R 1100 Rs",
  "R 1100 Rt",
  "R 1100 S",
  "R 1150 Gs",
  "R 1150 Gs Adventure",
  "R 1150 R",
  "R 1150 R Rockster",
  "R 1150 Rs",
  "R 1150 Rt",
  "R 1200 C",
  "R 1200 C Avantgard",
  "R 1200 C Classic",
  "R 1200 C Independent",
  "R 1200 Cl",
  "F 800 S",
  "K 1100 Lt",
  "G 650 Xmoto",
  "G 650 Xchallenge",
  "K 75",
  "G 650 Xcountry",
  "R 100 Rs",
  "K 100",
  "R 1200 Gs Hp2",
  "G 450 X",
  "F 800 Gs",
  "K 1300 R",
  "K 1300 S",
  "K 1300 Gt",
  "K 100 Rs",
  "F 800 R",
  "S 1000 Rr",
  "G 650 Gs",
  "K 1600 Gtl",
  "K 1600 Gt",
  "R 1200 R Classic",
  "R 1200 Gs Adventure Triple Black",
  "R 1200 Gs Triple Black",
  "R 100 Gs Paris Dakar",
  "R51 3",
  "K 75 S",
  "R80g S",
  "R60 0",
  "G 650 Gs Sertão",
  "F 800 Gs Adventure",
  "C 600 Sport",
  "S 1000 R",
  "R Ninet",
  "S 1000 Rr Hp4 Competition",
  "K 1600 Gtl Exclusive",
  "C 650 Gt Touring",
  "F 800 R Ride",
  "R 1200 Gs Sport",
  "S 1000 Xr",
  "G 310 R",
  "R 1200 Gs Premium +",
  "R 1200 Gs Sport +",
  "R 1200 Gs Adventure Premium +",
  "F 700 Gs",
  "R 1200 Gs Rallye",
  "F 700 Gs Sport",
  "K 1600 Bagger",
  "G 310 Gs",
  "R 1200 Gs Adventure Premium + Rallye",
  "R 1200 Gs Adventure Premium + Exclusive",
  "Hp4 Race",
  "F 750 Gs Sport",
  "F 750 Gs Premium",
  "F 850 Gs Sport",
  "F 850 Gs Premium",
  "R 1200 Gs Premium",
  "F 850 Gs Adventure Sport",
  "F 850 Gs Adventure Premium",
  "R 1250 Gs Sport",
  "R 1250 Gs Premium",
  "R 1250 Gs Adventure Premium + Exclusive",
  "R 1250 Gs Adventure Premium",
  "S 1000 Rr M",
  "R 1250 Rt",
  "R 1250 Gs Premium 40 Anos",
  "R 1250 Rt Premium",
  "R 1250 Gs Triple Black",
  "R 1250 Gs Adventure Triple Black",
  "C 400 Gt",
  "R 1250 Gs Premium Brasil Special Edition",
  "R 1250 Gs Adventure Premium 40 Anos",
  "R 1250 Rt Triple Black",
  "R 1250 Rt Mineral White",
  "R 18",
  "F 900 R",
  "Elegant 50",
  "Ft 125 Fosti",
  "Jt 100 Jaguar",
  "Jt 50 Jaguar",
  "Lightning City Cross Xb9sx",
  "Ulysses Xs 12X 1200",
  "Lightning Xb12ss",
  "Lightning Xb12s",
  "Ulysses Xb12x",
  "Firebolt Xb12r",
  "Ulysses Xb12x Touring",
  "Ulysses Xb12xp",
  "Lightning Xb9sx",
  "Lightning Super Tt Xb12stt",
  "Jbr 125",
  "Spirit Se 50",
  "Krc50 F5",
  "Krc50 S5",
  "Krc50 Sport",
  "Krc50 Sport 120",
  "Racy 125",
  "Gs 125",
  "Savage 150",
  "Krc50 F5 Plus",
  "Mito 125",
  "Navigator 1000",
  "V Raptor 1000",
  "Canyon 500",
  "Elefant 750",
  "Elefant 900",
  "Grand Canyon 900",
  "Planet 125",
  "Roadster 200",
  "W 16 600",
  "Canyon 600",
  "Super City 125",
  "Altino 100",
  "Message 50",
  "Vc 125 Advanced",
  "Vf 125",
  "Vl 125 Daystar",
  "Vs 125",
  "Vt 125 Magma",
  "Super 100",
  "Speed 150",
  "Laser 150",
  "Kansas 150",
  "Kansas 250",
  "Smart 125",
  "Zig 110",
  "Apache Rtr 150",
  "Super 50",
  "Citycom 300I",
  "Zig +",
  "Speed 150 Cargo",
  "Zig 50",
  "Riva 150",
  "Roadwin 250R",
  "Smart Joy 125",
  "Next 250",
  "Horizon 250",
  "Riva 150 Cargo",
  "Maxsym 400I",
  "Cityclass 200I",
  "Fiddle Iii",
  "Horizon 150",
  "Citycom S 300I",
  "Next 300",
  "Citycom S 300I Abs",
  "Apache Rtr 200",
  "Nh 190",
  "Hd 300",
  "Citycom Hd 300I",
  "Citycom 300I Cbs",
  "Cruisym 150",
  "Cruisym 300",
  "Apache Rtr 200 Abs",
  "Nh 300 Abs",
  "Rev 200",
  "125 Sprint",
  "Dy 150",
  "Dy 110 6",
  "Dy 125 8",
  "Dy 125T 10",
  "Dy 150 7",
  "Dy 150 9",
  "Dy 150Gy",
  "Dy 250 2",
  "City 150",
  "Senda 125R",
  "Predator Lc",
  "Gpr 50R",
  "Predador Ar",
  "996 S",
  "999 R Xerox",
  "Monster 600",
  "Monster 620",
  "Monster 695",
  "Monster 900",
  "Monster 937",
  "Monster S2 R 1000",
  "Monster S4 916",
  "Monster S4 R 996",
  "Monster S4 Rs Testastretta",
  "Multistrada 1000",
  "Multistrada 620",
  "Sportclassic Paul Smart 1000 Le",
  "Sport Classic 1000",
  "Ss 900",
  "St 2 900",
  "St 4 900",
  "Superbike 1098",
  "Hypermotard 1100",
  "Superbike 1098 S",
  "Multistrada 1100",
  "Monster 1100 S",
  "Monster 696",
  "Multistrada 1200",
  "Multistrada 1200S",
  "Desmosedici Rr",
  "Superbike 1198 S",
  "848 Evo",
  "Superbike 1198",
  "Hypermotard 796",
  "Streetfighter S",
  "Streetfighter 848",
  "Superbike 848",
  "Hipermotard 1100 Evo",
  "Monster 1100",
  "Multistrada 1200S Touring",
  "Diavel Dark",
  "Diavel Carbon",
  "Superbike 848 Evo",
  "749 S",
  "749 R",
  "Superbike 1198 Sp",
  "Monster 796",
  "Superbike 1199 Panigale",
  "Superbike 1199 Panigale S Tricolore",
  "Superbike 1199 Panigale R",
  "Superbike 1199 Panigale S",
  "Diavel Cromo",
  "Multistrada 1200 Pikes Peak",
  "Superleggera 1199",
  "Monster 1200 S",
  "Monster 1200",
  "Multistrada 1200 Abs",
  "Superbike 1199 Panigale S Senna",
  "Hyperstrada 821",
  "Superbike 1199 Panigale Abs",
  "Diavel Abs",
  "Hypermotard 821",
  "Monster 821",
  "Scrambler Icon",
  "Scrambler Urban Enduro",
  "Scrambler Classic",
  "Scrambler Full Throttle",
  "Superbike 1299 Panigale Abs",
  "Mh900 Evoluzione",
  "Monster 821 Dark",
  "Superbike 1299 Panigale S",
  "Scrambler Sixty2",
  "Xdiavel S",
  "Multistrada 1200 Enduro",
  "Superbike 959 Panigale",
  "Multistrada 1200 Sport",
  "Xdiavel Dark",
  "Multistrada 950 Abs",
  "Scrambler Custom",
  "Multistrada 1200 Enduro Le",
  "Monster 797",
  "Supersport S",
  "Multistrada 1260",
  "Multistrada 1260S",
  "Multistrada 950 S",
  "Diavel 1260 S",
  "Panigale V4 S",
  "Panigale V4 R",
  "Superleggera V4",
  "Xdiavel 1260 S",
  "Streetfighter V4 S",
  "Multistrada 1200 V4 S",
  "Monster 937",
  "Multistrada V2 S",
  "One Racing",
  "250 Elite",
  "150 Adventure",
  "49 Air",
  "Fy 250",
  "Fy 150 3",
  "Fy 125 19",
  "Fy 100 10A",
  "Fy 125 20 Sachs",
  "Fy 125Ey 2",
  "Fy 125Y 3",
  "Fy 150T 18",
  "Fy 125T 13A",
  "Gr 150P",
  "Gr 150T3",
  "Gr 125T3",
  "Gr 08T4",
  "Gr 125U",
  "Gr 250V",
  "Gr 125Z",
  "Gr 150U",
  "Gr 125St",
  "Gr 150St",
  "Gr 500Atv",
  "Gr 250T3",
  "Gr 125T3 Tina",
  "Gr 150Ti",
  "Gr 125S",
  "Txt 280 Pro",
  "Ec 250",
  "Ec 125",
  "Ec 200",
  "Ec 300",
  "Ec 450",
  "Mc 250",
  "Pampera 250",
  "Txt Boy 50",
  "Ec 400 Fse",
  "Ec 50 Rookie",
  "Pampera 125",
  "Tx Boy 50",
  "Txt 200 Contact",
  "Txt 280 Contact",
  "Txt 321 Contact",
  "Txt 50 Rookie",
  "Ec 200 Hobby",
  "Txt 125 Pro",
  "Pampera 450",
  "Fsr 450",
  "Sm 450",
  "Ec 250 F",
  "Txt Pro 250",
  "Ec 300 F",
  "Easy 110",
  "Runner 125",
  "Safari 150",
  "Sport 150",
  "Hb 125 9",
  "Hb 150T",
  "Hb 110 3",
  "Hb 150",
  "Hb 50Q",
  "Lindy 125 Cbs",
  "Chopper Road 150",
  "Dk 150",
  "Dk 150S Fi",
  "Vr 150",
  "Dr 160 Fi",
  "Dk 150 Cbs",
  "Master Ride 150",
  "Nk 150 Abs",
  "Dk 160",
  "Sportster Xl 883",
  "Sportster Xl 883 Custom",
  "Softail Fat Boy",
  "Sportster Xl 883 R",
  "Softail Deluxe",
  "Road King Classic",
  "Fat Boy Screamin Eagle",
  "Road King Custom",
  "Ultra Electra Glide Classic",
  "V Rod Screamin Eagle",
  "V Rod",
  "Electra Glide Classic",
  "Street Rod",
  "Springer Screamin Eagle",
  "Night Rod Special",
  "Softail Deuce",
  "Dyna Convertible",
  "Dyna Low Rider",
  "Dyna Low Rider Convertible",
  "Dyna Super Glide",
  "Dyna Wide Glide",
  "Eg Road King Fuel Injection",
  "Electra Glide Special",
  "Electra Glide Ultra Fuel Injection",
  "Softail Heritage Custom",
  "Softail Heritage Classic",
  "Softail Heritage Springer",
  "Night Train",
  "Road King",
  "Softail Classic",
  "Softail Custom",
  "Softail Special",
  "Softail Springer",
  "Softail Standard",
  "V Rod Sport",
  "Sportster 1200 Xl Standard",
  "Sportster 1200 Xl Custom C",
  "Sportster 1200 Xl Custom",
  "Sportster Xl 883 Low",
  "Sportster 883 Xlh Hugger",
  "Sportster Xl 883 Standard",
  "Sportster 1200 Xl Sport",
  "Sportster 1200 Xlh",
  "Sportster Xr 1200",
  "Sportster Xl 1200 N",
  "Screaming Eagle Softail Springer",
  "Softail Rocker",
  "Dyna Super Glide Custom",
  "V Rod Muscle",
  "Street Glide",
  "Softail Covertible",
  "Sportster Xl 883N Iron",
  "Sportster Xr 1200X",
  "Fat Boy Flstf",
  "Sportster 883 Roadster",
  "Sportster Iron 883",
  "Softail Fat Boy Special",
  "Rocker C",
  "V Rod 10Th Anniversary Edition",
  "Softail Blackline",
  "Dyna Fat Bob",
  "Dyna Switchback",
  "Electra Glide Ultra Limited",
  "Sportster 1200 Custom",
  "Sportster 1200 Custom Ca Limited",
  "Sportster 1200 Custom Cb Limited",
  "V Rod Night Rod Especial",
  "Sportster 1200 Xl Nightster",
  "Electra Glide Classic Flhtci",
  "Cvo Breakout",
  "Cvo Limited",
  "Fat Boy 110Th Special Anniversary Edition",
  "Cvo Ultra Classic Electra Glide",
  "Ultra Limited",
  "Sportster Forty Eight",
  "Dyna Super Glide Fxd",
  "Dyna Street Bob",
  "Softail Breakout",
  "Cvo Street Glide",
  "Street Glide Special",
  "Sportster Xl 1200 X",
  "Softail Slim S",
  "Road Glide Ultra",
  "Street 500",
  "Street 750",
  "Sportster Roadster",
  "Softail Fxst",
  "Hydra Glide",
  "Softail Fat Bob 114",
  "Softail Slim",
  "Softail Fat Boy 114",
  "Softail Breakout 114",
  "Road Glide Special",
  "Road King Special",
  "Cvo Road Glide",
  "Softail Fat Boy 114 115Th Anniversary",
  "Street Glide Special 115Th Anniversary",
  "Ultra Limited 115Th Anniversary",
  "Sportster Forty Eight 115Th Anniversary",
  "Cvo Limited Anniversary",
  "Bad Boy",
  "Sportster Iron 1200",
  "Softail Street Bob",
  "Sport Glide",
  "Softail Fxdr 114",
  "Softail Fat Bob 107",
  "Low Rider S",
  "Road Glide Limited",
  "Softail Heritage Classic 114",
  "Road King Police",
  "Pan America 1250 S",
  "Tri Glide Ultra Classic",
  "Sportster Nightster Special",
  "Sportster S",
  "Low Rider St",
  "Nightster Special",
  "Legion 125",
  "Cg 125 Fan",
  "Biz 125 Ks",
  "Biz 125 Es",
  "Cg 150 Job",
  "Cg 150 Titan Ks",
  "Biz 125 +",
  "Cg 150 Titan Es",
  "Nxr 150 Bros Ks",
  "Cg 150 Titan Esd",
  "Nxr 150 Bros Es",
  "Nxr 150 Bros Esd",
  "Cg 150 Titan Edição Especial",
  "Crf 230F",
  "Cbx 250 Twister",
  "Xr 250 Tornado",
  "Nx4 Falcon",
  "Cb 600F Hornet",
  "Shadow 750",
  "Cbr 600Rr",
  "Cbr 1000Rr Fireblade",
  "Gl 1800 Gold Wing",
  "Vtx 1800C",
  "C 100 Biz",
  "C 100 Biz Es",
  "C 100 Biz+",
  "C 100 Dream",
  "Cb 450 Dx",
  "Cb 500",
  "Cbr 1000F",
  "Cbr 1100Xx Super Blackbird",
  "Cbr 450",
  "Cbr 450 Sr",
  "Cbr 600F",
  "Cbr 900Rr Fireblade",
  "Cbx 200 Strada",
  "Cbx 750 Four Indy",
  "Cbx 750R",
  "Cg 125",
  "Cg 125 Cargo",
  "Cg 125 Titan",
  "Cg 125 Titan Es",
  "Cg 125 Titan Ks",
  "Cg 125 Titan Kse",
  "Cg 125 Today",
  "Cg 150 Sport",
  "Cr 80",
  "1500 Gold Wing",
  "750 Magna",
  "Nx 150",
  "Nx 200",
  "Nx 350 Sahara",
  "Nxr 125 Bros Es",
  "Nxr 125 Bros Ks",
  "Shadow 1100 Ac",
  "Shadow 1100 Ouro",
  "Shadow 750 Am",
  "Shadow 1100 Vt",
  "Ch 125R Spacy",
  "Super Hawk 1000",
  "Shadow 600C Vt",
  "Xl 125 Duty",
  "Xl 125S",
  "Xlr 125",
  "Xlr 125 Es",
  "Xlx 250R",
  "Xlx 350R",
  "Xr 200R",
  "Xr 650",
  "Cr 125",
  "Cr 250",
  "Cb 450",
  "Cb 400",
  "Cb 750 Four",
  "Xl 1000V Varadero",
  "Pop 100",
  "Cbr 900Rr",
  "Cb 50",
  "Valkyrie Rune",
  "Cbx 750 Four",
  "Interceptor V Four",
  "Crf 250R",
  "Cb 900F Hornet",
  "Crf 250X",
  "Cbr 1000Rr Repsol",
  "Cb 1300 F Super Four",
  "Cb 400 Four",
  "Cb 400 Ii",
  "Helix 250",
  "Cbr 929Rr",
  "Cbr 954Rr Fireblade",
  "Xl 250R",
  "Cbx 1050",
  "Gl 1000 Gold Wing",
  "Cg 125 Ml",
  "Cb 550 Four",
  "Crf 450R",
  "Crf 450X",
  "Cb 500 Four",
  "Trx 350 Fourtrax 4X2",
  "Trx 350 Fourtrax 4X4",
  "Xr 400R",
  "Trx 420 Fourtrax 4X2",
  "Trx 420 Fourtrax 4X4",
  "Cbx 150 Aero",
  "Xr 650L",
  "1200 Gold Wing",
  "Cb 750 Kz",
  "Cb 900F Bold´Or",
  "Trx 350 Fourtrax Fm 4X4",
  "St 1100 Pan European",
  "Cg 125 Fan Ks",
  "Cg 125 Fan Es",
  "Cb 350K",
  "Xrv 750 Africa Twin",
  "Cb 360",
  "Cg 150 Titan Mix Ks",
  "Cg 150 Titan Mix Es",
  "Cg 150 Titan Mix Esd",
  "Cg 125 Cargo Ks",
  "Cg 125 Cargo Es",
  "Crf 50F",
  "Crf 150R",
  "Nx 650 Dominator",
  "Cb 300R",
  "Xre 300",
  "Lead 110",
  "Cg 150 Fan Esi",
  "Nxr 150 Bros Mix Esd",
  "Nxr 150 Bros Mix Es",
  "Nxr 150 Bros Mix Ks",
  "Cg 150 Titan Mix Ex",
  "Xl 600V Transalp",
  "Xr 250R",
  "Vf750f Interceptor",
  "Cb 1000   Big One",
  "Cg 150 Fan",
  "Cb 1000R Abs",
  "Vfr 1200F",
  "Vt 1300 Cx",
  "Vt 1300 Cr",
  "Vt 1300 Cs",
  "Lead 110 Es",
  "Biz 125 Ex",
  "Xl 700V Transalp",
  "St 1300 Pan European",
  "Vtx 1300 S",
  "Nc 700X",
  "Cbr 250R",
  "Crf 150F",
  "Vf700f Interceptor",
  "Xr 100",
  "Vfr 400R",
  "Vtr 1000",
  "Cb 750K",
  "Cx 500Vt",
  "Nx 400I Falcon",
  "Biz 100 Es",
  "Biz 100 Ks",
  "Cb 500 X Abs",
  "Cb 450 E",
  "Vf1000f Interceptor",
  "Vfr 1200X",
  "Crf 250L",
  "Shadow 1100 Aero",
  "Cbr 1000Rr",
  "Cb 450 Tr",
  "St 70",
  "Cb 500F",
  "Trx 700Xx Sportrax",
  "Cbr 500R",
  "Cg 125 Fan Esd",
  "Turuna 125",
  "Cg 150 Titan Ex",
  "Cb 500 X",
  "Cg 150 Fan Esdi",
  "Ctx 700N Abs",
  "Vtx 1300",
  "Nc 700X Abs",
  "Cg 125 Cargo Esd",
  "Cg 150 Cargo",
  "Cg 125 Fan Job Ks",
  "Cb 450 P",
  "Cb 650 F",
  "Cb 650 F Abs",
  "Cbr 650F",
  "Cbr 650F Abs",
  "Cg 150 Chimpa Tmc Café Racer Martini",
  "Cg 150 Chimpa Tmc Café Racer Jps",
  "Cg 150 Chimpa Tmc Café Racer Gulf",
  "Cg 150 Chimpa Tmc Scrambler",
  "Cg 125 Chimpa Tmc Café Racer",
  "Nxr 160 Bros Esdd",
  "Nxr 160 Bros Esd",
  "Xre 300 Abs",
  "Cb 300R Abs",
  "Nc 750X",
  "Cg 150 Start Es",
  "Cb 500F Abs",
  "Cbr 500R Abs",
  "Nc 750X Abs",
  "Cbr 600Rr Abs",
  "Cbr 1000Rr Abs",
  "Pcx Dlx",
  "Cg 150 Cargo Esd",
  "Cb 1000R",
  "Crf 110F",
  "Xl 700V Transalp Abs",
  "Pop 110I",
  "Cg 160 Titan Ex",
  "Cg 160 Titan Esdi",
  "Cg 160 Fan",
  "Xl 250 Motosport",
  "Cb Twister",
  "Cb Twister Abs",
  "Sh 300I",
  "Crf 1000L Africa Twin Abs",
  "Gl 1800 Gold Wing 40Th Anniversary Edition",
  "Nrx1800 Ea Rune",
  "Nxr 160 Bros",
  "Biz 110I",
  "Cg 125I Fan",
  "Cg 160 Start Es",
  "Gl 1100 Gold Wing",
  "Xre 190 Abs",
  "Cg 125I Cargo",
  "Cg 160 Cargo",
  "Crf 1000L Africa Twin Abs Te",
  "Xre 300 Adventure",
  "Sh 150I",
  "Cb 650 Custom",
  "Pacific Coast 800",
  "X Adv",
  "Gl 1800 Gold Wing Tour",
  "Biz 125I",
  "Pcx Sport",
  "Sh 150I Dlx",
  "Xr 650 R",
  "Crf 250Rx",
  "Crf 450Rx",
  "Crf 250F",
  "Cg 160 Titan Special Edition 25 Anos",
  "Sh 300I Sport",
  "Elite 125I",
  "Crf 1000L Africa Twin Adventure Sports",
  "Crf 1000L Africa Twin Adventure Sports Te",
  "Pcx Abs",
  "Nxr 160 Bros Special Edition Abs",
  "Cb Twister Special Edition Abs",
  "Xre 190 Special Edition Abs",
  "Cg 160 Titan S",
  "Cb 650 R Abs",
  "Cbr 650R Abs",
  "Nxr 160 Bros Se",
  "Xre 190 Se",
  "Cg 160 Titan",
  "Crf 1100L Africa Twin",
  "Crf 1100L Africa Twin Adventure Sports Es",
  "Crf 1100L Africa Twin Dct",
  "Crf 1100L Africa Twin Adventure Sports Es Dct",
  "Xre 300 Rally",
  "Gl 1200 Gold Wing",
  "Xre 190 Adventure",
  "Forza 350",
  "Nc 750X Abs Dct",
  "Pcx 160 Cbs",
  "Pcx 160 Abs",
  "Pcx 160 Dlx",
  "Cb 750 Hornet",
  "Cb 300F Twister Abs",
  "Cb 300F Twister Cbs",
  "Cbr 1000Rr R Fireblade Sp",
  "Fe 550E",
  "Fe 400",
  "Fe 501",
  "Te 125",
  "Fe 570",
  "Fe 250",
  "Te 610",
  "Te 510",
  "Cr 125",
  "Te 450",
  "Sm 610",
  "Sm 510R",
  "Wr 250",
  "Sm 450R",
  "Wre 125",
  "Cr 250",
  "Husqy Boy J",
  "Husqy Boy R",
  "Husqy Boy S",
  "Tc 570",
  "Te 400",
  "Te 410",
  "Te 410E",
  "Te 570",
  "Te 610E",
  "Wr 360",
  "Te 477",
  "Fc 450",
  "Vitpilen 701",
  "701 Supermoto",
  "Chief Vintage",
  "Chief Classic",
  "Chief Springfield",
  "Chieftain Dark Horse",
  "Chief Dark Horse",
  "Scout Bobber",
  "Action 100 Es",
  "Action 100 Esa",
  "One 125 Es",
  "One 125 Esd",
  "One 125 Ex",
  "Moving 125 Es",
  "Moving 125 Esd",
  "Vintage 150",
  "Matrix 150",
  "Brave 70",
  "Brave 150",
  "Brave 450R",
  "Warrior 250",
  "Warrior 400 4X4",
  "Warrior 700 4X4",
  "Pro Street",
  "Spyder F1",
  "Atlantic 150",
  "Pegasus 150",
  "Tr 150",
  "Orbit 150",
  "Atlantic 250",
  "Jonny 50",
  "Hype 110",
  "Hype 50",
  "Quick 150",
  "125 Top",
  "125 K Top",
  "250 Dual",
  "Lj 16",
  "125 K Log",
  "Flash 150",
  "Win 110",
  "Comet Phase 2",
  "Mirage Premier",
  "Comet 650R",
  "Mirage Power",
  "Cruise 125",
  "Cruiser Ii 125 Classic",
  "Ero Plus 125",
  "Gf 125 Speed",
  "Magik 125",
  "Fx 110 Midas",
  "Prima 50",
  "Prima Rally 50",
  "Rx 125",
  "Super Cab 100",
  "Super Cab 50",
  "Tn 125",
  "Seta 125",
  "Comet Gt R",
  "Way 125",
  "Seta 150",
  "Comet 650R Efi",
  "Comet Gtr Efi",
  "Mirage 250",
  "Comet Efi",
  "Mirage 650 Efi",
  "Prima Electra",
  "Prima 150",
  "Prima 150 Gts",
  "Comet 150",
  "Comet Gt 250 Efi",
  "Mirage 150",
  "Crz 150",
  "Crz 150 Sm",
  "Comet 250",
  "Comet Gt 650R",
  "Comet Gt 250R",
  "Mirage 650",
  "Soft 50",
  "Comet Gt 650",
  "Comet Gt 250",
  "Prima 500",
  "Win Elektra",
  "Ninja 250R",
  "Ninja Ex 500",
  "Ninja Zx 6R 636",
  "Ninja Zx 6R",
  "Ninja Zx 7R",
  "Ninja Zx 9R",
  "Ninja Zx 10R",
  "Ninja Zx 12R",
  "Kx 65",
  "Kx 85",
  "Kx 125",
  "Vulcan 800",
  "Kx 250",
  "Vulcan 1500 Classic",
  "Vulcan 1500 Mean Streak",
  "Zzr 1200",
  "Vulcan 1600 Mean Streak",
  "Vulcan 750",
  "Kdx 200",
  "Voyager Xii",
  "Kdx 220 R",
  "Avajet 100",
  "Classic 100",
  "Er 5",
  "Klx 250",
  "Klx 650",
  "Kz 1000",
  "Maxi Ii 100",
  "Voyager 1200",
  "Vulcan 800 Classic",
  "Vulcan En 500",
  "Vulcan Ltd Class 500",
  "Vulcan Nomad 1500",
  "Vulcan 1500",
  "Vulcan 2000",
  "Ninja Zx 11",
  "Ninja Zx 14",
  "Ninja Zx 10",
  "Ninja 650R",
  "Concours 14",
  "Kz 1300",
  "Z 750",
  "Vulcan 900 Classic",
  "Mojave 250",
  "Kfx 450R",
  "Er 6N",
  "Er 6F",
  "Zr 7",
  "Zzr 1400",
  "D Tracker 250 X",
  "Vulcan 900 Classic Lt",
  "Vulcan 900 Custom",
  "Ninja 1000",
  "Versys Tourer",
  "Ninja Zx 14R",
  "Versys 1000 Abs",
  "Ninja 650R Abs",
  "Ninja 300",
  "Kz 1000 Police",
  "Versys 1000 Grand Tourer",
  "Versys City",
  "Kz 750 B4",
  "Kz 650",
  "Zzr 1100",
  "Ninja 1000 Tourer",
  "Er 6N Abs",
  "Ninja 300 Abs",
  "Ninja Z 300 Abs Se",
  "Ninja Zx 10R Abs",
  "Ninja Zx 10R Se",
  "Ninja Zx 10R Se Abs",
  "Ninja Zx 14R Abs",
  "Ninja Zx 6R 636 Abs Se",
  "Ninja Zx 6R 636 Se",
  "Vulcan 900 Classic Special Edition",
  "Z1000 Abs Se",
  "Z1000 Se",
  "Z800 Abs",
  "Versys Abs",
  "Vulcan S",
  "Vulcan S Abs",
  "Versys 1000 Grand Tourer Abs",
  "Z300 Abs",
  "Versys 1000",
  "Ninja H2",
  "Z 750 Abs",
  "Ninja Zx 6R 636 Abs",
  "Versys Tourer Abs",
  "Z1000 Abs",
  "Ninja 300 Abs Special Edition",
  "Ninja H2r",
  "Ninja 650 Abs",
  "Ninja H2 Carbon",
  "Z650 Abs",
  "Ninja 650 Abs Se",
  "Ninja 1000 Abs",
  "Ninja 1000 Tourer Abs",
  "Ninja Zx 10R R",
  "Z1000 R Abs",
  "Vulcan S Abs Se",
  "Versys Tr Abs",
  "Versys X 300",
  "Versys X 300 Abs",
  "Versys X 300 Tourer Abs",
  "Ninja Zx 10R Abs Krt Réplica",
  "Ninja 650 Krt Edition",
  "Vulcan S Abs Café",
  "Ninja H2 Sx Se",
  "Z900rs Café",
  "Ninja 400",
  "Ninja 400 Krt Edition",
  "Ninja Zx 10R Standard",
  "Z650 Abs Se",
  "Z900 Se",
  "Ninja Zx 6R Krt",
  "Kx 250X",
  "Z650 Abs Rs",
  "Z900 R Edition",
  "Z900 50Th Anniversary",
  "Z900rs R Edition",
  "Z400 Se",
  "Sx 125",
  "Sx 50",
  "Sx 250F",
  "Sx 65",
  "Exc 125",
  "Sxc 625",
  "Exc 200",
  "Adventure 640",
  "Exc 250 2T",
  "Duke Ii 640",
  "Exc 450",
  "Exc 525",
  "Superduke 990",
  "Adventure 950",
  "Duke 640",
  "Exc 250",
  "300 Exc",
  "Exc 380",
  "Exc 400",
  "Exc 520",
  "Lc4 640",
  "Sc 620",
  "Sx 250",
  "Sxc 520",
  "Super Enduro 950",
  "Sx 450F",
  "250 Exc F",
  "Exc 530R",
  "Adventure 990",
  "Supermoto 690",
  "Supermoto 950R",
  "Sc 400",
  "Rc8 1190",
  "Sx 85",
  "Xcf W 250",
  "Sx 150",
  "Enduro 690",
  "Supermoto 990T",
  "Rc8 R",
  "990 Adventure Abs Dakar",
  "690 Enduro R",
  "Sx 350F",
  "Xcf W 350",
  "Xc W 200",
  "Duke 690",
  "1290 Super Duke R",
  "350 Exc F",
  "Adventure 1190",
  "390 Duke Abs",
  "200 Duke",
  "Exc 250F Six Days",
  "Exc 300 Factory Edition Br",
  "Exc 250F Factory Edition Br",
  "Exc 350F Factory Edition Br",
  "Adventure 1190 R",
  "1290 Super Adventure R",
  "Xc W 300",
  "Xc 150",
  "390 Duke Abs Edição Especial",
  "1290 Super Adventure S",
  "1290 Super Duke Gt",
  "350 Exc F Six Days",
  "Sx F 450 Factory Edition",
  "450 Exc F Six Days",
  "250 Sx F",
  "Exc 150 Tpi",
  "Djw 50",
  "M Boy 90",
  "People 50",
  "Zing 150",
  "Pulsar 125",
  "Movie 50",
  "Vitality 50",
  "Super 8",
  "Like 50",
  "Agility 125 City",
  "Like 125",
  "Downtown 125 I",
  "Xciting 500 Ri",
  "Downtown 300 I",
  "Downtown 300 I Abs",
  "People Gti 300",
  "Agility 16+ 200I Abs",
  "Ak 550I",
  "Downtown 350 Abs",
  "Easy Lj 110 10",
  "Skema Lj 125 6",
  "Vite Lj 150T 7",
  "Fast Lj 150 2",
  "Drago Lj 150 7",
  "Easy 125",
  "Califórnia Jackal",
  "Califórnia Special",
  "Califórnia Ev",
  "Quota 1100 Es",
  "V11 Sport",
  "V11 Le Mans",
  "Califórnia Stone",
  "V7 Racer",
  "Griso 8V",
  "Mtx 150",
  "Mca 200",
  "Mcf 200",
  "Cappuccino 150",
  "Xiashing 150",
  "Velvet 150 Lt",
  "230 Supermotard",
  "230R Supercross",
  "F4 1000R 1+1",
  "Brutale S",
  "Brutale 910",
  "Brutale 910R",
  "F4 Senna",
  "F4 1000R",
  "F4 750",
  "F4 750S",
  "Brutale 910S",
  "F4 312 R",
  "F4 312 R 1+1",
  "F4 1078 Rr 312",
  "Brutale 1090R",
  "Brutale 1090Rr",
  "F4 Rr",
  "Brutale 1090Rr Abs",
  "F4 1000 Rr Abs",
  "Brutale 800",
  "F3 800",
  "Rivale 800",
  "F4 Abs",
  "F4 Rr Abs",
  "Brutale 800 Rr",
  "F3 Rr",
  "Superveloce 800",
  "Dragster Rr Scs",
  "Turismo Veloce Lusso Scs",
  "Brutale 1000Rr",
  "Rush 1000",
  "F3 Serie Oro",
  "Go 100",
  "Halley 150",
  "Ma 100 3B",
  "Ma 125 Gy",
  "Black Star 125",
  "Fox 100",
  "Street 150",
  "Black Star 150",
  "Halley 200",
  "Fenix 200",
  "Dual 200",
  "Super 125",
  "Xrt 110",
  "Sport 110",
  "X Cape 200",
  "Big Force 250",
  "Jurasic 300",
  "Fox 110",
  "Sport 150",
  "Big Force 250 Ii",
  "Brx 250",
  "Brx 140",
  "Simba 50",
  "Super X 125R",
  "Wy 50Q T 3 Scooter",
  "Fenix Gold 240",
  "Fu 240 2",
  "Nqi Gts",
  "Nqi Sport",
  "Ax 100",
  "Ax 100 A",
  "Jc 125",
  "Jc 125 2",
  "Jc 125 8",
  "Qm 50",
  "Br Iii",
  "Buxy 50",
  "Elyseo 125",
  "Scoot Elec",
  "Speedake 50",
  "Speedfight 100",
  "Speedfight 50",
  "Speedfight 50 Lc",
  "Squab 50",
  "Trekker 100",
  "Vivacity 50",
  "Zenith 50",
  "Beverly 250",
  "Beverly 500",
  "Nrg Mc3 Dd",
  "Runner 50",
  "Vespa Px 150 Originale",
  "Zip 50",
  "Vespa Px 200",
  "Beverly Cruiser 500",
  "Mp3 400 Rl",
  "Beverly Cruiser 250",
  "Vespa Gts 250 I.E",
  "Vespa Lx 125",
  "Vespa Gtv 250 I.E",
  "Vespa Gts 250 I.E Abs",
  "Vespa Lx 150",
  "Liberty S 200",
  "Liberty 200",
  "Beverly Tourer 300",
  "Beverly 300",
  "Mp3 Sport 500",
  "Vespa M4",
  "Mp3 300",
  "Mp3 500",
  "Beverly 350 Sport",
  "Vespa M3",
  "Vespa Vxl Classic 150",
  "Vespa Zx 125",
  "Vespa Li 125",
  "Vespa Sprint Vlb",
  "Vespa Allstate 125",
  "Trail Bross 325",
  "Sportsman Ace 150",
  "Sportsman 570",
  "Sportsman Touring 570",
  "Sportsman Touring 570 Eps",
  "Sportsman Xp 1000",
  "Ranger 570",
  "Rzr S 900 Eps",
  "Rzr S 4 900 Eps",
  "Rzr Xp 1000 Eps",
  "Rzr Xp 4 1000 Eps",
  "Rzr Xp Turbo Eps",
  "Rzr Xp Turbo S",
  "General 4 1000",
  "Rzr S 1000 Eps",
  "General Xp4 1000",
  "Ranger 570 Crew",
  "Ranger 1000",
  "Rzr Xp 1000 Sport",
  "Rzr Xp 1000 Premium",
  "Rzr Xp 1000 Ultimate",
  "Rzr Xp 4 1000 Ultimate",
  "Rzr Pro Xp 4 1000 Ultimate",
  "Black Jack",
  "Fenix Gold",
  "Spyder 270",
  "Spyder 320",
  "Ghost 320",
  "Trc01 Versão I",
  "Trc01 Versão Ii",
  "Trc01 Gran Luxo 1.6",
  "Trc01 Super Luxo Se 1.6",
  "Trc01 Super Luxo 1.6",
  "Trc01 Luxo 1.6",
  "Trc01 Junior 1.8",
  "Trc01 Gran Luxo 1.8",
  "Trc01 Super Luxo 1.8",
  "Trc01 Super Luxo Se 1.8",
  "Enjoy 50",
  "Husky 150",
  "Passing 110",
  "Xy 150 5 Speed",
  "Xy 125 14",
  "Xy 110 V Wave",
  "Xy 200 5",
  "Xy 50 Q 2",
  "Xy 150 Gy",
  "Xy 150 Sta",
  "Xy 250 5",
  "Xy 200 Lll",
  "Xy 250 Stvi",
  "Xy 50 Q",
  "Xy 50Q Phoenix",
  "Wy 150 Ex",
  "Xy 50Q Eagle",
  "Xy 150 5 Max",
  "F40 150",
  "Phoenix 50",
  "F35 150",
  "Phoenix + 50",
  "Racing 200",
  "Bike 50",
  "Max 150",
  "Jet 50",
  "Explorer 150",
  "New Wave 125",
  "New 50",
  "Retro 50",
  "Eagle 50",
  "Super Smart 50",
  "Jet 125",
  "Max 150 Fire",
  "Discover 250",
  "Xy 150 5 Sun",
  "Bolt 250",
  "Phoenix S 50",
  "Cross 50",
  "Xy 250 6B Discover",
  "Jet 50 2X",
  "Jef 150",
  "Jet 125 2X",
  "Worker 125",
  "She S",
  "Ray 50",
  "Shi 175",
  "Atv 125",
  "Worker Cross",
  "Akros 50",
  "Web Evo",
  "Hunter Se",
  "Hunter 90",
  "Max Se",
  "Max Sed",
  "Akros 50",
  "Akros 90",
  "Ergon 50",
  "Fifty 50",
  "Palio 50",
  "Pgo Speed 50",
  "Pgo Speed 90",
  "Super Fifty 50",
  "Hunter 100",
  "Future Elétrica",
  "Outlook 150",
  "Hawk 150",
  "Stx 200",
  "Motard 200",
  "Future Sport",
  "Cux Ducati",
  "Tc Max",
  "Intruder 125",
  "En 125 Yes Ed",
  "An125 Burgman",
  "Gsx R750",
  "Boulevard C1500",
  "Burgman 400",
  "Bandit 650S",
  "Gsx 750 F",
  "Dr Z 400E",
  "Bandit N1200",
  "Gsx 1300R Hayabusa",
  "Dl 1000 V Strom",
  "Address 100",
  "Address 50",
  "Ae 50",
  "Ag 100",
  "Bandit 600S",
  "Bandit N600",
  "Bandit 650N",
  "Dr 350",
  "Dr 350 Se",
  "Dr 650 Re",
  "Dr 650 Rse",
  "Dr 800 S",
  "Freewind Xf 650",
  "Gsx 1100 F",
  "Gsx R1100 W",
  "Intruder 250",
  "Lc 1500 Intruder",
  "Intruder Vs 1400 Glp",
  "Intruder Vs 800 Gl",
  "Intruder Vs 800 Glp",
  "Katana 125",
  "Lets Ii 50",
  "Lt 50 Quadriciclo",
  "Lt 80 Quadriciclo",
  "Lt F160 Quadriciclo",
  "Marauder 800",
  "Rf 600 R",
  "Rf 900 R",
  "Rm 125",
  "Rm 250",
  "Rm 80",
  "Rmx 250",
  "Ls 650 Savage",
  "Tl 1000 S",
  "Vx 800",
  "Rm Z 250",
  "Gsx R600",
  "Burgman 650 Executive",
  "En 125 Yes Ed Cargo",
  "Lt R450 Quadracer",
  "Lt A450x Kingquad",
  "B King",
  "Bandit N1250",
  "Bandit 1250S",
  "Gsx 1300 B King",
  "Lt A750x Kingquad",
  "Lt A400f Kingquad",
  "Lt F250 Ozark",
  "Gt 550",
  "Dr 650 R",
  "Dr 650 Rs",
  "Boulevard M109r",
  "Bandit 1200S",
  "Intruder 125 Ed Cargo",
  "Gsx 650 F",
  "Dl 650 V Strom",
  "Quadsport Z400",
  "Gsx 1100 Sbe Katana",
  "Gsx R750 W Srad",
  "Bandit 1250",
  "Boulevard M1500",
  "Burgman 650",
  "An650 Burgman",
  "Boulevard M800",
  "Ozark 250",
  "Gsx R1000",
  "Burgman 125 Automatic",
  "Bandit 650",
  "Yes 125 Se",
  "Yes 125 Ed",
  "Gsr 150I",
  "Burgman I",
  "V Strom 650 Abs",
  "Hayabusa Gsx1300r",
  "Boulevard M1500r",
  "Boulevard M800r",
  "Intruder 125 Ed",
  "Gt 750",
  "Gsx R750 Srad",
  "Gsr 750A",
  "Gsx 1250 Fa",
  "Rm Z 450",
  "Gsr 125",
  "Gsr 125S",
  "Hayabusa Gsx1300ra",
  "A 50",
  "Gsr 750",
  "Boulevard M1800r",
  "V Strom 1000 Abs",
  "Boulevard M1800r Boss",
  "Gsr 750Za",
  "Gsx 1300Raz Hayabusa",
  "Gsx R750 Moto Gp",
  "Gsx 1300Ra Hayabusa",
  "Gsx R1000 Moto Gp",
  "V Strom 650Xt Abs",
  "Gsx R1000 Moto Gp Abs",
  "Gsx S1000 Abs",
  "Gsx S1000f Abs",
  "Gsx R1000a 30 Anos",
  "Gsx R750 30 Anos",
  "Sv 650 A",
  "Boulevard M1800r Bz",
  "Boulevard M1800r Bzs Boss",
  "Gsx S750a",
  "V Strom 1000Xt Abs",
  "Gsx R1000a",
  "Boulevard M1800rs",
  "Gsx R1000r Abs",
  "Gsx S1000z Abs",
  "V Strom 1000Xt Adventure Abs",
  "V Strom 650 Adventure",
  "Gsx S1000a Abs",
  "V Strom 1050Xt Abs",
  "Hayabusa Gsx1300rrq",
  "Gsx S750za",
  "Gsx R1000raz",
  "Cj 50 F",
  "Best Jh125g",
  "Fly 125",
  "Jh 50",
  "Jh 70",
  "Jh 70 Iii",
  "Sky Jl 110 3",
  "Sky Jl 110 8",
  "Star Jl 50 Q 2",
  "Prince Jl 110 11",
  "Joto 125",
  "Shark 250",
  "Work 125",
  "Montez 250",
  "Joto 135",
  "Sky 125",
  "Star 50",
  "Moby 50",
  "Dunna 600",
  "Fly 135",
  "Sky 50",
  "Fly 250",
  "Fly 150",
  "Tss 150",
  "Tss 250",
  "Sky 50 Plus",
  "Sky Jl 125 9",
  "Tiger S",
  "Rocket Iii",
  "Tiger X",
  "Tiger T",
  "Sprint St",
  "Daytona 955I",
  "Bonneville T 100 790",
  "Daytona 1200",
  "Daytona 900",
  "Daytona Super Iii",
  "Daytona T 955",
  "Legend 900",
  "Speed Triple 1050I",
  "Speed Triple 900",
  "Speed Triple 955I",
  "Speed Triple T 509 900",
  "Sprint 900",
  "Sprint St 955",
  "Thunderbird 900",
  "Thunderbird 900 Sport",
  "Tiger 750",
  "Tiger 900",
  "Trident 750",
  "Trident 900",
  "Trophy 1200",
  "Trophy 900",
  "Tt 600",
  "Sprint St 1050",
  "Rocket Iii Classic",
  "Daytona 675",
  "Tiger 1050",
  "Speed Triple",
  "Tiger 955I",
  "Street Triple",
  "Rocket Iii Touring",
  "Tiger 1050 Se",
  "Bonneville T 100 865",
  "Speed Triple 1050",
  "Speed Triple 1050 Se",
  "Street Triple 675",
  "Sprint Rs 955",
  "Bonneville 790Cc",
  "Tiger Explorer",
  "Tiger 800Xc",
  "Rocket Iii Roadster",
  "Thunderbird Storm",
  "Daytona 675R",
  "Tiger 800 Abs",
  "Tiger Sport Abs",
  "Tiger Explorer Abs",
  "Tiger Explorer Xc Abs",
  "Thunderbird Storm Abs",
  "Street Triple 675 Abs",
  "Street Triple R Abs",
  "Speed Triple Abs",
  "Daytona 675 Abs",
  "Daytona 675R Abs",
  "Trophy Se",
  "Thunderbird Commander Abs",
  "Tiger 800 Xrx",
  "Tiger 800 Xcx",
  "Tiger 800 Xr",
  "Tiger 800 Xca",
  "Street Twin",
  "Speed Triple R",
  "Tiger Explorer Xr",
  "Tiger Explorer Xcx",
  "Bonneville T120 1200",
  "Bonneville T120 Black 1200",
  "Thruxton R",
  "Tiger Explorer Xca",
  "Bonneville Bobber 1200",
  "Street Cup",
  "Street Scrambler",
  "Street Triple 765 S",
  "Street Triple 765 Rs",
  "Tiger 800 Xrt",
  "Tiger 1200 Xr",
  "Tiger 1200 Xcx",
  "Tiger 1200 Xca",
  "Bonneville T100 Black 900",
  "Speed Twin",
  "Bonneville Scrambler 1200 Xc",
  "Bonneville Scrambler 1200 Xe",
  "Rocket Iii R",
  "Tiger 900 Gt",
  "Tiger 900 Rally",
  "Tiger 1200 Alpine Edition",
  "Tiger 1200 Desert Edition",
  "Tiger 900 Gt Pro",
  "Tiger 900 Rally Pro",
  "Trident 660",
  "Tiger 660 Sport",
  "Tiger 1200 Rally Explorer",
  "Tiger 1200 Rally Pro",
  "Tiger 1200 Gt Explorer",
  "Speed Twin 900",
  "Rocket Iii Gt",
  "Bonneville T100 900",
  "Bonneville Speedmaster",
  "Phantom R4",
  "Zip R3i Turbocam",
  "Triton R4",
  "Zip Gt5 Turbocam",
  "Triton Gt5",
  "Phantom Gt5",
  "V Thunder Xl",
  "Evs Work",
  "Wy150 Ex",
  "Wy125 Esd Plus",
  "Wy125 Esd",
  "Wy48q 2",
  "Ybr 125 E",
  "Neo At 115",
  "Ybr 125 Ed",
  "Ybr 125K",
  "Xtz 125 E",
  "Xt 660R",
  "Xtz 125 K",
  "Xtz 250 Lander",
  "Drag Star 650",
  "Yzf R1",
  "Fazer 600",
  "Tdm 900",
  "Yz 85Lw",
  "Yz 250",
  "Yz 125",
  "Yz 250F",
  "Wr 250",
  "Yz 450F",
  "Wr 450",
  "Mt 01",
  "Axis 90",
  "Bws 50",
  "Crypton 100",
  "Dt 180Z",
  "Dt 200",
  "Dt 200R",
  "Fzr 1000",
  "Jog 50",
  "Jog Teen 50",
  "Yp 250 Majesty",
  "Rd 135",
  "Royal Star 1300",
  "Tdm 225",
  "Tdm 850",
  "Trx 850",
  "V Max 1200",
  "Wr 200",
  "Wr 426F",
  "Xjr 1200",
  "Drag Star 1100",
  "Xt 225",
  "Xt 600 E",
  "Xtz 750S Tenere",
  "Xv 1100 Virago",
  "Xv 250S Virago",
  "Xv 535S Virago",
  "Xv Virago 750",
  "Yzf 1000 Thunderace",
  "Yzf 600 Thundercat",
  "Yzf R6",
  "Xt 600 Z Ténéré",
  "Road Star 1600",
  "Rd 350R",
  "Fz1 Fazer",
  "Dt 180",
  "Rdz 135",
  "Rd 125",
  "Rdz 125",
  "Rd 350 Lc",
  "Tdr 180",
  "Yzf 750",
  "Neo Cvt 115",
  "Fazer 600S",
  "Yfm 700R",
  "Fjr 1300",
  "Yz 80",
  "Tt R 125E",
  "Tt R 125Le",
  "Tt R 230",
  "Xtz 125 Xk",
  "Xtz 125 Xe",
  "Fazer 250Ie Limited Edition",
  "V Star 650",
  "Yfs 200 Blaster",
  "Mt 03",
  "Yfm 250R",
  "Yfm 80R",
  "Yfm 350R",
  "Yfz 450",
  "Grizzly 125",
  "Yfm 250 Big Bear",
  "Grizzly 350",
  "Grizzly 660",
  "Grizzly 700",
  "V Star 1100",
  "Xtz 250 X",
  "Raptor 660R",
  "Tx 650",
  "Fazer Fz6s",
  "Ybr 125 Factor K",
  "Ybr 125 Factor E",
  "Factor Ybr 125 Ed",
  "Rx 125",
  "Midnight Warrior",
  "Rd 350",
  "Xvs950a Midnight Star",
  "V Max 1800",
  "Yzf 450",
  "V Star 650 Classic",
  "Xj6 N",
  "Xj6 F",
  "Ys 250 Fazer",
  "Tt 125",
  "Ybr 125 Factor Ed Centenário",
  "Crypton Ed",
  "Crypton K",
  "Tmax Xp 500",
  "Xtz 250 Ténéré",
  "Yzf R125",
  "Xt1200z Super Ténéré",
  "Factor Ybr 125I Ed",
  "Tt R 125L",
  "Tt R 125Lwe",
  "T115 Crypton K",
  "T115 Crypton Ed",
  "Xt 660 Z Ténéré",
  "Factor Ybr 125 Ed Black Edition",
  "Xj 600S Diversion",
  "Ys 250 Fazer Blueflex",
  "Factor Ybr 125 K",
  "Rd 75",
  "Factor Ybr 125 K1",
  "Crypton Racing Blue K",
  "Crypton Racing Blue Ed",
  "Ys 250 Fazer Racing Blue",
  "Ybr 125 Factor K1",
  "Ybr 125 Factor Racing Blue K",
  "Ybr 125 Factor Racing Blue Ed",
  "T115 Crypton Ed Penelope",
  "Tt R 125",
  "Xj6 F Racing Blue",
  "Factor Ybr 125 Pro K1",
  "Factor Ybr 125 Pro E",
  "Ys 150 Fazer Sed",
  "Ys 150 Fazer Ed",
  "Virago Xv 535",
  "Virago 400",
  "Factor Ybr 125 E",
  "Xj6 N Sp",
  "Fazer Black Edition",
  "Wr 450F",
  "Xtz 150 Crosser E",
  "Xtz 150 Crosser Ed",
  "Grizzly 700 Se",
  "V Max",
  "Mt 09",
  "Xt1200z Super Ténéré Dx",
  "Virago Xv 250S",
  "Xj6 N Abs",
  "Xj6 N Abs Sp",
  "Xj6 F Abs",
  "Xt 660Z Ténéré Abs",
  "Mt 09 Sp",
  "Mt 07",
  "Mt 07 Abs",
  "Xtz 250 Ténéré Blueflex",
  "Yzf R3",
  "Rx 180",
  "Factor 150E",
  "Factor 150Ed",
  "Yzf R3 Abs",
  "Rd 50",
  "Mt 09 Tracer",
  "Nmax 160 Abs",
  "Mt 03 Abs",
  "Xv 250 Virago",
  "Neo 125",
  "Fazer 150 Ubs",
  "Xt1200z Super Ténéré Dx 60Th",
  "Yzf R1 60Th",
  "Yzf R1m",
  "Tmax Abs",
  "Yz 250Fx",
  "Yz 450Fx",
  "Wr 250F",
  "Xtz 150 Crosser S",
  "Xtz 150 Crosser Z",
  "Fz25 Fazer Abs",
  "Tracer 900 Gt",
  "Yz 250X",
  "Yz 65",
  "Xmax Abs",
  "Yzf R3 Monster Abs",
  "Factor Ybr 150 Ed",
  "Nmax 160 Star Wars",
  "Nmax 160 Abs Le",
  "Dt 180 Super",
  "Fluo 125 Abs",
  "Fz15 Fazer Abs",
  "Yzf R3 60Th Anniversary Abs",
  "Srx 160",
  "F 900 R Sport Plus",
  "C 400 X",
  "C 400 X Sport",
  "F 750 Gs",
  "F 850 Gs Adventure",
  "F 850 Gs",
  "R 1250 Gs Adventure",
  "R 1250 Gs",
  "R 1300 Gs",
  "R 1300 Gs Plus",
  "R 1300 Gs Trophy",
  "R 1300 Gs Triple Black",
  "R 1300 Gs Option 719",
  "Panigale V4 Sp",
  "Desert X",
  "Panigale V4 Speciale",
  "Multistrada 1200 V4 Rally Adventure",
  "Mc 85",
  "Mc 450F",
  "Ex 350F",
  "Electra Glide Ultra Limited 110Th Anniversary Edition",
  "Softail Breakout 114 115Th Anniversary",
  "Road Glide",
  "Cb 1000R Black Edition Abs",
  "Xre 300 Sahara",
  "Xre 300 Sahara Adventure",
  "Xre 300 Sahara Rally",
  "Norden 901 Expedition",
  "Fe 250",
  "Klr 650",
  "Ninja Zx 4R",
  "Ninja 400 Krt Edition Se",
  "890 Adventure R",
  "300 Exc Six Days",
  "450 Sx F",
  "890 Adventure S",
  "300 Xc",
  "Apé Cargo",
  "Apé Passenger",
  "Ranger Ev",
  "Rzr Pro R Sport",
  "Rzr Trail S 1000 Premium",
  "Rzr Pro Xp R2r",
  "Xy 125 Ss",
  "She 3000",
  "Rio 125",
  "Jef S 150",
  "Jet 50 S",
  "Jet 125 Ss",
  "Gsx S1000rq Abs",
  "Gsx S1000gt Abs",
  "V Strom 1050 Abs",
  "Tiger 900 Gt Aragón",
  "Tiger 900 Rally Aragón",
  "Street Triple Rs Abs",
  "Speed 400",
  "Scrambler 400 X",
  "Tiger 1200 Gt Pro",
  "Tiger 1200 Black Edition",
  "Nmax Connected 160 Abs",
  "Nmax Connected 160 Se Abs",
  "Yzf R15",
  "T350 X",
  "Dominar 250",
  "F 900 Gs",
  "F 900 Gs Adventure",
  "R 1300 Gs Adventure",
  "F 800 Gs Plus",
  "F 900 Gs Plus",
  "F 900 Gs Trophy Pro",
  "R 1250 Gs Adventure Premium Triple Black",
  "R 1250 Gs Premium Triple Black",
  "R 12",
  "R 12 Cruiser",
  "R 12 Option 719",
  "R 1200 R",
  "F 900 Gs Trophy",
  "Diavel V4",
  "Mc 125",
  "Xr 300L Tornado",
  "Eliminator 500",
  "Eliminator 500 Se",
  "Kx 112",
  "Z500 Se",
  "Ninja 500",
  "Ninja 500 Se",
  "Urban 150 Efi",
  "Free 150",
  "Pt3 S",
  "Strom 200",
  "Hayabusa Gsx1300rrq Edição De Aniversário",
  "V Strom 800 De",
  "Gsx 8S",
  "Scrambler 1200 Xe",
  "Daytona 660",
  "Yzf 750 R",
  "Yz 450F 50Th Anniversary",
  "Yz 250F 50Th Anniversary",
];
