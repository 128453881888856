"use client";

import Link from "next/link";
import React, { PropsWithChildren, useEffect, useState } from "react";

import styles from "../../header.module.scss";
import { useTheme } from "next-themes";
import { extractRegionFromCurrentSlug, handleFormatStyleString } from "~/utils";
import { FieldParams } from "~/constants";
import slugify from "slugify";
import { HeaderProps } from "../../props";
import { WebsitePreferenceType } from "~/components/editable/inventory";
import PlatformsLogo from "~/components/editable/logo";
import { ThemeToggle } from "~/components/layout";

export function HeaderVariant01(props: HeaderProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { theme } = useTheme();

  const variant = Number(props?.variants?.value) ?? 1;

  const clientPathName =
    props.site?.preferences?.find(
      (preference: { type: WebsitePreferenceType }) =>
        preference.type === WebsitePreferenceType.inventoryPathName
    )?.value || "estoque";

  const slugRegion = extractRegionFromCurrentSlug(props.page?.currentSlug);

  const clientMainRegion = slugRegion
    ? slugRegion
    : props.site?.preferences
        ?.find(
          (preference: { type: WebsitePreferenceType }) =>
            preference.type === WebsitePreferenceType.localSeoSlug
        )
        ?.value?.split(",")[0] || "brasil";

  function handleChangeTheme() {
    if (typeof window != "undefined" && typeof document != "undefined") {
      switch (theme) {
        case "dark":
          document.documentElement.setAttribute("data-theme", "dark");
          document.documentElement.setAttribute("prefers-color-scheme", "dark");
          break;
        default:
          document.documentElement.setAttribute("data-theme", "light");
          document.documentElement.setAttribute(
            "prefers-color-scheme",
            "light"
          );
          break;
      }
    }
  }

  useEffect(handleChangeTheme, [theme]);

  //@ts-ignore
  if (props && (props.visible == false || props.visible?.value == false)) {
    return <div />;
  }

  const Renderer = ({ children }: PropsWithChildren) =>
    variant == 1 || variant == 4 ? (
      <div className="variant-wrapper">{children}</div>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      <nav className={`${styles.container} container-${props.id}`}>
        <div className={`${styles.wrapper} wrapper-${props.id}`}>
          <PlatformsLogo
            id={props.id}
            logo={props.logo}
            logoDark={props.logoDark}
            logoWidth={props.logoWidth ?? 100}
            logoHeight={props.logoHeight ?? 100}
          />

          <button
            data-collapse-toggle="navbar-default"
            onClick={() => setOpen((prevState) => !prevState)}
            type="button"
            name="mobile navigation"
            aria-label="mobile navigation"
            className={`${styles["menu-mobile"]} menu-mobile-${props.id}`}
          >
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <Renderer>
            <div
              key={String(open)}
              className={`${styles["menu-container"]} menu-container-${
                props.id
              } ${
                open
                  ? `${styles["menu-container--open"]} menu-container--open-${props.id}`
                  : ""
              }`}
            >
              {typeof props.menus != "undefined" &&
                (props.menus.items ?? (props.menus as unknown as any)).map(
                  (item: FieldParams, idx: number) => {
                    if (item.label?.toLowerCase().includes("estoque")) {
                      item.link = `/${slugify(clientPathName, {
                        lower: true,
                      })}/${slugify(props.site?.inventoryType ?? "carros", {
                        lower: true,
                      })}/${slugify(clientMainRegion, { lower: true })}`;

                      return (
                        <Link
                          key={idx}
                          href={String(item.link ?? item.link)}
                          className={`${styles["menu-item"]} menu-item-${props.id}`}
                          prefetch
                        >
                          {item.label}
                        </Link>
                      );
                    }

                    return (
                      <Link
                        key={idx}
                        href={String(item.link ?? item.link)}
                        className={`${styles["menu-item"]} menu-item-${props.id}`}
                        prefetch
                      >
                        {item.label}
                      </Link>
                    );
                  }
                )}
              <div className={styles["theme-container-mobile"]}>
                <ThemeToggle
                  defaultTheme={
                    typeof props.defaultTheme != "undefined"
                      ? String(props.defaultTheme?.value ?? props.defaultTheme)
                      : "light"
                  }
                />
              </div>
            </div>
            <div className={styles["theme-container"]}>
              <ThemeToggle
                defaultTheme={
                  typeof props.defaultTheme != "undefined"
                    ? String(props.defaultTheme?.value ?? props.defaultTheme)
                    : "light"
                }
              />
            </div>
          </Renderer>
        </div>
      </nav>
    </>
  );
}
