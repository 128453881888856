"use client";

import { handleFormatStyleString } from "~/utils";
import { FormContactVariant01 } from "./variants/01";
import { FormContactVariant02 } from "./variants/02";
import { FormContact1Props } from "../props";
import { FormContactVariant03 } from "./variants/03";
import { FormContactVariant04 } from "./variants/04";

export function FormContact1(props: FormContact1Props): JSX.Element {
  const variationId = props?.variation?.currentVariationId || "01";

  const variant = Number(props?.variants?.value) ?? Number(variationId);

  function renderFormContact() {
    switch (variant) {
      case 1:
        return <FormContactVariant01 {...props} />;
      case 2:
        return <FormContactVariant02 {...props} />;
      case 3:
        return <FormContactVariant03 {...props} />;
      case 4:
        return <FormContactVariant04 {...props} />;

      default:
        return <FormContactVariant01 {...props} />;
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      {renderFormContact()}
    </>
  );
}
