"use client";

import { handleFormatStyleString } from "~/utils";
import { HeaderProps } from "./props";
import { HeaderVariant01 } from "./variants/01";
import { HeaderVariant02 } from "./variants/02";

export function Header(props: HeaderProps): JSX.Element {
  const variant = Number(props?.variants?.value) ?? 1;

  function renderHeader() {
    switch (variant) {
      case 1:
        return <HeaderVariant01 {...props} />;
      case 2:
        return <HeaderVariant02 {...props} />;
      default:
        return <HeaderVariant01 {...props} />;
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      {renderHeader()}
    </>
  );
}
