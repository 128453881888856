import Link from "next/link";
import styles from "./menu-variants-01.module.scss";
import { Footer1Props } from "../../../props";
import { FieldParams } from "~/constants";

export default function FooterMenuVariant01(props: Footer1Props) {
  return (
    <div className={styles["container__menu__wrapper"]}>
      {(props.menus.items ?? (props.menus as unknown as any)).map(
        (item: FieldParams, idx: number) => (
          <>
            <Link
              key={idx}
              href={String(item.link ?? item.link)}
              className={`${styles["container__menu__item"]} container__menu__item__${props.id}`}
            >
              {item.label}
            </Link>
            {Number(props?.variants?.value) < 3 &&
              (props.menus.items ?? (props.menus as unknown as any[])).length >
                idx + 1 && (
                <div className={`${styles["container__menu__divider"]}`} />
              )}
          </>
        )
      )}
    </div>
  );
}
