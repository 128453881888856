"use client";

import { useEffect, useState } from "react";
import { HeaderProps } from "../../props";
import { useTheme } from "next-themes";
import { WebsitePreferenceType } from "~/components/editable/inventory";
import { extractRegionFromCurrentSlug, handleFormatStyleString } from "~/utils";
import PlatformsLogo from "~/components/editable/logo";
import { FieldParams } from "~/constants";
import slugify from "slugify";
import Link from "next/link";
import { ThemeToggle } from "~/components/layout";
import { usePathname } from "next/navigation";
import { motion } from "framer-motion";

export const HeaderVariant02 = (props: HeaderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { theme } = useTheme();
  const pathname = usePathname();

  const clientPathName =
    props.site?.preferences?.find(
      (preference: { type: WebsitePreferenceType }) =>
        preference.type === WebsitePreferenceType.inventoryPathName
    )?.value || "estoque";

  const slugRegion = extractRegionFromCurrentSlug(props.page?.currentSlug);

  const clientMainRegion = slugRegion
    ? slugRegion
    : props.site?.preferences
        ?.find(
          (preference: { type: WebsitePreferenceType }) =>
            preference.type === WebsitePreferenceType.localSeoSlug
        )
        ?.value?.split(",")[0] || "brasil";

  function handleChangeTheme() {
    if (typeof window != "undefined" && typeof document != "undefined") {
      switch (theme) {
        case "dark":
          document.documentElement.setAttribute("data-theme", "dark");
          document.documentElement.setAttribute("prefers-color-scheme", "dark");
          break;
        default:
          document.documentElement.setAttribute("data-theme", "light");
          document.documentElement.setAttribute(
            "prefers-color-scheme",
            "light"
          );
          break;
      }
    }
  }

  useEffect(handleChangeTheme, [theme]);

  //@ts-ignore
  if (props && (props.visible == false || props.visible?.value == false)) {
    return <div />;
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      <motion.header
        className={`flex flex-col items-center justify-center py-2 w-full !backdrop-blur-xl max-w-[90%] fixed top-1 left-[5%] rounded-sm z-50`}
        transition={{ duration: 0.3 }}
      >
        <div className="flex justify-between items-center w-full max-w-[90%]">
          <PlatformsLogo
            id={props.id}
            logo={props.logo}
            logoDark={props.logoDark}
            logoWidth={props.logoWidth ?? 100}
            logoHeight={props.logoHeight ?? 100}
          />
          <div>
            <nav className="gap-4 hidden sm:flex">
              {typeof props.menus != "undefined" &&
                (props.menus.items ?? (props.menus as unknown as any)).map(
                  (item: FieldParams, idx: number) => {
                    const isCurrentPage = pathname === item.link;

                    if (item.label?.toLowerCase().includes("estoque")) {
                      item.link = `/${slugify(clientPathName, {
                        lower: true,
                      })}/${slugify(props.site?.inventoryType ?? "carros", {
                        lower: true,
                      })}/${slugify(clientMainRegion, { lower: true })}`;

                      return (
                        <Link
                          key={idx}
                          href={String(item.link ?? item.link)}
                          className={`font-semibold  ${
                            isCurrentPage ? "!underline" : ""
                          } menu-item-${props.id}`}
                          prefetch
                        >
                          {item.label?.toLowerCase()}
                        </Link>
                      );
                    }

                    return (
                      <Link
                        key={idx}
                        href={String(item.link ?? item.link)}
                        className={`font-semibold  ${
                          isCurrentPage ? "!underline" : ""
                        } menu-item-${props.id}`}
                        prefetch
                      >
                        {item.label?.toLowerCase()}
                      </Link>
                    );
                  }
                )}
            </nav>
          </div>
          <div className="hidden sm:flex">
            <ThemeToggle
              defaultTheme={
                typeof props.defaultTheme != "undefined"
                  ? String(props.defaultTheme?.value ?? props.defaultTheme)
                  : "light"
              }
            />
          </div>
          <div className="block sm:hidden">
            <button
              onClick={() => setOpen(!open)}
              className="focus:outline-none"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Menu Mobile com Framer Motion */}
        {open && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="md:hidden flex flex-col items-center justify-center mb-4 gap-3"
          >
            <nav className="flex items-center justify-center gap-5 mt-4 mb-2">
              {typeof props.menus != "undefined" &&
                (props.menus.items ?? (props.menus as unknown as any)).map(
                  (item: FieldParams, idx: number) => {
                    const isCurrentPage = pathname === item.link;

                    if (item.label?.toLowerCase().includes("estoque")) {
                      item.link = `/${slugify(clientPathName, {
                        lower: true,
                      })}/${slugify(props.site?.inventoryType ?? "carros", {
                        lower: true,
                      })}/${slugify(clientMainRegion, { lower: true })}`;

                      return (
                        <Link
                          key={idx}
                          href={String(item.link ?? item.link)}
                          className={`font-semibold  ${
                            isCurrentPage ? "!underline" : ""
                          } menu-item-${props.id}`}
                          prefetch
                          onClick={() => setOpen(false)}
                        >
                          {item.label?.toLowerCase()}
                        </Link>
                      );
                    }

                    return (
                      <Link
                        key={idx}
                        href={String(item.link ?? item.link)}
                        className={`font-semibold  ${
                          isCurrentPage ? "!underline" : ""
                        } menu-item-${props.id}`}
                        prefetch
                        onClick={() => setOpen(false)}
                      >
                        {item.label?.toLowerCase()}
                      </Link>
                    );
                  }
                )}
            </nav>
            <ThemeToggle
              defaultTheme={
                typeof props.defaultTheme != "undefined"
                  ? String(props.defaultTheme?.value ?? props.defaultTheme)
                  : "light"
              }
            />
          </motion.div>
        )}
      </motion.header>
    </>
  );
};
