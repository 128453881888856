"use client";

import Link from "next/link";
import CookieConsent from "react-cookie-consent";

export const CookieConsentComponent = () => (
  //@ts-ignore
  <CookieConsent
    buttonText="Prosseguir"
    cookieName={`AcceptCookieFromSyncVendors}`}
    containerClasses="px-8 mx-auto sm:justify-between absolute bottom-0"
    style={{
      backgroundColor: "#222",
    }}
    contentStyle={{
      backgroundColor: "#222",
      color: "#fff",
      paddingRight: "2rem",
      paddingLeft: "2rem",
      margin: "2rem",
    }}
    buttonStyle={{
      backgroundColor: "#A0BBF2",
      color: "#333",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
      borderRadius: "0.375rem",
    }}
    enableDeclineButton
    declineButtonStyle={{
      backgroundColor: "#fff",
      color: "#333",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      paddingTop: ".5rem",
      paddingBottom: ".5rem",
      borderRadius: "0.375rem",
    }}
    declineButtonText="Não aceito"
    ariaAcceptLabel="Prosseguir"
    ariaDeclineLabel="Não aceito"
  >
    Nossa loja utiliza cookies e outras tecnologias semelhantes para melhorar a
    sua experiência, de acordo com a nossa Política de Privacidade e, ao
    continuar navegando, você concorda com &nbsp;
    <Link href="/politicas-de-privacidade" className="underline">
      nossas políticas.
    </Link>
  </CookieConsent>
);
