"use client";

import { useEffect, useState } from "react";
import { FilterItemProps } from "./props";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";
import { usePathname } from "next/navigation";
import Disclosure from "../disclosure";
import styles from "./range-item.module.scss";

export default function RangeItem({
  title,
  queryKey,
  handleFilter,
}: FilterItemProps): JSX.Element {
  const [value, setValue] = useState<{ minYear: string; maxYear: string }>({
    minYear: "",
    maxYear: "",
  });

  const pathname = usePathname();

  useEffect(() => {
    const details = extractVehicleDetailsFromPathname(pathname as string);

    if (details) {
      setValue({
        minYear: details.filter?.minYear ? String(details.filter?.minYear) : "",
        maxYear: details.filter?.maxYear ? String(details.filter?.maxYear) : "",
      });
    }
  }, [pathname]);

  const handleDefaultValues = (minYear: number, maxYear: number) => {
    const newMinYear = String(minYear);
    const newMaxYear = String(maxYear);

    // Atualiza os valores locais
    setValue({
      minYear: newMinYear,
      maxYear: newMaxYear,
    });

    // Chama a função handleFilter para minYear e maxYear
    handleFilter(
      { target: { value: [newMinYear, newMaxYear], id: "-range" } } as any,
      //@ts-ignore
      [newMinYear, newMaxYear],
      queryKey
    );
  };

  return (
    <Disclosure defaultOpen={true} childrenCount={4} title={title}>
      <div className={styles.container}>
        <div className={styles.input_wrapper}>
          <div className={styles.input_container}>
            <label htmlFor="min-key">Mínimo</label>
            <input
              id="min-key-range"
              value={value.minYear}
              onChange={(e) => {
                setValue({
                  ...value,
                  minYear: e.target.value,
                });
                if (e.target.value.length === 4) {
                  handleFilter(e, e.target.value, queryKey[0]);
                }
              }}
              maxLength={4}
              placeholder="2021"
            />
          </div>
          <div className={styles.input_container}>
            <label htmlFor="max-key">Máximo</label>
            <input
              id="max-key-range"
              value={value.maxYear}
              onChange={(e) => {
                setValue({
                  ...value,
                  maxYear: e.target.value,
                });
                if (e.target.value.length === 4) {
                  handleFilter(e, e.target.value, queryKey[1]);
                }
              }}
              maxLength={4}
              placeholder="2022"
            />
          </div>
        </div>
        <div className={styles.buttons_container}>
          <button
            onClick={() => handleDefaultValues(2024, 2025)}
            className={styles.default_buttons}
          >
            Novos - {new Date().getFullYear()}/{new Date().getFullYear() + 1}
          </button>
          <button
            className={styles.default_buttons}
            onClick={() => handleDefaultValues(2021, 2025)}
          >
            Últimos anos - {new Date().getFullYear() - 4}/
            {new Date().getFullYear()}
          </button>
        </div>
      </div>
    </Disclosure>
  );
}
