"use client";

import { SwHomeProps } from "./props";
import { handleFormatStyleString } from "~/utils";
import { SwHomeVariant01 } from "./variants/01";
import { SwHomeVariant03 } from "./variants/03";
import { SwHomeVariant02 } from "./variants/02";
import { SwHomeVariant04 } from "./variants/04";
import { SwHomeVariant05 } from "./variants/05";
import { SwHomeVariant06 } from "./variants/06";

export function SwHome(props: SwHomeProps): JSX.Element {
  const variant = Number(props?.variants?.value) ?? 1;

  function renderSwHome(): JSX.Element {
    switch (variant) {
      case 1:
        return <SwHomeVariant01 {...props} />;
      case 2:
        return <SwHomeVariant02 {...props} />;
      case 3:
        return <SwHomeVariant03 {...props} />;
      case 4:
        return <SwHomeVariant04 {...props} />;
      case 5:
        return <SwHomeVariant05 {...props} />;
      case 6:
        return <SwHomeVariant06 {...props} />;
      default:
        return <SwHomeVariant01 {...props} />;
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      {renderSwHome()}
    </>
  );
}
