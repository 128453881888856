import Link from "next/link";
import styles from "../mobile-menu.module.scss";
import { PlatformsIcon, PlatformsImage } from "~/components/non-editable/core";
import { HeaderMenuMobileProps } from "./props";

export const HeaderMenuMobile = ({
  ctaProps,
  setOpen,
}: HeaderMenuMobileProps) => {
  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <div className={`${styles["menu-header"]} menu-header-${ctaProps.id}`}>
      <Link href="/" rel="noreferrer">
        <div className={`${styles.wrapper} wrapper-${ctaProps.id}`}>
          <div
            className={`${styles["image-wrapper"]} image-wrapper-${ctaProps.id}`}
          >
            <PlatformsImage
              width={385}
              height={288}
              priority
              className={`${styles.image} image-${ctaProps.id}`}
              src={(ctaProps?.image?.value ?? ctaProps?.image) as string}
              alt=""
            />
          </div>
          <div
            className={`${styles["header-content"]} header-content-${ctaProps.id}`}
          >
            {typeof ctaProps.vendorName != "undefined" && (
              <h3
                className={`${styles["header-vendor-name"]} header-vendor-name-${ctaProps.id}`}
              >
                {typeof ctaProps.vendorName === "string"
                  ? ctaProps.vendorName
                  : ctaProps.vendorName.value}
              </h3>
            )}

            {typeof ctaProps.shortDescription != "undefined" && (
              <span
                className={`${styles["short-description"]} short-description-${ctaProps.id}`}
              >
                {/* @ts-ignore */}
                {typeof ctaProps.shortDescription === "string"
                  ? ctaProps.shortDescription
                  : ctaProps.shortDescription.value}
              </span>
            )}
          </div>
        </div>
      </Link>
      <PlatformsIcon
        width={20}
        height={20}
        className={`${styles["close-icon"]} close-icon-${ctaProps.id}`}
        icon="mingcute:close-line"
        onClick={handleCloseMenu}
      />
    </div>
  );
};
