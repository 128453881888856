import { DetailsVariants } from "./props";
import DetailsHeaderVariant01 from "./variants/01";

export default function DetailsHeaderVariants({
  props,
  variant,
}: DetailsVariants) {
  function renderHeaderVariants(): JSX.Element {
    switch (variant) {
      case 1:
        return <DetailsHeaderVariant01 {...props} />;
      default:
        return <DetailsHeaderVariant01 {...props} />;
    }
  }

  return renderHeaderVariants();
}
