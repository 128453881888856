"use client";

import { Hero2Props } from "./props";

//Componente "Em Construção"
export function Hero2(props: Hero2Props): JSX.Element {
  return (
    <div className="w-full h-screen flex flex-col items-center justify-between">
      {props && typeof props.image != "undefined" && (
        <img
          src={String(props.image?.value ?? props.image)}
          alt="logo"
          className="fixed top-4 left-4 max-w-[80px] z-[0]"
        />
      )}
      <div className="xl:w-1/2 flex-1 flex flex-col items-center justify-center text-center px-4 lg:px-0">
        <img
          src="https://illustrations.popsy.co/blue/page-under-construction.svg"
          alt="under constuction"
          className="w-full max-w-[500px]"
        />
        <p className="text-4xl font-bold text-gray-700 capitalize tracking-wide mt-8">
          Site em construção
        </p>
        <p className="text-xl text-gray-700 uppercase mt-4">Volte em breve</p>
      </div>
      <div className="w-full py-4 border-t border-gray-300">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center text-gray-600 text-sm md:space-x-8 space-y-1 md:space-y-0">
          <span className="font-bold">
            Você pode entrar em contato conosco em:
          </span>
          <a
            href="#"
            className="flex items-center space-x-1"
            target="_blank"
            title="Call"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            {typeof props.vendorWhatsAppPhone1 != "undefined" && (
              <span>
                {String(
                  props.vendorWhatsAppPhone1?.value ??
                    props.vendorWhatsAppPhone1
                )}
              </span>
            )}
          </a>
        </div>
      </div>
    </div>
  );
}
