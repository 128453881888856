"use client";

import { FormContact1Props } from "../../../props";
import { useState } from "react";
import { usePathname } from "next/navigation";
import { SubmitHandler, useForm } from "react-hook-form";
import { setCookie } from "cookies-next";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";
import { ChevronRight } from "lucide-react";
import Image from "next/image";

export const FormContactVariant04 = (props: FormContact1Props) => {
  const [toast, setToast] = useState<any>(null);

  const pathname = usePathname();

  const methods = useForm();

  const { register, handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setCookie("@user-datalayer", {
      fullName: data.name,
      email: data.email,
      phone: data.cellphone,
    });

    data.workflowId = props?.n8nWorkflowId?.value;
    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      data.originUrl = `${protocol}//${hostname}${
        port ? ":" + port : ""
      }${pathname}`;
    }

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      setToast({
        message: "Dados enviados com sucesso! Aguarde nosso contato.",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
    reset();
    setToast(null);
  };

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-7xl bg-white shadow-2xl overflow-hidden">
        <div className="flex flex-col lg:flex-row">
          {/* Seção de Imagem e Informações */}
          <div className="lg:w-1/2 relative">
            <div className="absolute inset-0 bg-black bg-opacity-60 z-10" />
            <Image
              src={props.image?.value as string}
              alt="Carro luxuoso"
              layout="fill"
              objectFit="cover"
              className="w-full h-full"
            />
            <div className="absolute inset-0 z-20 p-12 flex flex-col justify-between text-white">
              <div>
                <h1 className="text-4xl font-bold mb-6 tracking-wider">
                  Luxo em Movimento
                </h1>
                <p className="text-sm mb-8">
                  Experimente a fusão perfeita entre design e performance. Seu
                  próximo veículo está a um passo de distância.
                </p>
              </div>
            </div>
          </div>

          {/* Formulário */}
          <div className="lg:w-1/2 p-12 bg-gray-50">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">
              Agende sua Experiência
            </h2>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="relative">
                <input
                  type="text"
                  id="nome"
                  required
                  className="peer w-full border-b-2 border-gray-300 bg-transparent pt-4 pb-1 px-0 text-gray-900 focus:outline-none focus:border-black"
                  {...register("name")}
                />
                <label
                  htmlFor="nome"
                  className="absolute left-0 top-4 text-gray-600 transition-all duration-300 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-black peer-[:not(:placeholder-shown)]:-top-3 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:text-black"
                >
                  Seu Nome
                </label>
              </div>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  required
                  className="peer w-full border-b-2 border-gray-300 bg-transparent pt-4 pb-1 px-0 text-gray-900 focus:outline-none focus:border-black"
                  {...register("email")}
                />
                <label
                  htmlFor="email"
                  className="absolute left-0 top-4 text-gray-600 transition-all duration-300 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-black peer-[:not(:placeholder-shown)]:-top-3 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:text-black"
                >
                  Seu Email
                </label>
              </div>
              <div className="relative">
                <input
                  type="tel"
                  id="telefone"
                  className="peer w-full border-b-2 border-gray-300 bg-transparent pt-4 pb-1 px-0 text-gray-900 focus:outline-none focus:border-black"
                  {...register("cellphone")}
                />
                <label
                  htmlFor="telefone"
                  className="absolute left-0 top-4 text-gray-600 transition-all duration-300 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-black peer-[:not(:placeholder-shown)]:-top-3 peer-[:not(:placeholder-shown)]:text-sm peer-[:not(:placeholder-shown)]:text-black"
                >
                  Seu Telefone
                </label>
              </div>

              <button
                type="submit"
                className="w-full !bg-primary-500 text-white hover:bg-primary-900 transition-colors duration-300 py-3 text-lg font-semibold flex items-center justify-center"
              >
                Agendar Visita
                <ChevronRight className="ml-2 h-5 w-5" />
              </button>
            </form>
          </div>
        </div>
      </div>
      {toast && <PlatformsToast message={toast.message} type={toast.type} />}
    </div>
  );
};
