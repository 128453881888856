"use client";

import { CtaPremium1Props } from "./props";
import { usePathname } from "next/navigation";
import styles from "./cta-premium.module.scss";
import { handleFormatStyleString } from "~/utils";

export const CtaPremium1 = (props: CtaPremium1Props) => {
  const pathname = usePathname();
  const lastPath = pathname?.split("/").at(-1);

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      {!lastPath?.includes("-") && typeof props?.image != "undefined" && (
        <section
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
              props?.image?.value ?? props?.image
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className={`${styles.container} container-${props.id}`}
        >
          <div className={`${styles.wrapper} wrapper-${props.id}`}>
            {typeof props?.heading != "undefined" && (
              <h3>
                {props?.heading?.value ?? (props?.heading as unknown as string)}
              </h3>
            )}
            {typeof props?.description != "undefined" && (
              <div
                dangerouslySetInnerHTML={{
                  //@ts-ignore
                  __html:
                    props?.description?.value ??
                    (props?.description as unknown as string),
                }}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
};
