import { ComponentsBaseProps, Vehicle } from "../../../../../types";
import { InventoryProps } from "../mock";

export enum ContactActionType {
  whatsApp = "whatsApp",
  cellphone = "cellphone",
}

export enum ContactActionButtonType {
  default = "default",
  pulsing = "pulsing",
}

export interface ContactActionProps {
  actionType: ContactActionType;
  buttonType: ContactActionButtonType;
  required: boolean;
  label: string;
  placeholder: string;
  receiver: string;
  link: string;
  regex: string | null;
}

export interface VehicleDetails1Props extends ComponentsBaseProps {
  shouldRenderDetail: boolean;
  showPrice: boolean;
  contactActions: ContactActionProps[];
  data: {
    vehicle?: Vehicle;
    vehicleList?: Vehicle[];
    shouldRenderDetail: boolean;
  };
}

export interface GenerateLead {
  event: string;
  ecommerce: {
    currency: string;
    value: number | undefined;
    items: {
      item_id: string | undefined;
      item_name: string | undefined;
      item_brand: string | undefined;
      item_category: string;
      item_category2: string | undefined;
      item_variant: string | undefined;
      price: number | undefined;
      year: string | undefined;
      mileage: number | undefined;
      transmission: string | undefined;
      fuel_type: string | undefined;
      color: string | undefined;
      plate_end: string | null;
      quantity: number;
      optional_features: string | string[] | undefined;
    }[];
  };
  user_data?: {
    fullName: string | null;
    email: string | null;
    phone: string | null;
  };
}

export interface DetailsVariantsProps {
  props: InventoryProps;
  variant: number;
  vehicle: Vehicle;
  vehiclePrice: string;
}

export interface JsonLDProps {
  vehicle: Vehicle;
}

export interface IDataLead {
  name: string | undefined;
  email?: string;
  cellphone: string | undefined;
  message?: string;
  originUrl?: string;
  workflowId?: string;
  subdomain?: string;
  hostname?: string;
}
