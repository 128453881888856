import { compositeBrands } from "./composite-makes";
import { compositeCarModels } from "./composite-car-models";
import { compositeMotorcycleModels } from "./composite-motorcycle-models";
import { CarDetails, InventoryType, VehicleDetails } from "./props";
import { FilterDefaultQueryKeys } from "@sync/platforms/src/components/editable/inventory";

export function toTitleCase(str: string): string {
  return str
    .split(/([,]\s*|\s+)/) // Divida por vírgula seguida de zero ou mais espaços ou por espaço
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
}

export function extractSoldCarDetails(url: string): CarDetails | null {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [path, _] = url.split(":");
    const parts = path.split("/");
    const lastPart = parts[parts.length - 1];
    const segments = lastPart.split("-");

    const normalizedBrands = compositeBrands.map((brand) =>
      brand.toLowerCase().replace(/ /g, "-")
    );

    for (const brand of normalizedBrands) {
      const brandSegments = brand.split("-");
      const matchesBrand = brandSegments.every(
        (segment, index) => segment === segments[index].toLowerCase()
      );

      if (matchesBrand) {
        const brandLength = brandSegments.length;
        const year = segments[brandLength + 1];
        const model = segments.slice(brandLength, -1).join(" ");
        return {
          brand: compositeBrands[normalizedBrands.indexOf(brand)],
          model,
          year,
        };
      }
    }

    const year = segments[segments.length - 1] ?? null;
    const brand = toTitleCase(segments[0]) ?? null;
    const model = toTitleCase(segments.slice(1, -1).join(" ")) ?? null;

    return { brand, model, year };
  } catch (error) {
    console.error("Error extracting car details:", error);
    return null;
  }
}

function normalizeString(str: string): string {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

function normalizeAndReplace(value: string): string {
  return normalizeString(value).replace(/-/g, " ");
}

export function extractVehicleDetailsFromPathname(
  pathname: string
): VehicleDetails | null {
  let brand = "";
  let model = "";
  let version = "";
  let modelYear = "";

  const newPathname = pathname.replace(/\/([^\/]*)$/, "?$1");

  const url = `https://contoso${decodeURIComponent(newPathname)}`;
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  const category = searchParams.getAll(FilterDefaultQueryKeys.Category);
  const color = searchParams.getAll(FilterDefaultQueryKeys.Color);
  const transmission = searchParams.getAll(FilterDefaultQueryKeys.Transmission);
  const style = searchParams.getAll(FilterDefaultQueryKeys.Style);
  const fuelType = searchParams.getAll(FilterDefaultQueryKeys.FuelType);
  const minKm = searchParams.getAll(FilterDefaultQueryKeys.MinKm);
  const maxKm = searchParams.getAll(FilterDefaultQueryKeys.MaxKm);
  const minPrice = searchParams.getAll(FilterDefaultQueryKeys.MinPrice);
  const maxPrice = searchParams.getAll(FilterDefaultQueryKeys.MaxPrice);
  const yearMin = searchParams.getAll(FilterDefaultQueryKeys.ManufactureYear);
  const yearMax = searchParams.getAll(FilterDefaultQueryKeys.ModelYear);
  const optionals = searchParams.getAll(FilterDefaultQueryKeys.Optionals);
  const sortBy = searchParams.getAll(FilterDefaultQueryKeys.Sort);
  const page = searchParams.get(FilterDefaultQueryKeys.Page);

  const [, , vehicleType, region, brandModelVersionOrFilter] =
    pathname.split("/");

  const isMotorcycle = vehicleType === "motos";

  // Verifica se a última parte da URL é um filtro e não um modelo de veículo
  const brandModelVersionWithoutVehicleId = (
    decodeURIComponent(brandModelVersionOrFilter) ?? ""
  ).split(":")[0];

  // Filtros que podem estar na URL
  const filters = [
    ...transmission,
    ...fuelType,
    ...minKm,
    ...maxKm,
    ...minPrice,
    ...maxPrice,
    ...category,
    ...color,
    ...optionals,
  ];

  // Se a última parte da URL for um filtro, não trate como marca/modelo
  if (
    filters.some((filter) => brandModelVersionWithoutVehicleId.includes(filter))
  ) {
    // Não há marca/modelo, já que a última parte é um filtro
    return {
      type: isMotorcycle ? InventoryType.Motorcycle : InventoryType.Car,
      region: toTitleCase(region?.replace(/-/g, " ") ?? ""),
      make: "",
      model: "",
      version: "",
      minYear: null,
      maxYear: null,
      modelYear: null,
      filter: {
        category,
        color,
        transmission,
        style,
        fuelType,
        minKm: minKm.map((km) => parseInt(km, 10)),
        maxKm: maxKm.map((km) => parseInt(km, 10)),
        minPrice: minPrice.map((price) => parseFloat(price)),
        maxPrice: maxPrice.map((price) => parseFloat(price)),
        optionals,
        sortBy,
        page,
        minYear: Number(yearMin),
        maxYear: Number(yearMax),
      },
    };
  }

  const parts = brandModelVersionWithoutVehicleId.split("-");

  const normalizedCompositeBrands = compositeBrands.map((b) =>
    normalizeAndReplace(b)
  );
  const normalizedCompositeCarModels = compositeCarModels.map((m) =>
    normalizeAndReplace(m)
  );
  const normalizedCompositeMotorcycleModels = compositeMotorcycleModels.map(
    (m) => normalizeAndReplace(m)
  );

  // Encontrar a marca composta usando compositeBrands normalizadas
  for (let i = parts.length; i > 0; i--) {
    const possibleBrand = normalizeAndReplace(parts.slice(0, i).join(" "));
    if (normalizedCompositeBrands.includes(possibleBrand)) {
      brand = parts.slice(0, i).join(" "); // Marca composta encontrada
      model = parts.slice(i).join("-"); // O restante é o modelo + versão
      break;
    }
  }

  // Se não encontrar uma marca composta, assume que o primeiro nome é a marca
  if (!brand) {
    brand = normalizeAndReplace(parts[0]);
    model = parts.slice(1).join("-");
  }

  // Separar modelo e versão
  const modelParts = model.split("-");

  for (let i = modelParts.length; i > 0; i--) {
    const possibleModel = modelParts.slice(0, i).join(" ");
    const isCompositeModel = isMotorcycle
      ? normalizedCompositeMotorcycleModels.includes(possibleModel)
      : normalizedCompositeCarModels.includes(possibleModel);

    if (isCompositeModel) {
      model = modelParts.slice(0, i).join(" "); // Modelo composto encontrado
      version = modelParts.slice(i).join(" "); // O restante é a versão

      const yearMatch = version.match(/\b(19|20)\d{2}\b/);
      modelYear = yearMatch ? yearMatch[0] : "";

      break;
    }

    // Se não for composto, o primeiro nome é o modelo, e o restante é a versão
    if (i === 1) {
      model = modelParts[0];
      version = modelParts.slice(1).join(" ");

      const yearMatch = version.match(/\b(19|20)\d{2}\b/);
      modelYear = yearMatch ? yearMatch[0] : "";
    }
  }

  const filter = {
    category,
    color,
    transmission,
    style,
    fuelType,
    minKm: minKm.map((km) => parseInt(km, 10)),
    maxKm: maxKm.map((km) => parseInt(km, 10)),
    minPrice: minPrice.map((price) => parseFloat(price)),
    maxPrice: maxPrice.map((price) => parseFloat(price)),
    optionals,
    sortBy,
    page,
    minYear: Number(yearMin),
    maxYear: Number(yearMax),
  };

  const hasFilterValues =
    filter.category.length > 0 ||
    filter.color.length > 0 ||
    filter.style.length > 0 ||
    filter.transmission.length > 0 ||
    filter.fuelType.length > 0 ||
    filter.minKm.length > 0 ||
    filter.maxKm.length > 0 ||
    filter.minPrice.length > 0 ||
    filter.maxPrice.length > 0 ||
    filter.optionals.length > 0 ||
    filter.sortBy.length > 0 ||
    filter.minYear > 0 ||
    filter.maxYear > 0 ||
    filter.page !== null;

  return {
    type: isMotorcycle ? InventoryType.Motorcycle : InventoryType.Car,
    region: toTitleCase(region?.replace(/-/g, " ") ?? ""),
    make: brand && brand != "undefined" ? fixMessBrandFuncion(brand) : "",
    model: toTitleCase(normalizeAndReplace(model)),
    version: normalizeAndReplace(version),
    minYear: null,
    maxYear: null,
    modelYear: modelYear ? parseInt(modelYear, 10) : null,
    filter: hasFilterValues ? filter : null,
  };
}

function fixMessBrandFuncion(brand: string) {
  return decodeURIComponent(toTitleCase(normalizeAndReplace(brand))).includes(
    "="
  )
    ? ""
    : toTitleCase(normalizeAndReplace(brand));
}
