"use client";

import { ChangeEvent, Suspense, useCallback } from "react";
import { ClientFilterProps } from "../props";
import ClientFilterVariant1 from "./variants/01";
import ClientFilterVariant2 from "./variants/02";
import { usePathname, useRouter } from "next/navigation";
import { Filter1Skeleton } from "../skeleton";
import slugify from "slugify";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";
import { FilterDefaultQueryKeys, filterItemsOptions } from "./options";
import { WebsitePreferenceType } from "../../mock";

function ClientFilter({ children, props }: ClientFilterProps) {
  const variant = Number(props?.variants?.value ?? 1);
  const { push } = useRouter();
  const asPath = usePathname();

  // Função para determinar o tipo de coleção ("carros", "motos", ou "carros-e-motos")
  const getVehiclePathType = (ref: string, value: string) => {
    const vehicles = props.data.vehicleList;

    if (ref === "carroceria") {
      return "carros";
    }

    if (ref === "estilo") {
      return "motos";
    }

    if (ref === "transmissao") {
      return "carros";
    }

    const hasCars = vehicles?.some((v) => {
      const makeMatch = slugify(v.make, { lower: true }) === value;
      const modelMatch = slugify(v.model, { lower: true }) === value;
      return v.type === "Car" && (ref === "marca" ? makeMatch : modelMatch);
    });

    const hasMotorcycles = (vehicles ?? []).some((v) => {
      const makeMatch = slugify(v.make, { lower: true }) === value;
      const modelMatch = slugify(v.model, { lower: true }) === value;
      return (
        v.type === "Motocycle" && (ref === "marca" ? makeMatch : modelMatch)
      );
    });

    if (hasCars && hasMotorcycles) {
      return "carros-e-motos";
    } else if (hasCars) {
      return "carros";
    } else if (hasMotorcycles) {
      return "motos";
    }
    return "carros-e-motos";
  };

  const handleFilter = useCallback(
    (
      e: ChangeEvent<HTMLInputElement>,
      item: string | string[],
      filterType: string | string[]
    ) => {
      const clientPathName =
        props.data.site.preferences.find(
          (preference: { type: WebsitePreferenceType }) =>
            preference.type === WebsitePreferenceType.inventoryPathName
        )?.value || "estoque";

      // Extraímos os detalhes atuais da URL usando a função que você forneceu
      const vehicleDetails = extractVehicleDetailsFromPathname(
        asPath as string
      );

      const clientMainRegion = vehicleDetails?.region
        ? vehicleDetails?.region
        : props.data.site.preferences
            .find(
              (preference: { type: string }) =>
                preference.type === WebsitePreferenceType.localSeoSlug
            )
            ?.value?.split(",")[0] || "brasil";

      // Garante que clientPathName sempre seja incluído
      const basePath = `/${slugify(clientPathName, { lower: true })}`;
      let checked = e.target.checked;

      // tratamento para inputs do tipo range que são os slide-items
      if (!checked && e.target?.id?.includes("range")) {
        if (
          e.target.value &&
          (Number(e.target.value) > 0 ||
            (Array.isArray(e.target.value) &&
              e.target.value.some((i) => Number(i) > 0)))
        ) {
          checked = true;
        } else {
          checked = false;
        }
      }

      // Usar a marca e o modelo atuais extraídos da URL
      let currentBrand = vehicleDetails?.make
        ? slugify(vehicleDetails.make, { lower: true })
        : "";
      let currentModel = vehicleDetails?.model
        ? slugify(vehicleDetails.model, { lower: true })
        : "";

      // const currentVersion = vehicleDetails?.version
      //   ? slugify(vehicleDetails.version, { lower: true })
      //   : "";

      // Atualiza currentBrand quando o filtro for de marca
      if (filterType === "marca") {
        currentBrand = checked ? slugify(item as string, { lower: true }) : "";
      }

      // Atualização de modelo no path
      const slugifiedItem = slugify(Array.isArray(item) ? item[0] : item, {
        lower: true,
      });
      const slugifiedItem2 =
        Array.isArray(item) && item[1]
          ? slugify(item[1], { lower: true })
          : null;

      if (filterType === "modelo") {
        if (currentBrand && !currentModel && checked) {
          // Se há uma marca e o modelo está vazio, preenche com o modelo
          currentModel = slugifiedItem;
        } else if (!currentBrand && checked) {
          // Se não há marca e o modelo foi selecionado, preenche apenas o modelo
          currentModel = slugifiedItem;
        } else if (checked) {
          // Se há uma marca e um modelo, atualiza apenas o modelo
          currentModel = slugifiedItem;
        } else {
          // Se o filtro foi desmarcado
          if (slugifiedItem === currentModel) {
            // Se o modelo foi desmarcado, limpa o currentModel
            currentModel = "";
          }
        }
      }

      // Concatena a marca e o modelo no mesmo path
      // Se houver apenas o modelo, ele é prefixado com um hífen "-"
      const brandModelSlug =
        currentBrand && currentModel
          ? `${currentBrand}-${currentModel}` // Marca e Modelo presentes
          : currentBrand
          ? currentBrand // Apenas a Marca
          : currentModel
          ? `-${currentModel}` // Apenas o Modelo com prefixo "-"
          : ""; // Nenhum presente

      // Tratamento dos filtros adicionais com base nas opções de filtro
      let filters = "";
      let urlFilters: string[] = [];

      if (vehicleDetails?.filter) {
        // Adiciona os filtros atuais ao urlFilters conforme o enum e formata com queryKey
        urlFilters = [
          ...vehicleDetails.filter.category.map(
            (cat) => `${FilterDefaultQueryKeys.Category}=${cat}`
          ),
          ...vehicleDetails.filter.color.map(
            (col) => `${FilterDefaultQueryKeys.Color}=${col}`
          ),
          ...vehicleDetails.filter.fuelType.map(
            (fuel) => `${FilterDefaultQueryKeys.FuelType}=${fuel}`
          ),
          ...vehicleDetails.filter.transmission.map(
            (trans) => `${FilterDefaultQueryKeys.Transmission}=${trans}`
          ),
          ...vehicleDetails.filter.style.map(
            (sty) => `${FilterDefaultQueryKeys.Style}=${sty}`
          ),
          ...vehicleDetails.filter.optionals.map(
            (opt) => `${FilterDefaultQueryKeys.Optionals}=${opt}`
          ),
          ...vehicleDetails.filter.sortBy.map(
            (sort) => `${FilterDefaultQueryKeys.Sort}=${sort}`
          ),
          ...vehicleDetails.filter.minKm.map(
            (km) => `${FilterDefaultQueryKeys.MinKm}=${km}`
          ),
          ...vehicleDetails.filter.maxKm.map(
            (km) => `${FilterDefaultQueryKeys.MaxKm}=${km}`
          ),
          ...vehicleDetails.filter.minPrice.map(
            (price) => `${FilterDefaultQueryKeys.MinPrice}=${price}`
          ),
          ...vehicleDetails.filter.maxPrice.map(
            (price) => `${FilterDefaultQueryKeys.MaxPrice}=${price}`
          ),
          vehicleDetails.filter.minYear && vehicleDetails.filter.minYear !== 0
            ? `${FilterDefaultQueryKeys.ManufactureYear}=${vehicleDetails.filter.minYear}`
            : "",
          vehicleDetails.filter.maxYear && vehicleDetails.filter.maxYear !== 0
            ? `${FilterDefaultQueryKeys.ModelYear}=${vehicleDetails.filter.maxYear}`
            : "",
        ].filter(
          (item) => item !== null && item !== undefined && item.length !== 0
        ); // Remove valores nulos, indefinidos, vazios e 0
      }

      // Use filterItemsOptions para mapear os filtros de maneira dinâmica
      const filterOption = filterItemsOptions.find(
        (option) =>
          option.queryKey === filterType ||
          option.queryKey.includes(filterType as FilterDefaultQueryKeys)
      );

      if (filterOption) {
        if (
          filterOption.queryKey === FilterDefaultQueryKeys.Category ||
          filterOption.isKm ||
          filterOption.queryKey === FilterDefaultQueryKeys.Style ||
          filterOption.queryKey === FilterDefaultQueryKeys.Transmission ||
          filterOption.queryKey === FilterDefaultQueryKeys.Color ||
          filterOption.queryKey.includes(
            FilterDefaultQueryKeys.ManufactureYear
          ) ||
          filterOption.queryKey.includes(FilterDefaultQueryKeys.ModelYear) ||
          filterOption.queryKey === FilterDefaultQueryKeys.MinKm ||
          filterOption.queryKey === FilterDefaultQueryKeys.MaxKm ||
          filterOption.queryKey === FilterDefaultQueryKeys.MinPrice ||
          filterOption.queryKey === FilterDefaultQueryKeys.MaxPrice
          // || filterOption.queryKey === FilterDefaultQueryKeys.Version
        ) {
          if (slugifiedItem2) {
            filters = `${filterType[0]}=${slugifiedItem}&${filterType[1]}=${slugifiedItem2}`;
          } else {
            filters = `${filterType}=${slugifiedItem}`;
          }
        }

        // TODO Melhorar para passarmos na URL da forma baixo:
        // else if (
        //   filterOption.queryKey === FilterDefaultQueryKeys.ManufactureYear
        // ) {
        //   filters = `de.${slugifiedItem}`;
        // } else if (filterOption.queryKey === FilterDefaultQueryKeys.ModelYear) {
        //   filters = `ate.${slugifiedItem}`;
        // }
      }

      // Se o filtro foi marcado, adiciona ao array de filtros
      if (checked) {
        urlFilters.push(filters);
      } else {
        // Remoção de filtros
        if (
          filterOption?.queryKey.includes(
            FilterDefaultQueryKeys.ManufactureYear
          ) ||
          filterOption?.queryKey.includes(FilterDefaultQueryKeys.ModelYear) ||
          filterOption?.queryKey === FilterDefaultQueryKeys.MinKm ||
          filterOption?.queryKey === FilterDefaultQueryKeys.MaxKm ||
          filterOption?.queryKey === FilterDefaultQueryKeys.MinPrice ||
          filterOption?.queryKey === FilterDefaultQueryKeys.MaxPrice ||
          filterOption?.queryKey === FilterDefaultQueryKeys.Optionals ||
          filterOption?.queryKey === FilterDefaultQueryKeys.Category ||
          filterOption?.queryKey === FilterDefaultQueryKeys.Color ||
          filterOption?.queryKey === FilterDefaultQueryKeys.Transmission ||
          filterOption?.queryKey === FilterDefaultQueryKeys.FuelType ||
          filterOption?.queryKey === FilterDefaultQueryKeys.Style ||
          filterOption?.queryKey === FilterDefaultQueryKeys.Sort
        ) {
          urlFilters.splice(urlFilters.indexOf(filters), 1);
        }
      }

      function removeDuplicateYearFilters(arr: string[]): string[] {
        const filteredArr = arr.filter(
          (item) => !item.startsWith("anoMin=") && !item.startsWith("anoMax=")
        );

        const lastYearFilter = arr
          .reverse()
          .find((item) => item.includes("anoMin") || item.includes("anoMax"));

        if (lastYearFilter) {
          filteredArr.push(lastYearFilter);
        }

        return filteredArr;
      }

      if (urlFilters.some((i) => i.includes("&"))) {
        urlFilters = removeDuplicateYearFilters(urlFilters);
      }

      // Concatena os filtros no final da URL
      const filterString = urlFilters.length
        ? `/${urlFilters.join("&").replace(/^&+|&+$/g, "")}` // garante que nenhum & fique no começo ou no fim da query
        : "";

      // Determina o tipo de veículo no path (carros, motos, ou carros-e-motos) com base em currentBrand
      const vehiclePathType = getVehiclePathType(filterType[0], item as string);

      // Atualiza a URL com os filtros no final
      const updatedPath = `${basePath}/${vehiclePathType}/${slugify(
        clientMainRegion,
        {
          lower: true,
        }
      )}/${brandModelSlug}${filterString}`;

      push(updatedPath, { scroll: false });
    },
    [asPath, push, props.data.filteredList]
  );

  function renderFilter() {
    switch (variant) {
      case 1:
        return (
          <ClientFilterVariant1 props={props} handleFilter={handleFilter}>
            {children}
          </ClientFilterVariant1>
        );
      case 2:
        return (
          <ClientFilterVariant2 props={props} handleFilter={handleFilter}>
            {children}
          </ClientFilterVariant2>
        );
      default:
        return (
          <ClientFilterVariant1 props={props} handleFilter={handleFilter}>
            {children}
          </ClientFilterVariant1>
        );
    }
  }

  return (
    <Suspense
      fallback={
        <Filter1Skeleton {...props}>
          <div />
        </Filter1Skeleton>
      }
    >
      {renderFilter()}
    </Suspense>
  );
}

export default ClientFilter;
