import { SWHomeCardProps } from "./props";
import dynamic from "next/dynamic";

const VehicleFeatured01 = dynamic(() => import("./variants/01"), {
  ssr: true,
}) as any;
const VehicleFeatured02 = dynamic(() => import("./variants/02"), {
  ssr: true,
}) as any;
const VehicleFeatured03 = dynamic(() => import("./variants/03"), {
  ssr: true,
}) as any;
const VehicleFeatured04 = dynamic(() => import("./variants/04"), {
  ssr: true,
}) as any;

export default function CardsVariants({
  variant,
  props,
  vehicle,
  idx,
}: SWHomeCardProps) {
  function renderCardVariants(): JSX.Element {
    switch (variant) {
      case "1":
        return (
          <VehicleFeatured01 key={idx} idx={idx} vehicle={vehicle} {...props} />
        );
      case "2":
        return (
          <VehicleFeatured02 key={idx} idx={idx} vehicle={vehicle} {...props} />
        );
      case "3":
        return (
          <VehicleFeatured03 key={idx} idx={idx} vehicle={vehicle} {...props} />
        );
      case "4":
        return (
          <VehicleFeatured04 key={idx} idx={idx} vehicle={vehicle} {...props} />
        );
      default:
        return (
          <VehicleFeatured01 key={idx} idx={idx} vehicle={vehicle} {...props} />
        );
    }
  }

  return renderCardVariants();
}
