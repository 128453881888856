"use client";
import { SwPremiumModule1Props } from "./props";
import { VehiclePremiumCard } from "./vehicle-premium-card";
import { usePathname } from "next/navigation";
import { useTheme } from "next-themes";
import { useEffect } from "react";
import { VehicleDetails1 } from "../../inventory";

import styles from "./premium-module.module.scss";
import { handleFormatStyleString } from "~/utils";

export function SwPremiumModule1(props: SwPremiumModule1Props) {
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme("dark");
  }, []);

  const vehicles = props?.data?.vehicleList || [];
  const vehiclesPremium = vehicles?.filter((vehicle) =>
    vehicle?.tarja?.includes("Premium")
  );
  const pathname = usePathname();
  const lastPath = pathname?.split("/").at(-1);
  const mainPath = pathname?.split("/")[1];

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      <style jsx global>
        {`
          #theme-toggle {
            display: none !important;
          }
        `}
      </style>
      {!lastPath?.includes("-") ? (
        <section className={`${styles.container} container-${props.id}`}>
          <div className={`${styles.wrapper} wrapper-${props.id}`}>
            <h2>Destaques</h2>
            {props && mainPath && (
              <div
                className={`${styles["cards-wrapper"]} cards-wrapper-${props.id}`}
              >
                {vehiclesPremium &&
                  vehiclesPremium?.map((vehicle, index) => (
                    <div key={index}>
                      <VehiclePremiumCard
                        vehicle={vehicle}
                        mainPath={mainPath}
                        props={props}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </section>
      ) : (
        <>
          <VehicleDetails1 {...(props as any)} />
        </>
      )}
    </>
  );
}
