import { FooterStoreAddressProps } from "./props";
import SocialIconsVariant1 from "./variants/01";
import SocialIconsVariant2 from "./variants/02";
import SocialIconsVariant3 from "./variants/03";

export default function FooterSocialIcons({
  variant,
  props,
}: FooterStoreAddressProps) {
  function renderSocialIcons(): JSX.Element {
    switch (variant) {
      case "1":
        return <SocialIconsVariant1 {...props} />;
      case "2":
        return <SocialIconsVariant2 {...props} />;
      case "3":
        return <SocialIconsVariant3 {...props} />;
      default:
        return <SocialIconsVariant1 {...props} />;
    }
  }

  return renderSocialIcons();
}
