"use client";

import { useEffect, useState } from "react";
import { ChipsProps } from "./props";
import styles from "./filter-chips.module.scss";
import {
  extractVehicleDetailsFromPathname,
  toTitleCase,
} from "@sync/utils/src/platforms"; // Importa o método de extração de path params
import { usePathname } from "next/navigation"; // Importa o hook do Next.js
import { FilterDefaultQueryKeys } from "../options";

export default function FilterChips({ handleFilter }: ChipsProps) {
  const pathname = usePathname(); // Usa o hook para pegar a URL no Next.js
  const [resultArray, setResultArray] = useState<
    { query: string; value: string }[]
  >([]);

  useEffect(() => {
    const details = extractVehicleDetailsFromPathname(pathname as string);
    const filters: { query: string; value: string }[] = [];

    if (details) {
      if (details.make) {
        filters.push({
          query: FilterDefaultQueryKeys.Make,
          value: details.make,
        });
      }
      if (details.model) {
        filters.push({
          query: FilterDefaultQueryKeys.Model,
          value: details.model,
        });
      }
      if (details.filter?.category && details.filter?.category.length) {
        filters.push({
          query: FilterDefaultQueryKeys.Category,
          value: details.filter.category[0],
        });
      }

      if (details.filter?.color && details.filter?.color.length) {
        filters.push({
          query: FilterDefaultQueryKeys.Color,
          value: details.filter.color[0],
        });
      }

      if (details.filter?.style && details.filter?.style.length) {
        filters.push({
          query: FilterDefaultQueryKeys.Style,
          value: details.filter.style[0],
        });
      }
      if (details.filter?.transmission && details.filter?.transmission.length) {
        filters.push({
          query: FilterDefaultQueryKeys.Transmission,
          value: details.filter.transmission[0],
        });
      }
      if (details.filter?.minKm && details.filter?.minKm.length) {
        filters.push({
          query: FilterDefaultQueryKeys.MinKm,
          value: details.filter.minKm[0].toString(),
        });
      }
      if (details.filter?.maxKm && details.filter?.maxKm.length) {
        filters.push({
          query: FilterDefaultQueryKeys.MaxKm,
          value: details.filter.maxKm[0].toString(),
        });
      }
      if (details.filter?.minPrice && details.filter?.minPrice.length) {
        filters.push({
          query: FilterDefaultQueryKeys.MinPrice,
          value: details.filter.minPrice[0].toString(),
        });
      }
      if (details.filter?.maxPrice && details.filter?.maxPrice.length) {
        filters.push({
          query: FilterDefaultQueryKeys.MaxPrice,
          value: details.filter.maxPrice[0].toString(),
        });
      }
      if (details.filter?.minYear) {
        filters.push({
          query: FilterDefaultQueryKeys.ManufactureYear,
          value: details.filter.minYear.toString(),
        });
      }
      if (details.filter?.maxYear) {
        filters.push({
          query: FilterDefaultQueryKeys.ModelYear,
          value: details.filter.maxYear.toString(),
        });
      }
    }
    setResultArray(filters); // Atualiza o estado com os filtros extraídos
  }, [pathname]); // Executa sempre que o pathname mudar

  const getFilterMessage = (query: string, value: string) => {
    const formatedValue = toTitleCase(value.replace("-", " "));
    switch (query) {
      case FilterDefaultQueryKeys.Make:
        return `Marca: ${formatedValue}`;
      case FilterDefaultQueryKeys.Model:
        return `Modelo: ${formatedValue}`;
      case FilterDefaultQueryKeys.Category:
        return `Categoria: ${formatedValue}`;
      case FilterDefaultQueryKeys.Color:
        return `Cor: ${formatedValue}`;
      case FilterDefaultQueryKeys.Style:
        return `Estilo: ${formatedValue}`;
      case FilterDefaultQueryKeys.Transmission:
        return `Transmissão: ${formatedValue}`;
      case FilterDefaultQueryKeys.MinKm:
        return `A partir de: ${Number(value).toLocaleString("pt-BR")}km`;
      case FilterDefaultQueryKeys.MaxKm:
        return `Até ${Number(value).toLocaleString("pt-BR")}km`;
      case FilterDefaultQueryKeys.MinPrice:
        return `Preço mínimo: ${Number(value).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: 0,
        })}`;
      case FilterDefaultQueryKeys.MaxPrice:
        return `Preço máximo: ${Number(value).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          maximumFractionDigits: 0,
        })}`;
      case FilterDefaultQueryKeys.ManufactureYear:
        return `Ano modelo a partir de: ${value}`;
      case FilterDefaultQueryKeys.ModelYear:
        return `Ano modelo até: ${value}`;
      default:
        return formatedValue; // Mensagem padrão para outros tipos de filtro
    }
  };

  return (
    <div className={styles.container}>
      {resultArray?.map((i) => (
        <span key={i?.value} id={i?.value} className={`${styles.chip} chip`}>
          <span>{getFilterMessage(i.query, toTitleCase(i.value))}</span>
          <button
            id={`clear-filters-${i?.value}-main`}
            type="button"
            className={`${styles.button} button`}
            name="clear filters"
            aria-label="Clear filters"
            onClick={(e) =>
              handleFilter(
                {
                  ...e,
                  target: {
                    ...e.target,
                    checked: false,
                    //@ts-ignore
                    remove: true,
                  },
                },
                i?.value,
                i?.query
              )
            }
          >
            <svg stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </span>
      ))}
    </div>
  );
}
