"use client";

import { SwHomeProps } from "../../props";
import styles from "./sw-home-variant-04.module.scss";
import CardsVariants from "../../../../../non-editable/misc/card-variants";
import { PlatformsIcon } from "~/components/non-editable/core";
import { useState } from "react";

export const SwHomeVariant04 = (props: SwHomeProps): JSX.Element => {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const vehicleList = props?.data?.vehicleList ?? [];

  const vehicleListLine1 = vehicleList.slice(0, 4);
  const vehicleListLine2 = vehicleList.slice(5, 9);

  return (
    <section className={`${styles.section} section-${props.id}`}>
      <div className={`${styles.container} container-${props.id}`}>
        <div className={`${styles.wrapper} wrapper-${props.id}`}>
          <h2>Veículos em destaque</h2>

          <div
            className={`${styles["images-container"]} images-container-${props.id} navigation-wrapper`}
          >
            {vehicleListLine1 &&
              vehicleListLine1?.map((vehicle, idx: number) => (
                <CardsVariants
                  key={idx}
                  vehicle={vehicle}
                  idx={idx}
                  variant="4"
                  props={props}
                />
              ))}
          </div>
          <div
            className={`${styles["images-container"]} images-container-${props.id} navigation-wrapper`}
          >
            {seeMore &&
              vehicleListLine2 &&
              vehicleListLine2?.map((vehicle, idx: number) => (
                <CardsVariants
                  key={idx}
                  vehicle={vehicle}
                  idx={idx}
                  variant="4"
                  props={props}
                />
              ))}
          </div>
          <div
            className={`${styles["button-container"]} button-container-${props.id}`}
          >
            <button
              className={`${styles.button} button-${props.id}`}
              onClick={() => setSeeMore((state) => !state)}
            >
              {!seeMore ? "Ver mais" : "Ver menos"}

              {!seeMore ? (
                <PlatformsIcon
                  icon="eva:chevron-down-fill"
                  width={30}
                  height={30}
                />
              ) : (
                <PlatformsIcon
                  icon="eva:chevron-up-fill"
                  width={30}
                  height={30}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
