import React, { useRef, useState, useEffect, useCallback } from "react";
import Image from "next/image";
import { Vehicle } from "~/types";
import { BrandNewVehicleFiles } from "../props";
import { Globe } from "lucide-react";

interface FeatureSliderProps {
  features: BrandNewVehicleFiles[];
  vehicle: Vehicle;
}

const FeatureSlider: React.FC<FeatureSliderProps> = ({ features, vehicle }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [slidesPerGroup, setSlidesPerGroup] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const updateSlidesPerView = useCallback(() => {
    if (sliderRef.current && sliderRef.current.children.length > 0) {
      let spv = 1;
      let spg = 1;
      let mobile = false;

      if (window.innerWidth >= 1024) {
        // para 3 items, mudar para 3
        spv = 4;
        spg = 4;
      } else if (window.innerWidth >= 640) {
        spv = 2;
        spg = 2;
      } else {
        spv = 1;
        spg = 1;
        mobile = true;
      }

      setSlidesPerView(spv);
      setSlidesPerGroup(spg);
      setIsMobile(mobile);
    }
  }, []);

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);
    return () => window.removeEventListener("resize", updateSlidesPerView);
  }, [updateSlidesPerView]);

  const scrollToIndex = useCallback(
    (index: number) => {
      if (sliderRef.current) {
        const totalSlides = features.length;
        if (index >= totalSlides) {
          index = 0;
        } else if (index < 0) {
          index = totalSlides - slidesPerView;
          if (index < 0) index = 0;
        }
        const slide = sliderRef.current.children[index] as HTMLElement;
        if (slide) {
          const offsetLeft = slide.offsetLeft - sliderRef.current.offsetLeft;
          sliderRef.current.scrollTo({
            left: offsetLeft,
            behavior: "smooth",
          });
          setCurrentIndex(index);
        }
      }
    },
    [features.length, slidesPerView]
  );

  const scrollLeft = useCallback(() => {
    const totalSlides = features.length;
    let newIndex = currentIndex - slidesPerGroup;
    if (newIndex < 0) {
      newIndex = totalSlides - slidesPerView;
      if (newIndex < 0) newIndex = 0;
    }
    scrollToIndex(newIndex);
  }, [
    currentIndex,
    slidesPerGroup,
    slidesPerView,
    features.length,
    scrollToIndex,
  ]);

  const scrollRight = useCallback(() => {
    const totalSlides = features.length;
    let newIndex = currentIndex + slidesPerGroup;
    if (newIndex >= totalSlides) {
      newIndex = 0;
    }
    scrollToIndex(newIndex);
  }, [currentIndex, slidesPerGroup, features.length, scrollToIndex]);

  return (
    <div className="max-w-[85rem] px-3 py-10 sm:px-3 lg:px-3 lg:py-14 mx-auto">
      <div className="max-w-[1500px] mx-auto mb-4 lg:mb-6">
        <div className="flex gap-2 items-center">
          <Globe color="#8b8b8b" />
          <p className="text-[#8b8b8b]">
            <span className="underline">Tec</span>nologia e Conectividade
          </p>
        </div>
        <h2 className="text-2xl font-bold md:text-2xl md:leading-tight text-black">
          Seu dia a dia ainda mais tecnológico com o {vehicle.model}
        </h2>
        <p className="text-black">
          Conte com praticidades tecnológicas sempre à mão com itens de série
          equipados para transformar o seu novo carro 0 km.
        </p>
      </div>
      {/* para 3 items, mudar para 1200 */}
      <div className="relative max-w-[1500px] mx-auto ">
        {!isMobile && (
          <>
            <button
              onClick={scrollLeft}
              className="flex absolute left-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white dark:bg-gray-800 rounded-full shadow"
              aria-label="Slide anterior"
            >
              <svg
                className="w-6 h-6 text-gray-600 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M15 18l-6-6 6-6" />
              </svg>
            </button>

            <button
              onClick={scrollRight}
              className="flex absolute right-0 top-1/2 transform -translate-y-1/2 z-10 p-2 bg-white dark:bg-gray-800 rounded-full shadow"
              aria-label="Próximo slide"
            >
              <svg
                className="w-6 h-6 text-gray-600 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                <path d="M9 6l6 6-6 6" />
              </svg>
            </button>
          </>
        )}

        <div
          ref={sliderRef}
          className={`flex scroll-smooth snap-x snap-mandatory max-w-[1500px] mx-auto justify-start ${
            isMobile ? "overflow-x-auto" : "overflow-x-hidden"
          }`}
        >
          {features.map((feature, index) => (
            <div
              key={index}
              // para 3 filterItemsOptions, mudar para lg:w-1/3
              className="flex-shrink-0 w-full sm:w-1/2 lg:w-1/4 px-2 snap-center"
            >
              <div className="group flex flex-col focus:outline-none h-full max-w-[350px] min-w-[300px] rounded-t-lg overflow-hidden mx-auto justify-between">
                <div className="relative pt-[70%] overflow-hidden">
                  <Image
                    className="absolute object-center top-0 left-0 w-full h-full group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
                    src={feature.url}
                    alt={feature.title ?? "Imagem do Card"}
                    layout="fill"
                  />
                </div>

                <div
                  className="flex flex-col flex-grow justify-between border-b-[1px] border-l-[1px] border-r-[1px] p-2 border-stone-200"
                  style={{
                    borderBottomRightRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                >
                  <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-neutral-300 dark:group-hover:text-white">
                    {feature.title}
                  </h3>
                  <p className="mt-2 text-lg md:text-sm font-normal flex-grow">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureSlider;
