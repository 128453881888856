import { Vehicle } from "~/types";
import { InventoryProps } from "../../../../mock";

import styles from "./optionals-variant-01.module.scss";

export default function OptionalVariant01(props: InventoryProps) {
  const vehicle =
    props && props.data && props.data.vehicle
      ? (props.data.vehicle as Vehicle)
      : null;

  return (
    <section className={`${styles.information} information-${props.id}`}>
      <h2>Opcionais</h2>

      <div className={`${styles["info-wrapper"]} info-wrapper-${props.id}`}>
        <dl className={`${styles.information} information-${props.id}`}>
          {vehicle?.features?.split(",").map((v, idx) => (
            <div
              className={`${styles["info-wrapper"]} info-wrapper-${props.id}`}
              key={idx}
            >
              <dd className={`${styles["dd-variant"]} dd-variant-${props.id}`}>
                {v}
              </dd>
            </div>
          ))}
        </dl>
      </div>

      <div className={`${styles.about} about-${props.id}`}>
        <h2>Sobre este carro</h2>
        <p>{vehicle?.generalDescription}</p>
      </div>
    </section>
  );
}
