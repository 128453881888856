import Link from "next/link";
import styles from "./menu-variants-03.module.scss";
import { Footer1Props } from "../../../props";
import { FieldParams } from "~/constants";

export default function FooterMenuVariant03(props: Footer1Props) {
  return (
    <div className={styles["container__menu__wrapper"]}>
      {(props.menus.items ?? (props.menus as unknown as any)).map(
        (item: FieldParams, idx: number) => (
          <>
            <Link
              key={idx}
              href={String(item.link ?? item.link)}
              className={`${styles["container__menu__item"]} container__menu__item__${props.id}`}
            >
              <div className={styles["container__menu__item__decorator"]} />
              {item.label}
            </Link>
          </>
        )
      )}
    </div>
  );
}
