import { InformationVariationProps } from "./props";
import DetailsInformationVariant01 from "./variants/01";
import DetailsInformationVariant02 from "./variants/02";

export default function DetailsInformationVariants({
  props,
  variant,
}: InformationVariationProps) {
  function renderVariants(): JSX.Element {
    switch (variant) {
      case 1:
        return <DetailsInformationVariant01 {...props} />;
      case 2:
        return <DetailsInformationVariant02 {...props} />;
      default:
        return <DetailsInformationVariant01 {...props} />;
    }
  }

  return renderVariants();
}
