"use client";

import { handleFormatStyleString } from "~/utils";
import { NavigationComponentProps } from "./props";
import { NavigationVariant01 } from "./variants/01";

export const Navigation = (props: NavigationComponentProps) => {
  const variant = Number(props?.variants?.value) ?? 1;

  function renderVariant(): JSX.Element {
    switch (variant) {
      case 1:
        return <NavigationVariant01 {...props} />;

      default:
        return <NavigationVariant01 {...props} />;
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(
            props.styles?.value as string,
            props.id,
            props.styles?.classes
          )}
        </style>
      )}

      {renderVariant()}
    </>
  );
};
