export const options = [
  {
    label: "Ano",
    value: ["manufactureYear", "modelYear"],
    visibleKey: null,
    textTransform: null,
    vehicleType: "all",
    icon: "year.svg",
  },
  {
    label: "KM",
    value: "kilometers",
    visibleKey: "showVehicleKm",
    textTransform: (value: number) =>
      value.toLocaleString("pt-BR", {
        style: "decimal",
      }),
    vehicleType: "all",
    icon: "km.svg",
  },
  {
    label: "Câmbio",
    value: "transmission",
    visibleKey: null,
    textTransform: null,
    vehicleType: "car",
    icon: "gear.svg",
  },
  {
    label: "Numero de marchas",
    value: "numberGears",
    visibleKey: null,
    textTransform: null,
    vehicleType: "motorcycle",
    icon: "gear.svg",
  },
  {
    label: "Carroceria",
    value: "category",
    visibleKey: null,
    textTransform: null,
    vehicleType: "car",
    icon: "category.svg",
  },
  {
    label: "Combustível",
    value: "fuel",
    visibleKey: null,
    textTransform: null,
    vehicleType: "all",
    icon: "gas.svg",
  },
  {
    label: "Final da Placa",
    value: "plate",
    visibleKey: null,
    textTransform: (value: string | null) =>
      value !== null ? value.slice(-1) : "0 KM",
    vehicleType: "all",
    icon: "plate.svg",
  },
  {
    label: "Cor",
    value: "color",
    visibleKey: null,
    textTransform: null,
    vehicleType: "all",
    icon: "color.svg",
  },
  {
    label: "Tipo de freio",
    value: "typeBrake",
    visibleKey: null,
    textTransform: null,
    vehicleType: "motocycle",
    icon: "brake.svg",
  },
  {
    label: "Tipo de resfriamento",
    value: "typeCooling",
    visibleKey: null,
    textTransform: null,
    vehicleType: "motocycle",
    icon: "cooling.svg",
  },
  {
    label: "Tipo do motor",
    value: "typeEngine",
    visibleKey: null,
    textTransform: null,
    vehicleType: "motocycle",
    icon: "engine.svg",
  },
  {
    label: "Tipo de ignição",
    value: "typeStart",
    visibleKey: null,
    textTransform: null,
    vehicleType: "motocycle",
    icon: "ignition.svg",
  },
  {
    label: "Tipo de alimentação",
    value: "powerType",
    visibleKey: null,
    textTransform: null,
    vehicleType: "motocycle",
    icon: "power.svg",
  },
  {
    label: "Estilo de moto",
    value: "powerType",
    visibleKey: null,
    textTransform: null,
    vehicleType: "style",
  },
];
