import { FormContact1Props } from "../../../props";
import { useState } from "react";
import { RHFInputText } from "~/components/non-editable/hook-form/input-text";
import { usePathname } from "next/navigation";
import { SubmitHandler, useForm } from "react-hook-form";
import { setCookie } from "cookies-next";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";
import styles from "./form-contact-variant-03.module.scss";

export const FormContactVariant03 = (props: FormContact1Props) => {
  const [toast, setToast] = useState<any>(null);

  const pathname = usePathname();

  const methods = useForm();
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    setCookie("@user-datalayer", {
      fullName: data.name,
      email: data.email,
      phone: data.cellphone,
    });

    data.workflowId = props?.n8nWorkflowId?.value;
    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      data.originUrl = `${protocol}//${hostname}${
        port ? ":" + port : ""
      }${pathname}`;
    }

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      setToast({
        message: "Dados enviados com sucesso! Aguarde nosso contado.",
        type: "success",
      });
    } catch (error) {
      console.error(error);
    }
    reset();
    setToast(null);
  };

  return (
    <div className={`${styles.container} container-${props.id}`}>
      <div
        className={`${styles["container__image"]} container__image-${props.id}`}
        style={{
          backgroundImage: `linear-gradient(to top,rgba(0, 0, 0, 0.9) -10%, rgba(0, 0, 0, 0)), url(${
            props.image && props.image.value
              ? props.image.value ?? props.image
              : ""
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div
        className={`${styles["container__form"]} container__form-${props.id}`}
      >
        <div className={`${styles["form__wrapper"]} form__wrapper-${props.id}`}>
          {props && props.formName && props.formName.value && (
            <h1 className={`${styles.title} title-${props.id}`}>
              {props.formName.value ?? "Fale Conosco"}
            </h1>
          )}
          {typeof props.description != "undefined" && (
            <div
              className={`${styles.description} description-${props.id}`}
              dangerouslySetInnerHTML={{
                __html:
                  (props.description.value as string) ??
                  (props.description as unknown as string),
              }}
            />
          )}
          <form
            className={`${styles.form}`}
            onSubmit={(e) => {
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
          >
            <RHFInputText
              placeholder="Nome"
              key="form-contact-name"
              type="text"
              name="name"
              label="Nome"
              register={register}
              props={{
                required: true,
                minLength: 5 as number,
                maxLength: 100 as number,
              }}
            />
            {errors["name"] && (
              <span
                className={`${styles["form__error-text"]} form__error-text-${props.id}`}
              >
                {errors["name"]?.message as string}
              </span>
            )}
            <RHFInputText
              placeholder="Email"
              key="form-contact-email"
              type="email"
              name="email"
              label="Email"
              register={register}
              props={{
                required: true,
                minLength: 5 as number,
                maxLength: 100 as number,
              }}
            />
            {errors["email"] && (
              <span
                className={`${styles["form__error-text"]} form__error-text-${props.id}`}
              >
                {errors["email"]?.message as string}
              </span>
            )}
            <RHFInputText
              placeholder="Telefone"
              key="form-contact-cellphone"
              type="tel"
              name="cellphone"
              label="Telefone"
              register={register}
              props={{
                required: true,
                minLength: 5 as number,
                maxLength: 100 as number,
              }}
            />
            {errors["phone"] && (
              <span
                className={`${styles["form__error-text"]} form__error-text-${props.id}`}
              >
                {errors["phone"]?.message as string}
              </span>
            )}

            <button className={`${styles.button}`} type="submit">
              Enviar mensagem
            </button>
          </form>
        </div>
      </div>
      {toast && <PlatformsToast message={toast.message} type={toast.type} />}
    </div>
  );
};
