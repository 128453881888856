"use client";
import { useTheme } from "next-themes";
import { Logo, LogoWhite } from "../non-editable/misc";

type Props = {
  height?: number;
  width?: number;
};

export const ThemeLogo = (props: Props) => {
  const { resolvedTheme } = useTheme();
  let src;
  switch (resolvedTheme) {
    case "light":
      src = (
        <LogoWhite height={props.height ?? 80} width={props.width ?? 141} />
      );
      break;
    case "dark":
      src = <Logo height={props.height ?? 80} width={props.width ?? 141} />;
      break;
    default:
      src = <Logo height={props.height ?? 80} width={props.width ?? 141} />;
      break;
  }
  return src;
};
