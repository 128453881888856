export interface Settings {
  key: string;
  defaultValue: string;
  description: string;
}

// MANTER CONFORMIDADE COM TABELA FEATURE DO DB
export const SITE_FEATURES = {
  inventory: "inventory",
  offer: "offer",
  blog: "blog",
  dealership: "dealership",
  newVehicles: "newVehicles",
  instagram: "instagram",
};

export const PLATFORMS_CONSTS = {
  SYNC_BASE_ADDRESS: process.env.SYNC_BASE_ADDRESS,
  SYNC_SITE_GESTOR_ENDPOINT: process.env.SYNC_SITE_GESTOR_ENDPOINT,
  PLATFORMS_CONTENT_ENDPOINT: process.env.PLATFORMS_CONTENT_ENDPOINT,
};
