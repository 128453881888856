"use client";

import {
  extractRegionFromCurrentSlug,
  vehicleLinkBuilderByReference,
} from "~/utils";
import { SwHomeProps } from "../../props";
import { DirectionAwareHover } from "./card";
import styles from "./sw-home-variant-05.module.scss";
import Link from "next/link";

export const SwHomeVariant05 = (props: SwHomeProps): JSX.Element => {
  const vehicleList = props?.data?.vehicleList ?? [];

  const site = props.data.site;

  const region = extractRegionFromCurrentSlug(props.data.page?.currentSlug);

  return (
    <section className={`${styles.section} section-${props.id}`}>
      <div className={`${styles.container} container-${props.id}`}>
        <div className={`${styles.wrapper} wrapper-${props.id}`}>
          <h2>Veículos em destaque</h2>

          <div
            className={`flex flex-wrap gap-3 items-center justify-center images-container-${props.id} navigation-wrapper mt-6`}
          >
            {vehicleList
              .sort(() => 0.5 - Math.random())
              .slice(0, 4)
              .map((v, index) => {
                const vehicleUrl =
                  vehicleLinkBuilderByReference({
                    vehicle: v,
                    site: {
                      subdomain: site?.subdomain as string,
                      customDomain: site?.customDomain ?? "",
                      preferences: site?.preferences ?? [],
                    },
                    region,
                  }) ?? "/";
                return (
                  <Link href={vehicleUrl} prefetch>
                    <DirectionAwareHover
                      imageUrl={v.images.split(",")[0]}
                      key={v.version + index}
                      className="!rounded-lg md:max-w-[350px]"
                    >
                      <p className="font-bold text-xl">
                        {v.make} {v.model}
                      </p>
                      <p className="font-normal text-sm">{v.version}</p>
                    </DirectionAwareHover>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};
