"use client";

import { FloatingWhatsApp1Props } from "./props";
import styles from "./floating-whatsapp.module.scss";
import {
  handleDefaltWhatsappMessage,
  handleFormatStyleString,
  removeNonNumericAndSpaces,
} from "~/utils";
import dynamic from "next/dynamic";

const PlatformsIcon = dynamic(
  () =>
    import("~/components/non-editable/core").then((mod) => ({
      default: mod.PlatformsIcon,
    })) as any,
  { ssr: true }
) as any;

export const FloatingWhatsApp1 = (props: FloatingWhatsApp1Props) => (
  <>
    {props && props.styles && props.styles.value && (
      <style>
        {handleFormatStyleString(props.styles.value as string, props.id)}
      </style>
    )}

    {typeof props.vendorWhatsAppPhone1 != "undefined" && (
      <a
        href={`https://wa.me/55${removeNonNumericAndSpaces(
          String(
            props.vendorWhatsAppPhone1?.value ?? props.vendorWhatsAppPhone1
          )
        )}${handleDefaltWhatsappMessage()}`}
        target="_blank"
        rel="noreferrer"
        data-te-ripple-init
        data-te-ripple-color="light"
        aria-label="WhatsApp"
        className={`${styles.icon} icon-${props.id}`}
        style={{
          backgroundColor: "#25D366",
          position: "fixed",
          right: 10,
          bottom: 110,
          color: "#fffff",
          zIndex: 10000,
        }}
      >
        <PlatformsIcon icon={"mdi:whatsapp"} width={48} height={48} />
      </a>
    )}
  </>
);
