"use client";

import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import cookies from "js-cookie";
import { PlatformsIcon } from "../non-editable/core";

export type ThemeOptions = "light" | "dark";
interface ThemeToggleProps {
  defaultTheme: string;
}

export function ThemeToggle({ defaultTheme }: ThemeToggleProps): JSX.Element {
  const { systemTheme, theme, setTheme } = useTheme();
  const [currentTheme, setCurrentTheme] = useState<string>();

  useEffect(() => {
    if (cookies.get("theme") == undefined) {
      const themeDefault = defaultTheme != undefined ? defaultTheme : "light";
      setTheme(themeDefault);
      cookies.set("theme", themeDefault as ThemeOptions);
    }
  }, []);

  useEffect(() => {
    if (theme === "system") {
      setCurrentTheme(systemTheme);
    } else {
      setCurrentTheme(theme ?? "light");
    }
  }, [systemTheme, theme]);

  function handleToggleTheme(newTheme: ThemeOptions): void {
    if (typeof document != "undefined") {
      const html = document.getElementById("page-render");
      html?.classList.remove(String(currentTheme));
      html?.classList.add(String(newTheme));
    }
    setTheme(newTheme);
    setCurrentTheme(newTheme);
    cookies.set("theme", newTheme as ThemeOptions);
  }

  return (
    <button
      name="toggle-theme"
      aria-label="toggle-theme"
      className="hover:cursor-pointer flex justify-end"
    >
      <span id="theme-toggle" className="w-fit min-w-[24px]">
        <PlatformsIcon
          width={24}
          height={24}
          icon={
            currentTheme === "dark"
              ? "line-md:light-dark"
              : "line-md:light-dark-loop"
          }
          onClick={() =>
            handleToggleTheme(currentTheme === "dark" ? "light" : "dark")
          }
        />
      </span>
    </button>
  );
}
