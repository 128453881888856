import { DetailsOptionalsProps } from "./props";
import OptionalVariant01 from "./variants/01";
import OptionalVariant02 from "./variants/02";

export default function DetailsOptionalsVariants({
  props,
  variant,
}: DetailsOptionalsProps) {
  function renderOptionals() {
    switch (variant) {
      case 1:
        return <OptionalVariant01 {...props} />;
      case 2:
        return <OptionalVariant02 {...props} />;
      default:
        return <OptionalVariant01 {...props} />;
    }
  }

  return renderOptionals();
}
