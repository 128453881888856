import styles from "./platforms-button.module.scss";
import Link from "next/link";
import PlatformsIcon from "../platforms-icon";
import { FieldParams } from "~/constants";

export interface IPlatformsButtonProps {
  action: FieldParams;
  componentId?: string;
}

export default function PlatformsButton({
  action,
  componentId,
}: IPlatformsButtonProps): JSX.Element {
  return (
    <Link
      key={action.id}
      className={`${styles.button} platforms_button-${componentId}`}
      href={String(action.link)}
      target={action.link?.includes("https") ? "_blank" : "_self"}
    >
      {(action.value && action.value) || ""}
      {action.icon && (
        <PlatformsIcon
          icon={"lucide:arrow-right"}
          className={styles["button__icon"]}
        />
      )}
    </Link>
  );
}
