import { PlatformsIcon } from "../../core";
import { ShareButtonProps } from "./props";
import styles from "./share-button.module.scss";

export default function ShareButton({ path }: ShareButtonProps) {
  async function handleNavigatorShare() {
    await navigator.share({ url: path });
  }

  return (
    <>
      {typeof navigator != "undefined" &&
        typeof navigator.share == "function" && (
          <button
            className={`${styles.button} button`}
            onClick={handleNavigatorShare}
          >
            <PlatformsIcon
              icon="ic:round-share"
              className={`${styles["button__icon"]} button__icon`}
            />
          </button>
        )}
    </>
  );
}
