/* eslint-disable react/prop-types */
import Link from "next/link";
import { SwPremiumModule1Props } from "../props";

import styles from "./vehicle-premium.module.scss";
import { Vehicle } from "~/types";
import {
  handleFormatStyleString,
  handleNormalizeString,
  stringToSlug,
} from "~/utils";
import { PlatformsImage } from "~/components/non-editable/core";

export interface VehiclePremiumCardProps {
  vehicle: Vehicle;
  mainPath: string;
  props: SwPremiumModule1Props;
}

export const VehiclePremiumCard = ({
  vehicle,
  mainPath,
  props,
}: VehiclePremiumCardProps) => {
  const imageThumb = vehicle.images.includes(",")
    ? vehicle.images.split(",")[0]
    : vehicle.images;

  const slug = handleNormalizeString(
    [vehicle.make, vehicle.model, vehicle.modelYear.toString()]
      .join("-")
      .toString()
  );

  const integradorId = vehicle.id ? vehicle.id : vehicle.externalId;

  const formatedSlug = stringToSlug(slug);

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      <div className={`${styles.card} card-${props.id}`}>
        <Link href={`/${mainPath}/${formatedSlug}:${integradorId}`}>
          <div
            key={vehicle.externalId}
            className={`${styles.link} link-${props.id}`}
          >
            <div
              className={`${styles["armored-container"]} armored-container-${props.id}`}
            >
              {vehicle.isArmored && (
                <span className={`${styles.text} text-${props.id}`}>
                  BLINDADO
                </span>
              )}
              <PlatformsImage
                src={imageThumb}
                priority
                alt={vehicle.make + " " + vehicle.model}
                width={360}
                height={270}
                layout="responsive"
              />
            </div>
            <div
              className={`${styles["details-wrapper"]} details-wrapper-${props.id}`}
            >
              <p id="marca" className={`${styles.make} make-${props.id}`}>
                {vehicle.make.toUpperCase()}
              </p>
              <p className={`${styles.model} model-${props.id}`}>
                {vehicle.model.toUpperCase()} {vehicle.version.toUpperCase()}
              </p>
              <div className={`${styles.divider} divider-${props.id}`} />

              {props && props.options && props.options.showVehicleKm && (
                <p className={`${styles["small-text"]} small-text-${props.id}`}>
                  KM{" "}
                  {vehicle.kilometers?.toLocaleString("pt-BR", {
                    style: "decimal",
                  })}
                </p>
              )}

              {props && props.options && props.options.showVehiclePrice && (
                <p className={`${styles["small-text"]} small-text-${props.id}`}>
                  {vehicle.price.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              )}

              {props &&
                props.options &&
                Boolean(
                  props.options.showVehiclePrice?.value ??
                    props.options.showVehiclePrice
                ) === false &&
                String(
                  props.options.textAboveVehiclePrice?.value ??
                    props.options.textAboveVehiclePrice
                ) !== "" &&
                props.options.textAboveVehiclePrice !== undefined && (
                  <p
                    className={`${styles["small-text"]} small-text-${props.id}`}
                  >
                    {String(
                      props.options.textAboveVehiclePrice?.value ??
                        props.options.textAboveVehiclePrice
                    )}
                  </p>
                )}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
