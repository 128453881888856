import { useEffect, useState } from "react";

export function useCustomScroll() {
  const [stickyState, setStickytate] = useState<boolean>(true);
  const [lastScroll, setLastScroll] = useState<number>(0);

  const handleScroll = () => {
    const maxHeight = document.body.scrollHeight;
    const current = document.documentElement.scrollTop;

    if (lastScroll - 0.3 < window.scrollY || current > maxHeight * 0.8) {
      setStickytate(false);
    } else {
      setStickytate(true);
    }
    setLastScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return {
    stickyState,
  };
}
