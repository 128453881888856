"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";

export default function VehicleSold() {
  const pathname = usePathname();
  const carDetails = extractVehicleDetailsFromPathname(pathname as string);

  return (
    <Link
      href="/"
      className="relative block border border-gray-100 max-w-[380px] mx-auto my-10 shadow-3xl overflow-hidden"
      style={{ borderRadius: "5px" }}
    >
      <div
        style={{
          backgroundImage: `url("https://sync-front-end-resources.s3.sa-east-1.amazonaws.com/umami-analytics/vendido-1500x1200.jpg")`,
          width: "100%",
          height: "300px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />

      <div className="p-4 text-center">
        <strong className="text-xl dark:text-white text-gray-900 font-bold">
          {" "}
          <span>{carDetails?.make} </span>
          <span className="text-secondary-500">
            {carDetails?.model} - {carDetails?.modelYear}
          </span>
          <p className="text-secondary-400 text-sm">{carDetails?.version}</p>
        </strong>

        <span
          className="mt-4 block rounded-md border border-primary-700 !bg-secondary-500 px-5 py-3 text-sm font-bold uppercase tracking-widest text-white transition-colors hover:bg-white hover:text-black"
          style={{ borderRadius: "10px" }}
        >
          Ver estoque
        </span>
      </div>
    </Link>
  );
}
