import { Vehicle } from "../../../../types";

export enum VehicleGalleryVariant {
  Default = "default",
  Beehive = "beehive",
  Grid = "grid",
  ShowroomGrid = "showroomGrid",
  BrandNew = "brandNew",
}

export interface VehicleGalleryProps {
  vehicle: Vehicle;
  variant: VehicleGalleryVariant;
  internalImages?: string[];
}
