export interface CarDetails {
  brand?: string;
  model?: string;
  year?: string;
}

export interface VehicleDetails {
  type: InventoryType;
  region: string | null;
  make: string;
  model: string;
  version: string;
  minYear: number | null;
  maxYear: number | null;
  modelYear: number | null;
  filter: {
    category: string[];
    color: string[];
    fuelType: string[];
    transmission: string[];
    minKm: number[];
    maxKm: number[];
    minPrice: number[];
    maxPrice: number[];
    minYear: number;
    maxYear: number;
    optionals: string[];
    style: string[];
    sortBy: string[];
    page: string | null;
  } | null;
}

export enum InventoryType {
  Car = "carros",
  Motorcycle = "motos",
}
