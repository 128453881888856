import { motion, AnimationControls } from "framer-motion";
import { ArrowRight } from "lucide-react";
import Image from "next/image";

export const FloatHeader = ({
  controls,
  setOpen,
}: {
  controls: AnimationControls;
  setOpen: (state: boolean) => void;
}): JSX.Element => (
  <motion.div
    initial={{ y: -100, opacity: 0 }}
    animate={controls}
    exit={{ y: -100, opacity: 0 }}
    transition={{ duration: 0.3 }}
    className="fixed top-0 left-0 w-full bg-white shadow-lg p-2 z-40"
  >
    <div className="max-w-[1500px] mx-auto md:flex md:justify-between ">
      <div className="flex gap-4 items-center font-semibold text-lg md:text-xl mb-1 md:mb-0">
        <Image
          src="https://sync-platforms.s3.sa-east-1.amazonaws.com/logotipos/marcas/Logotipo-da-Hyundai-6-removebg-preview.png"
          alt="Logo Hyundai"
          width={80}
          height={20}
        />
        <p>Acelere com mais eficiência e segurança</p>
      </div>

      <button
        onClick={() => setOpen(true)}
        className="px-6 py-2 w-full bg-primary-800 text-white font-bold rounded-lg shadow-md hover:bg-primary-700 transition-colors flex items-center justify-center md:max-w-64"
      >
        Solicitar Cotação <ArrowRight className="ml-2" />
      </button>
    </div>
  </motion.div>
);
