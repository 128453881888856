import Link from "next/link";

import styles from "./details-variant-02.module.scss";
import { removeNonNumericAndSpaces } from "~/utils";
import VehicleGallery from "~/components/non-editable/misc/vehicle-gallery";
import { VehicleGalleryVariant } from "~/components/non-editable/misc/vehicle-gallery/props";
import { PlatformsIcon } from "~/components/non-editable/core";
import { WhatsappClientButton } from "./whatsapp-button";
import { ContactActionProps, DetailsVariantsProps } from "../../../props";
import DetailsHeaderVariants from "../../../details-header";
import DetailsInformationVariants from "../../../information";
import DetailsOptionalsVariants from "../../../optionals";
import DataLayerForm from "../../../datalayer-form";
import { ContactActionButtonType, ContactActionType } from "~/constants/props";

export function VehicleDetailsVariant2({
  props,
  vehicle,
  vehiclePrice,
}: DetailsVariantsProps): JSX.Element {
  return (
    <main className=" mt-6">
      {vehicle &&
        props.options != undefined &&
        props.options.galeryType != undefined && (
          <VehicleGallery
            variant={
              String(
                props.options.galeryType?.value ?? props.options.galeryType
              ) as VehicleGalleryVariant
            }
            vehicle={vehicle}
          />
        )}
      <div className={`${styles.container} container-${props.id}`}>
        <div
          className={`${styles.wrapper} wrapper-${props.id} ${
            Boolean(
              props?.options?.useCustomDetails?.value ??
                props?.options?.useCustomDetails
            ) == true
              ? `${styles["wrapper--customDetails"]} wrapper--customDetails-${props.id}`
              : ""
          }`}
        >
          <div
            className={`${styles["details-wrapper"]} details-wrapper-${
              props.id
            } ${
              Boolean(
                props?.options?.useCustomDetails?.value ??
                  props?.options?.useCustomDetails
              ) == true
                ? `${styles["details-wrapper--customDetails"]} details-wrapper--customDetails-${props.id}`
                : ""
            }`}
          >
            <DetailsHeaderVariants props={props} variant={2} />

            <DetailsInformationVariants props={props} variant={2} />

            <div className={`${styles.divider} divider-${props.id}`} />

            <DetailsOptionalsVariants props={props} variant={2} />
          </div>

          <div
            className={`${styles["form-wrapper"]} form-wrapper-${props.id} ${
              Boolean(
                props?.options?.useCustomDetails?.value ??
                  props?.options?.useCustomDetails
              ) == true
                ? `${styles["form-wrapper--customDetails"]} form-wrapper--customDetails-${props.id}`
                : ""
            }`}
          >
            {props?.options &&
              Boolean(
                props?.options.showDataLayerForm?.value ??
                  props?.options.showDataLayerForm
              ) && (
                <DataLayerForm
                  props={props}
                  variant={1}
                  vehicle={vehicle}
                  vehiclePrice={vehiclePrice}
                />
              )}

            {props.options &&
              !(
                props.options.showDataLayerForm?.value ??
                props.options.showDataLayerForm
              ) && (
                <div
                  className={`${styles["form-actions"]} form-actions-${props.id}`}
                >
                  {vehiclePrice &&
                    Boolean(
                      props.options.showVehiclePrice?.value ??
                        props.options.showVehiclePrice
                    ) == true && (
                      <p
                        className={`${styles["vehicle-price"]} vehicle-price-${props.id}`}
                      >
                        {vehiclePrice}
                      </p>
                    )}

                  <div
                    className={`${styles["send-message-container"]} send-message-container-${props.id}`}
                  >
                    <h3 className={styles.title}>
                      Envie uma mensagem ao vendedor
                    </h3>
                    <div
                      className={`${styles["actions-container"]} actions-container-${props.id}`}
                    >
                      {props.contactActions &&
                        // @ts-ignore
                        (props.contactActions?.items ?? props.contactActions)
                          .filter(
                            (action: ContactActionProps) =>
                              action.buttonType ==
                              ContactActionButtonType.default
                          )
                          .map((item: ContactActionProps, idx: number) => {
                            switch (item.actionType) {
                              case ContactActionType.whatsApp:
                                return (
                                  <WhatsappClientButton
                                    key={idx}
                                    componentId={props.id}
                                    label={item.label}
                                    //@ts-ignore
                                    link={item?.link.value ?? item?.link}
                                  />
                                );

                              case ContactActionType.cellphone:
                                return (
                                  <Link
                                    key={idx}
                                    href={`tel:+55${removeNonNumericAndSpaces(
                                      //@ts-ignore
                                      item.link?.value ?? item.link
                                    )}`}
                                    type="submit"
                                    className={`${styles["action-button"]} action-button-${props.id} ${styles["action-button--darken"]} action-button--darken-${props.id}`}
                                  >
                                    <div
                                      className={`${styles.icon} icon-${props.id}`}
                                    >
                                      <PlatformsIcon
                                        icon={"carbon:phone"}
                                        width={32}
                                        height={32}
                                      />
                                    </div>
                                    {item.label}
                                  </Link>
                                );
                            }
                          })}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </main>
  );
}
