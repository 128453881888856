import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";

const PlatformsToast = ({
  message,
  type,
}: {
  message: string;
  type: string;
}) => {
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100); // Adicione um pequeno atraso para começar a animação

    const timeout = setTimeout(() => {
      setVisible(false);
    }, 3000); // Defina o tempo que deseja que o toast fique visível (em milissegundos)

    const interval = setInterval(() => {
      setProgress((prevProgress) => Math.max(prevProgress - 1, 0));
    }, 30); // Ajuste o intervalo para controlar a velocidade da barra de progresso

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, []);

  // const toastClass = `toast ${
  //   type === "error" ? "bg-red-500" : "bg-green-400"
  // }`;

  const progressStyle = {
    width: `${progress}%`,
    transition: "width 0.03s linear",
  };

  const toastStyle = {
    transform: visible ? "translateY(0)" : "translateY(100%)",
    transition: "transform 0.5s ease-in-out",
  };

  return (
    <div
      style={{ zIndex: 1000 }}
      className={`fixed bottom-1 left-1/2 transform shadow-xl -translate-x-1/2 transition-opacity z-50 rounded overflow-hidden`}
    >
      <div
        style={toastStyle}
        className={`!bg-white pb-2 rounded shadow transition-opacity z-50 text-sm overflow-hidden`}
      >
        <div className="relative">
          <div className="progress-bar h-1 bg-gray-300">
            <div
              className={`progress absolute top-0 left-0 h-1 ${
                type === "error" ? "bg-red-500" : "bg-green-400"
              }`}
              style={progressStyle}
            />
          </div>
        </div>
        <div className="px-10 pt-3 flex gap-5 items-center justify-center !text-black">
          <Icon width={15} height={15} icon="emojione-v1:left-check-mark" />
          {message}
        </div>
      </div>
    </div>
  );
};

export default PlatformsToast;
