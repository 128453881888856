"use client";

import {
  extractRegionFromCurrentSlug,
  vehicleLinkBuilderByReference,
} from "~/utils";
import { SwHomeProps } from "../../props";
import { AnimatedSlider } from "./slider";
import styles from "./sw-home-variant-05.module.scss";

export const SwHomeVariant06 = (props: SwHomeProps): JSX.Element => {
  const vehicleList = props?.data?.vehicleList ?? [];

  const site = props.data.site;

  const region = extractRegionFromCurrentSlug(props.data.page?.currentSlug);

  const vehiclesSlider = vehicleList.slice(0, 15).map((v) => {
    const vehicleUrl =
      vehicleLinkBuilderByReference({
        vehicle: v,
        site: {
          subdomain: site?.subdomain as string,
          customDomain: site?.customDomain ?? "",
          preferences: site?.preferences ?? [],
        },
        region,
      }) ?? "/";

    return {
      title: `${v.make ?? ""} ${v.model ?? ""}`,
      version: v.version ?? "",
      description: v.generalDescription ?? "",
      src: v.images.split(",")[0],
      inventoryLink: vehicleUrl,
    };
  });

  return (
    <section className={`${styles.section} section-${props.id}`}>
      <div className={`${styles.container} container-${props.id}`}>
        <div className={`${styles.wrapper} wrapper-${props.id}`}>
          <AnimatedSlider vehicles={vehiclesSlider} autoplay />
        </div>
      </div>
    </section>
  );
};
