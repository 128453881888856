import { Vehicle } from "~/types";
import { InventoryProps } from "../../../../mock";
import styles from "./details-information-variant-01.module.scss";
import { options } from "../../../options";

export default function DetailsInformationVariant01(props: InventoryProps) {
  const vehicle =
    props && props.data && props.data.vehicle
      ? (props.data.vehicle as Vehicle)
      : null;

  return (
    <div className={`${styles.information} information-${props.id}`}>
      <dl>
        {options.map((i) => {
          const value = Array.isArray(i.value)
            ? `${vehicle?.[i.value[0] as keyof Vehicle]}/${
                vehicle?.[i.value[1] as keyof Vehicle]
              }`
            : vehicle?.[i.value as keyof Vehicle];

          if (vehicle?.[i.value as keyof Vehicle] === null) {
            return;
          }

          if (
            i.visibleKey &&
            //@ts-ignore
            props?.options?.[i.visibleKey as any] == false
          ) {
            return;
          }

          if (
            i.vehicleType != "all" &&
            vehicle?.type.toLowerCase() != i.vehicleType
          ) {
            return;
          }

          if (i.textTransform != null && typeof i.textTransform == "function") {
            return (
              <div
                className={`${styles["info-wrapper"]} info-wrapper-${props.id}`}
              >
                <dt>{i.label}</dt>
                <dd>
                  {i.textTransform(
                    vehicle?.[i.value as keyof Vehicle] as never
                  )}
                </dd>
              </div>
            );
          }

          return (
            <div
              className={`${styles["info-wrapper"]} info-wrapper-${props.id}`}
            >
              <dt>{i.label}</dt>
              {/* @ts-ignore */}
              <dd>{value}</dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
}
