/* eslint-disable react/prop-types */
"use client";

import { useEffect, useState } from "react";
import styles from "./filter-item.module.scss";
import { groupBy, handleNormalizeString, stringToSlug } from "~/utils";
import { Vehicle } from "~/types";
import { FilterItemProps } from "../../props";
import Disclosure from "../../../disclosure";
import { brandsMap } from "./brands";
import Image from "next/image";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms"; // Importa o método de extração de path params
import { usePathname } from "next/navigation"; // Importa o hook do Next.js

export default function FilterItemVariant2({
  itemKey,
  title,
  vehicles,
  queryKey,
  handleFilter,
  useListByKey = false,
  showOption,
}: FilterItemProps) {
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: boolean;
  }>({});
  const pathname = usePathname(); // Usa o hook do Next.js para pegar a URL

  useEffect(() => {
    // Extrair os valores da URL usando o método `extractVehicleDetailsFromPathname`
    const details = extractVehicleDetailsFromPathname(pathname as string); // Agora usamos `pathname` do Next.js
    const filters: { [key: string]: boolean } = {}; // Ajustado para boolean

    // Verifica se `details` não é nulo antes de acessar suas propriedades
    if (details) {
      // Verifica e atribui as propriedades se existirem
      if (details.make) filters["marca"] = true; // Agora utilizando boolean
      if (details.model) filters["modelo"] = true;

      if (details.filter?.category && details.filter?.category.length) {
        filters["carroceria"] = true;
      }

      if (details.filter?.minKm && details.filter?.minKm.length) {
        filters["minKm"] = true;
      }

      if (details.filter?.maxKm && details.filter?.maxKm.length) {
        filters["maxKm"] = true;
      }

      if (details.filter?.minPrice && details.filter?.minPrice.length) {
        filters["precoMin"] = true;
      }

      if (details.filter?.maxPrice && details.filter?.maxPrice.length) {
        filters["precoMax"] = true;
      }
    }

    setSelectedFilters(filters); // Atualiza selectedFilters com valores booleanos
  }, [pathname]); // O hook `usePathname` do Next.js é a dependência

  const optionsByKey = groupBy(vehicles, itemKey);
  const make = groupBy(vehicles, "make");

  function generateListByKey() {
    let listByKey;
    const filterItemByKey = Object.keys(make).filter(
      (makeKey) =>
        handleNormalizeString(makeKey) ===
        (selectedFilters["marca"] ? "true" : "false") // Comparando boolean com string
    );

    if (filterItemByKey.length > 0 && vehicles) {
      listByKey = groupBy(
        vehicles.filter((veic: Vehicle) =>
          filterItemByKey.some(
            (item: string) => handleNormalizeString(veic.make) === item
          )
        ),
        "category"
      );
    } else {
      listByKey = optionsByKey;
    }

    return listByKey;
  }
  const options = useListByKey ? generateListByKey() : optionsByKey;

  if (!showOption) {
    return null;
  }
  return (
    <Disclosure
      defaultOpen={Boolean(true)}
      title={title}
      childrenCount={Object.keys(options).length ?? 0}
    >
      {Object.keys(options)?.map((item: string, idx: number) => {
        const slugString = stringToSlug(item);
        const isChecked = selectedFilters[queryKey] === true;

        const icon = brandsMap.get(slugString);

        return (
          <div key={idx} className={styles.container}>
            <div className={`${styles["input-wrapper"]} input-wrapper`}>
              <input
                id={`filter-web-${item}-${idx}`}
                name={`${item}`}
                type="checkbox"
                checked={isChecked} // Define se o checkbox está marcado
                value={slugString}
                onChange={(e) => handleFilter(e, slugString, queryKey)}
                className={`${styles.input} input`}
              />
              <Image
                src={String(
                  icon ??
                    "https://www.svgrepo.com/show/309414/checkbox-checked.svg"
                )}
                alt={`icon-alt-${icon}`}
                width={100}
                height={100}
                className={`${styles.icon} icon`}
              />
            </div>
            <label htmlFor={`filter-web-${item}-${idx}`}>
              {item}
              <strong>
                {`(${(options![item as keyof Vehicle] as Vehicle[]).length})`}
              </strong>
            </label>
          </div>
        );
      })}
    </Disclosure>
  );
}
