/* eslint-disable react/prop-types */
import type { ImageProps } from "next/image";
import Head from "next/head";
import { Suspense, lazy } from "react";

const Image = lazy(() =>
  new Promise((resolve) => setTimeout(resolve, 200)).then(
    () => import("next/image")
  )
);

import styles from "./platforms-image.module.scss";

export interface PlatformsImage extends ImageProps {
  preload?: boolean;
}

export default function PlatformsImage(inputProps: PlatformsImage) {
  const src =
    inputProps.src ??
    "https://sync-platforms.s3.sa-east-1.amazonaws.com/logo-default.svg";
  const props = { ...inputProps, src: src };

  return (
    <>
      {props?.preload == true && (
        <Head>
          <link
            rel="preload"
            href={src as string}
            as="image"
            //@ts-ignore
            fetchpriority="high"
          />
        </Head>
      )}
      <Suspense fallback={<div className={styles.skeleton} />}>
        <Image
          {...props}
          alt={props.alt ?? `Image - ${src}`}
          style={{ ...props.style, userSelect: "none" }}
        />
      </Suspense>
    </>
  );
}
