import { FooterStoreAddressProps } from "./props";
import FooterStoreAddressVariant01 from "./variants/01";
import FooterStoreAddressVariant02 from "./variants/02";
import FooterStoreAddressVariant03 from "./variants/03";

export default function FooterStoreAddress({
  variant,
  props,
}: FooterStoreAddressProps) {
  function renderStoreAddress(): JSX.Element {
    switch (variant) {
      case "1":
        return <FooterStoreAddressVariant01 {...props} />;
      case "2":
        return <FooterStoreAddressVariant02 {...props} />;
      case "3":
        return <FooterStoreAddressVariant03 {...props} />;
      default:
        return <FooterStoreAddressVariant01 {...props} />;
    }
  }

  return renderStoreAddress();
}
