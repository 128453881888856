/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ContactActionProps,
  MenuProps,
  Hero1Props,
  SwHomeProps,
  Hero2Props,
  StickyNavbar1Props,
  HeaderProps,
} from "../components";
import { ComponentsBaseProps, Vehicle } from "../types";

import { About1Props } from "../components/editable/about/about1/props";
import { CtaPremium1Props } from "../components/editable/ctas/cta-premium1/props";
import { Cta1Props } from "../components/editable/ctas/cta1/props";
import { FloatingWhatsApp1Props } from "../components/editable/floating-whatsapp/floating-whatsapp1/props";
import { PrivacyPolicy1Props } from "../components/editable/privacy-policy/privacy-policy1/props";
import { SwMakes1Props } from "../components/editable/smart-widget/sw-makes/props";
import { StickFooter1Props } from "../components/editable/stick-footer/stick-footer1/props";
import { FilterProps } from "../components/editable/inventory/vehicles/filter-1/props";
import { VehicleDetails1Props } from "../components/editable/inventory/vehicles/details-1/props";
import { SwPremiumModule1Props } from "../components/editable/premium-modules/premium-module1/props";
import { FormContact1Props } from "../components/editable/forms/props";
import { Footer1Props } from "../components/editable/footers/footer1/props";
import { SeoProps } from "../components/editable/seo/seo-page/props";
import { OpeningHours1Props } from "~/components/editable/opening-hours/props";
import { NavigationComponentProps } from "~/components/editable/navigation/navigation1/props";
import { InstagramPostsProps } from "~/components/editable/smart-widget/sw-instagram/props";

export interface InventoryOptionsProps {
  showPrice: boolean;
  showMakeFilter?: boolean;
  textAboveVehiclePrice?: string;
  galeryType?: string;
  showVehiclePrice?: boolean;
  showVehicleKm?: boolean;
  increaseVehiclePriceInPercentage?: number;
  increaseVehiclePriceAmount?: number;
}

export enum ContactActionType {
  whatsApp = "whatsApp",
  cellphone = "cellphone",
}

export enum ContactActionButtonType {
  default = "default",
  pulsing = "pulsing",
}

export type ComponentNames =
  | "ContactAction"
  | "Menu"
  | "Hero1"
  | "SwHome"
  | "Hero2"
  | "StickyNavbar1"
  | "Header"
  | "About1"
  | "CtaPremium1"
  | "VehicleDetails1"
  | "Cta1"
  | "FloatingWhatsApp1"
  | "Footer1"
  | "PrivacyPolicy1"
  | "SwMakes1"
  | "Seo"
  | "SwPremiumModule1"
  | "StickFooter1"
  | "FormContact1"
  | "SwModels"
  | "SwCategory"
  | "Filter"
  | "InstagramPosts"
  | "OpeningHours1";

export type ComponentProps<T extends ComponentNames> = T extends keyof PropsMap
  ? PropsMap[T]
  : never;

type PropsMap = {
  ContactAction: ContactActionProps;
  Menu: MenuProps;
  Hero1: Hero1Props;
  SwHome: SwHomeProps;
  Hero2: Hero2Props;
  StickyNavbar1: StickyNavbar1Props;
  Header: HeaderProps;
  About1: About1Props;
  CtaPremium1: CtaPremium1Props;
  VehicleDetails1: VehicleDetails1Props;
  Cta1: Cta1Props;
  FloatingWhatsApp1: FloatingWhatsApp1Props;
  Footer1: Footer1Props;
  PrivacyPolicy1: PrivacyPolicy1Props;
  SwMakes1: SwMakes1Props;
  Seo: SeoProps;
  SwPremiumModule1: SwPremiumModule1Props;
  StickFooter1: StickFooter1Props;
  FormContact1: FormContact1Props;
  Filter: FilterProps;
  OpningHours1: OpeningHours1Props;
  Navigation: NavigationComponentProps;
  InstagramPosts: InstagramPostsProps;
};

export interface SelectProps {
  value: string | number | null;
  label: string;
}

export enum TypeFieldParams {
  Text = "text",
  Number = "number",
  Autocomplete = "autocomplete",
  Datepicker = "datepicker",
  Image = "image",
  Checkbox = "checkbox",
  Action = "action",
  Collection = "collection",
  Email = "email",
  Tel = "tel",
  Switch = "switch",
  Textarea = "textarea",
  Styles = "styles",
}

interface StylesProps {
  classes: string[];
  type: TypeFieldParams.Styles;
  value: string | null;
}

export type FieldParams = {
  label?: string;
  type?: TypeFieldParams;
  value?: string | number | boolean;
  advancedConfig?: boolean;
  selectOptions?: SelectProps[];
  id?: string;
  icon?: boolean;
  link?: string;
  items?: MenuProps[];
  hasButton?: boolean;
  options?: InventoryOptionsProps;
  contactActions?: ContactActionProps[];
  styles?: StylesProps;
  data?: {
    vehicle?: Vehicle;
    vehicleList?: Vehicle[];
    filteredList?: Vehicle[];
    shouldRenderDetails?: boolean;
  };
};
export type IComponentsMocks<
  T extends ComponentsBaseProps,
  K extends FieldParams
> = {
  [key in ComponentNames]?: IComponentProps<key>;
};

export type IComponentProps<Key extends ComponentNames> = Partial<
  ComponentProps<Key>
>;

export type IComponentsValuesUnionProps<T, K, V> =
  | T
  | K
  | V
  | number
  | string
  | boolean;
