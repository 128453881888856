"use client";

import { usePathname } from "next/navigation";
import { RadioItemProps } from "./props";
import {
  maxKmOptions,
  maxPriceOptions,
  minKmOptions,
  minPriceOptions,
} from "./options";

import Disclosure from "../disclosure";

import styles from "./radio-item.module.scss";
import { FilterDefaultQueryKeys } from "~/index";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";

export default function RadioItem({
  title,
  queryKey,
  handleFilter,
  showOption,
  isKm,
}: RadioItemProps) {
  const pathname = usePathname();

  const detailsFromPathname = extractVehicleDetailsFromPathname(pathname ?? "");

  const defaultCheckedValue = (() => {
    switch (queryKey) {
      case FilterDefaultQueryKeys.MinKm:
        return detailsFromPathname?.filter?.minKm?.[0];
      case FilterDefaultQueryKeys.MaxKm:
        return detailsFromPathname?.filter?.maxKm?.[0];
      case FilterDefaultQueryKeys.MinPrice:
        return detailsFromPathname?.filter?.minPrice?.[0];
      case FilterDefaultQueryKeys.MaxPrice:
        return detailsFromPathname?.filter?.maxPrice?.[0];
      default:
        return null;
    }
  })();

  if (!showOption) {
    return null;
  }

  function getOptions() {
    switch (queryKey) {
      case FilterDefaultQueryKeys.MinKm:
        return minKmOptions;
      case FilterDefaultQueryKeys.MaxKm:
        return maxKmOptions;
      case FilterDefaultQueryKeys.MinPrice:
        return minPriceOptions;
      case FilterDefaultQueryKeys.MaxPrice:
        return maxPriceOptions;
      default:
        return [];
    }
  }

  const options = getOptions();

  return (
    <Disclosure
      defaultOpen={true}
      childrenCount={options?.length ?? 0}
      title={title}
    >
      <div className={styles.container}>
        {options?.map((item) => (
          <div className={styles.wrapper} key={`input-idx-${item}`}>
            <input
              type="radio"
              name={`hs-default-radio-${queryKey}`}
              id={`hs-default-radio-${queryKey}-${item}`}
              onChange={(e) => handleFilter(e, e.target.value, queryKey)}
              value={item * 1000}
              defaultChecked={defaultCheckedValue === item * 1000}
            />
            <label
              htmlFor={`hs-default-radio-${queryKey}-${item}`}
              className="text-sm text-primary-800 ms-2 dark:text-white"
            >
              {isKm
                ? Intl.NumberFormat("pt-BR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(item * 1000)
                : Intl.NumberFormat("pt-BR", {
                    currency: "BRL",
                    style: "currency",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(item * 1000)}
            </label>
          </div>
        ))}
      </div>
    </Disclosure>
  );
}
