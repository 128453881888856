import PlatformsButton from "~/components/non-editable/core/platforms-button";
import { Hero1Props } from "../../props";
import styles from "./hero-variant-02.module.scss";

export default function HeroVariant02(props: Hero1Props): JSX.Element {
  return (
    <div className={`${styles.container} container-${props.id}`}>
      <div
        className={`${styles["container__wrapper"]} container__wrapper-${props.id}`}
      >
        {(props?.heading?.value ?? props?.heading) && (
          <h1
            className={`${styles["container__wrapper__title"]} container__wrapper__title-${props.id}`}
          >
            {String(props?.heading?.value ?? props?.heading)}
          </h1>
        )}
        {(props?.description?.value ?? props?.description) && (
          <h3
            className={`${styles["container__wrapper__description"]} container__wrapper__description-${props.id}`}
          >
            {String(props?.description?.value ?? props?.description)}
          </h3>
        )}
        {props?.action && (
          <div
            className={`${styles["container__wrapper__row"]} container__wrapper__row-${props.id}`}
          >
            {/* @ts-ignore */}
            <PlatformsButton action={props.action} componentId={props.id} />
          </div>
        )}
      </div>
      {props.image && (
        <div
          className={`${styles["container__wrapper__image__container"]} container__wrapper__image__container-${props.id}`}
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
              typeof props.image != "undefined"
                ? String(props?.image?.value ?? props?.image).replace(
                    "blob:",
                    ""
                  )
                : ""
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              className={`${styles["container__wrapper__image__item"]} container__wrapper__image__item-${props.id}`}
              style={{ gridArea: `card${index + 1}` }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
