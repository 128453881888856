"use client";

import { PlatformsIcon } from "~/components/non-editable/core";
import styles from "./whatsapp-action.module.scss";
import { WhatsappActionProps } from "./props";
import { motion } from "framer-motion";
import {
  maskPhone,
  removeNonNumericAndSpaces,
  replaceNewlinesWithSpaces,
} from "~/utils";
import { DevelopedBy } from "~/components/editable/developed-by";
import { useForm } from "react-hook-form";
import useDomain from "~/hooks/useSubdomain";

export const WhatsappAction = ({
  ctaProps,
  openWhatsappForm,
  setOpenWhatsappForm,
}: WhatsappActionProps) => {
  const { domain } = useDomain();
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const isVehicleLead = false;

  const whatsappPhone =
    typeof ctaProps.whatsappVendorPhone === "string"
      ? ctaProps.whatsappVendorPhone
      : ctaProps.whatsappVendorPhone?.value;

  const workflowId =
    typeof ctaProps.workflowId === "string"
      ? ctaProps.workflowId
      : ctaProps.workflowId.value;

  const handleOpenWhatsappForm = () => {
    setOpenWhatsappForm(!openWhatsappForm);
    reset();
  };

  const onSubmit = async (data: any): Promise<void | boolean> => {
    const encodedMessage = encodeURIComponent(
      (data.message as string) ?? ("" as string)
    );
    if (whatsappPhone) {
      window.open(
        `https://wa.me/55${removeNonNumericAndSpaces(
          String(whatsappPhone)
        )}?text=${encodedMessage}`,
        "_blank"
      );
    }
    let originUrl;

    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;

      originUrl = `${protocol}//${hostname}${port ? ":" + port : ""}${
        window.location.pathname
      }`;
    }
    const newLead = {
      name: data.name,
      email: data.email,
      cellphone: data.cellphone,
      message: replaceNewlinesWithSpaces(data.message) ?? "",
      originUrl: originUrl,
      workflowId: workflowId as string,
      sendLeadTo:
        typeof ctaProps.emailToSendLead === "string"
          ? ctaProps.emailToSendLead
          : (ctaProps.emailToSendLead.value as string) ?? ("" as string),
      domain: domain,
      inventoryCode: null,
      metadata: {},
      isVehicleLead,
    };

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newLead),
        }
      );
    } catch (error) {
      console.error(error);
    }

    reset();
    setOpenWhatsappForm(false);

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(isSubmitting);
      }, 2000);
    });
  };

  return (
    <>
      {typeof ctaProps.whatsappVendorPhone != "undefined" && (
        <>
          <motion.div
            initial={{ opacity: 1 }}
            animate={{
              opacity: openWhatsappForm ? 0 : 1,
            }}
            transition={{ duration: openWhatsappForm ? 0.2 : 0.5 }}
            style={{ width: "100%" }}
          >
            <motion.button
              type="button"
              onClick={handleOpenWhatsappForm}
              initial={{
                opacity: 1,
                height: "",
              }}
              animate={{
                opacity: openWhatsappForm ? 0 : 1,
                height: openWhatsappForm ? "15px" : "",
                overflow: openWhatsappForm ? "hidden" : "visible",
              }}
              transition={{ duration: !openWhatsappForm ? 0.2 : 0.5 }}
              className={`${styles.button} button-${ctaProps.id} gtm_event_whatsapp`}
              style={{ width: "100%" }}
              data-umami-event="Falar no Whatsapp"
            >
              <PlatformsIcon
                width={25}
                height={25}
                className={`${styles.icon} icon-${ctaProps.id}`}
                icon="ic:round-whatsapp"
              />
              Whatsapp
            </motion.button>
          </motion.div>
          <motion.div
            className={`${styles.whatsapp__form__container}`}
            initial={{ height: "0px", overflow: "hidden" }}
            animate={{
              height: openWhatsappForm ? "100%" : "0px",
              overflow: openWhatsappForm ? "visible" : "hidden",
              display: openWhatsappForm ? "" : "none",
            }}
            transition={{ duration: 0.5 }}
            data-umami-event="Ligar Agora"
          >
            <div className={`${styles.header__container}`}>
              <button type="button" onClick={handleOpenWhatsappForm}>
                <PlatformsIcon
                  width={20}
                  height={20}
                  className={`${styles.icon} icon-${ctaProps.id}`}
                  icon="ic:round-close"
                />
              </button>
              <h4>Fale comigo</h4>
            </div>
            <motion.form
              initial={{ minHeight: "100%" }}
              animate={{ minHeight: openWhatsappForm ? "90vh" : "100%" }}
              transition={{ duration: 0.5 }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={`${styles.wrapper}`}>
                <input
                  required
                  type="text"
                  placeholder="Nome"
                  {...register("name")}
                />
                <input
                  required
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                />
                <input
                  required
                  type="tel"
                  placeholder="Telefone"
                  maxLength={15}
                  {...register("cellphone", {
                    onChange: (e) => {
                      const maskedValue = maskPhone(e.target.value);
                      setValue("cellphone", maskedValue);
                    },
                    maxLength: 15,
                  })}
                />
                <textarea
                  rows={6}
                  placeholder="Digite sua mensagem"
                  {...register("message")}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                    gap: "15px",
                  }}
                >
                  <input id="check" defaultChecked={true} type="checkbox" />
                  <label htmlFor="check">
                    Ao enviar, você concordo com os termos de uso.
                  </label>
                </div>
              </div>

              <div className={`${styles.footer__container}`}>
                <button disabled={isSubmitting} type="submit">
                  {!isSubmitting ? (
                    <>
                      <PlatformsIcon
                        width={25}
                        height={25}
                        className={`${styles.icon} icon-${ctaProps.id}`}
                        icon="ic:round-whatsapp"
                      />
                      Falar no Whatsapp
                    </>
                  ) : (
                    <>
                      <PlatformsIcon
                        width={25}
                        height={25}
                        className={`${styles.icon__loading} icon-${ctaProps.id}`}
                        icon={"mingcute:loading-3-fill"}
                      />
                      Aguarde um momento...
                    </>
                  )}
                </button>

                <DevelopedBy />
              </div>
            </motion.form>
          </motion.div>
        </>
      )}
    </>
  );
};
