import Head from "next/head";
import { Vehicle } from "../../../../../../types";
import { toJsonLd } from "../../../../../../utils/structuredData";
import { InventoryProps } from "../../mock";
import ClientPagination from "../client-pagination";

import styles from "../vehile-inventory.module.scss";
import VehicleCardSkeleton from "../../card/skeleton";
import ClientFilterSkeleton from "../client-filter/skeleton";

export function Filter1Skeleton(props: InventoryProps) {
  const vehicles = props.data?.filteredList
    ? props.data?.filteredList
    : props.data?.vehicleList;
  const itemListElement = vehicles?.map((vehicle: Vehicle) => {
    const { make, model } = vehicle;

    const offer = {
      "@type": "Offer",
      itemOffered: {
        "@type": "CarDealership",
        name: `${make} ${model}`,
      },
    };

    return offer;
  });

  const object = {
    "@context": "https://schema.org",
    "@type": "CarDealership",
    name: "Estoque",
    description:
      "Encontre a melhor seleção de veículos para venda com opções de filtragem personalizadas. Explore nossa ampla variedade de carros disponíveis e refine sua busca por marca, modelo, preço e muito mais. Encontre o veículo dos seus sonhos aqui!",
    itemListElement: itemListElement ?? [], // Adiciona a lista de objetos criados aqui
  };

  if (props?.data?.shouldRenderDetail ?? props?.shouldRenderDetail) {
    return <></>;
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={toJsonLd("CarDealership", { ...object })}
        />
        <title>"Estoque"</title>
        <meta
          name="description"
          content="Encontre a melhor seleção de veículos para venda com opções de filtragem personalizadas. Explore nossa ampla variedade de carros disponíveis e refine sua busca por marca, modelo, preço e muito mais. Encontre o veículo dos seus sonhos aqui!"
        />
        <meta
          property="og:title"
          content={
            vehicles?.length === 1
              ? `ESTOQUE | ${vehicles[0].make} - ${vehicles[0].model}`
              : `Estoque de veiculos`
          }
        />
        <meta
          property="og:description"
          content="Encontre a melhor seleção de veículos para venda com opções de filtragem personalizadas. Explore nossa ampla variedade de carros disponíveis e refine sua busca por marca, modelo, preço e muito mais. Encontre o veículo dos seus sonhos aqui!"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="follow, index" />
        <meta property="og:type" content="website" />
      </Head>

      <ClientFilterSkeleton>
        <main
          className={`${styles.main} ${
            Boolean(
              props.options?.showMakeFilter?.value ??
                props.options?.showMakeFilter
            ) == false &&
            Boolean(
              props.options?.showModelFilter?.value ??
                props.options?.showModelFilter
            ) == false
              ? styles["main--4col"]
              : ""
          }`}
        >
          <div className={styles.wrapper}>
            <div className={styles["vehicle-container"]}>
              {Array.from({ length: 9 })?.map((_, idx: number) => (
                <VehicleCardSkeleton key={`vehicle-card-skeleton--${idx}`} />
              ))}
            </div>
            <ClientPagination props={props} />
          </div>
        </main>
      </ClientFilterSkeleton>
    </>
  );
}
