"use client";

import Link from "next/link";
import { Footer1Props } from "../../props";
import styles from "./footer-variant-03.module.scss";
import FooterStoreAddress from "../../store-address";
import WorkingHoursVariants from "../../working-hours";
import ManuVariants from "../../menu-variants";
import PlatformsLogo from "~/components/editable/logo";
import { phoneFormatter } from "~/utils";
import { PlatformsIcon } from "~/components/non-editable/core";
import FooterSocialIcons from "../../socials";

export default function FooterVariant03(props: Footer1Props): JSX.Element {
  function handleScrollTop() {
    if (typeof window != "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  const phoneStore1 = props?.phoneStore1?.value ?? props?.phoneStore1;
  const showPhone = props.showPhone?.value ?? true;

  return (
    <footer className={`${styles.container} container-${props.id}`}>
      <div
        className={`${styles["container__card"]} container__card-${props.id}`}
      >
        <div
          className={`${styles["container__card__row"]} container__card__row-${props.id}`}
        >
          <div
            className={`${styles["container__card__column"]} container__card__column-${props.id}`}
          >
            <PlatformsLogo
              id={props.id}
              logo={props.logo}
              logoDark={props.logoDark}
              logoWidth={props.logoWidth}
              logoHeight={props.logoHeight}
            />
            <ManuVariants props={props} variant="1" />
          </div>
          <div
            className={`${styles["container__card__column"]} container__card__column-${props.id}`}
          >
            {phoneStore1 && showPhone && (
              <div
                className={`${styles["container__card__phone"]} container__card__phone-${props.id}`}
              >
                <div
                  className={`${styles["container__card__phone__label"]} container__card__phone__label-${props.id}`}
                >
                  Entre em contato
                </div>
                <a
                  href={`tel:${props?.phoneStore1?.value}`}
                  className={`${styles["container__card__phone__number"]} container__card__phone__number-${props.id}`}
                >
                  {phoneFormatter(
                    props?.phoneStore1?.value
                      ? (props?.phoneStore1?.value as string)
                      : ""
                  )}
                </a>
                <FooterSocialIcons variant="1" props={props} />
              </div>
            )}
            <FooterStoreAddress props={props} variant="1" />
            <WorkingHoursVariants props={props} variant="1" />
          </div>
        </div>
        <button
          className={`${styles["container__card__button"]} container__card__button-${props.id}`}
        >
          <PlatformsIcon
            className={`${styles["container__card__button__icon"]} container__card__button__icon-${props.id}`}
            icon="iconamoon:arrow-up-1-light"
            onClick={handleScrollTop}
          />
        </button>
        <Link href="/sobre-nos" style={{ width: "100%" }}>
          <div className={`${styles["inner-card"]} inner-card-${props.id}`}>
            Explore nosso sucesso
            <PlatformsIcon
              className={`${styles["inner-card__icon"]} inner-card__icon-${props.id}`}
              icon="majesticons:arrow-right-line"
            />
          </div>
        </Link>
        <Link
          target="_blank"
          href={`https://sync.com.br`}
          className={`${styles.text} text-${props.id}`}
        >
          Desenvolvido por <p className="underline">sync</p>
        </Link>
      </div>
    </footer>
  );
}
