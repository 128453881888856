"use client";

import { useEffect, useState } from "react";
import { usePathname, useRouter } from "next/navigation";

import styles from "./client.pagination.module.scss";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";
import { Vehicle } from "~/types";
import { WebsitePreference, WebsitePreferenceType } from "../../mock";
import slugify from "slugify";

const ClientPagination = ({ props }: any) => {
  const { push } = useRouter();
  const pathname = usePathname();

  const vehicles: Vehicle[] = props.data.vehicleList ?? [];

  const hasCar = vehicles.some((v) => v.type === "Car");
  const hasMotorcycle = vehicles.some((v) => v.type === "Motocycle");

  const inventorySlugPreference =
    ((props?.data?.site?.preferences as WebsitePreference[]) ?? [])?.find(
      (preference) =>
        preference?.type === WebsitePreferenceType.inventoryPathName
    )?.value ?? "estoque";

  const localSeoPreference =
    ((props?.data?.site?.preferences as WebsitePreference[]) ?? [])
      ?.find(
        (preference) => preference?.type === WebsitePreferenceType.localSeoSlug
      )
      ?.value?.split(",")[0] ?? "brasil";

  const handleInventoryTypes = () => {
    if (hasCar && hasMotorcycle) {
      return `/carros-e-motos`;
    } else if (hasCar) {
      return `/carros`;
    } else if (hasMotorcycle) {
      return `/motos`;
    }
  };

  const [page, setPage] = useState<number | undefined>(1);

  function updatePageInPath(pathname: string, page: number) {
    const pgRegex = /([&\/])pg=\d+/;
    const hasOtherFilters =
      pathname.includes("=") && !pathname.includes("/pg=");

    if (page === 1) {
      return pathname.replace(pgRegex, "").replace(/[&\/]$/, "");
    }

    if (pgRegex.test(pathname)) {
      return pathname.replace(pgRegex, `$1pg=${page}`);
    } else {
      if (pathname === "/") {
        return `/${slugify(inventorySlugPreference, {
          lower: true,
        })}/${handleInventoryTypes()}/${slugify(localSeoPreference, {
          lower: true,
        })}/pg=${page}`;
      }

      if (hasOtherFilters) {
        return `${pathname}&pg=${page}`;
      } else {
        return `${pathname}/pg=${page}`;
      }
    }
  }

  useEffect(() => {
    const details = extractVehicleDetailsFromPathname(pathname as string);

    if (details && details.filter && details.filter.page) {
      setPage(Number(details.filter.page));
    } else {
      setPage(1);
    }
  }, [pathname]);

  const pagination =
    Number(
      typeof props?.options?.quantityPerPage === "number"
        ? props?.options?.quantityPerPage
        : props?.options?.quantityPerPage.value
    ) ?? 10;

  function handleNextPage() {
    const newPage = (page ?? 1) + 1;
    setPage(newPage);

    const updatedPath = updatePageInPath(pathname ?? "", newPage);
    push(updatedPath, { scroll: true });
  }

  function handlePreviousPage() {
    const newPage = (page ?? 1) - 1;
    setPage(newPage);

    const updatedPath = updatePageInPath(pathname ?? "", newPage);
    push(updatedPath, { scroll: true });
  }

  if (!props.options) {
    return <></>;
  }

  const showPrev = +page! > 1;
  const showNext =
    props?.data?.filteredList?.length > 0 &&
    (props?.data?.filteredList?.length === +pagination - 1 ||
      props?.data?.filteredList?.length === +pagination);

  return (
    <>
      {Boolean(
        props.options.showPagination?.value ?? props.options.showPagination
      ) && (
        <nav className={styles.container} aria-label="Pagination">
          <div
            className={`${styles.wrapper} ${
              showPrev && showNext ? styles["space-between"] : ""
            }`}
          >
            {showPrev && (
              <a
                href="javascript:void(0)"
                className={`${styles.button} ${
                  !showNext ? styles["button-prev"] : ""
                }`}
                onClick={handlePreviousPage}
              >
                Página Anterior
              </a>
            )}
            {showNext && (
              <a
                href="javascript:void(0)"
                className={`${styles.button} ${
                  !showPrev ? styles["button-next"] : ""
                }`}
                onClick={handleNextPage}
              >
                Próxima Página
              </a>
            )}
          </div>
        </nav>
      )}
    </>
  );
};

export default ClientPagination;
