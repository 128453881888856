"use client";

import { Suspense, useEffect, useMemo, useState } from "react";
//@ts-ignore
import { PanInfo } from "framer-motion";
import { BaseCarousselProps } from "./props";
import dynamic from "next/dynamic";

import styles from "./caroussel.module.scss";
import { PlatformsIcon } from "../../core";

const MotionDiv = dynamic(
  () => import("framer-motion").then((mod) => mod.motion.div),
  { ssr: false }
);
const PanInfo = dynamic(
  //@ts-ignore
  () => import("framer-motion").then((mod) => mod.PanInfo),
  { ssr: false }
);

export default function Caroussel({
  itens,
  itemsPerView,
  useAutoScroll,
}: BaseCarousselProps): JSX.Element {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [quantityPerView, setQuantityPerView] = useState<number>(1);
  const [isClient, setIsClient] = useState<boolean>(false);

  const use4cols = itemsPerView == "4";

  const startAutoSlide = () => {
    const id = setInterval(slideRight, 4000);
    setIntervalId(id);
  };

  const stopAutoSlide = () => {
    if (intervalId) {
      //@ts-ignore
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  useEffect(() => {
    setIsClient(true);
    if (useAutoScroll) {
      startAutoSlide();

      return () => {
        stopAutoSlide();
      };
    }
  }, [useAutoScroll]);

  const handleMouseEnter = () => {
    stopAutoSlide();
  };

  const handleMouseLeave = () => {
    startAutoSlide();
  };

  const quantity =
    typeof window != "undefined"
      ? window?.innerWidth >= 1365
        ? 3
        : window?.innerWidth >= 577
        ? 2
        : 1
      : 3;

  //@ts-ignore
  const transformedItems = Array.from(
    {
      length: Math.max(Math.ceil(itens.length / quantity), 0),
    },
    (_, pageIndex) => {
      const startIndex = pageIndex * quantity;
      const endIndex = Math.min(startIndex + quantity, itens.length);
      const itemsInPage = itens.slice(startIndex, endIndex);

      if (itemsInPage.length > 0) {
        return (
          <section
            suppressHydrationWarning
            className={`${styles["child-wrapper"]} ${
              quantityPerView == 4 ? styles["child-wrapper--4col"] : ""
            } `}
            key={`group-${pageIndex}`}
          >
            {itemsInPage.map((groupedItem, groupIdx) => (
              <div
                key={`item-${startIndex + groupIdx}`}
                className={styles.child}
              >
                {groupedItem}
              </div>
            ))}
          </section>
        );
      }

      return null;
    }
  ).filter(Boolean);

  const slideRight = (): void => {
    setCurrentIndex(
      (prev: number) =>
        (prev + 1) %
        Number((transformedItems as (JSX.Element | null)[])?.length ?? 0)
    );
  };

  const slideLeft = (): void => {
    setCurrentIndex(
      (prev: number) =>
        (prev - 1 + (transformedItems as (JSX.Element | null)[])?.length) %
        (transformedItems as (JSX.Element | null)[])?.length
    );
  };

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ): void => {
    const offset = info.offset.x;
    const velocity = info.velocity.x;

    if (offset > 100 || velocity > 500) {
      slideLeft();
    } else if (offset < -100 || velocity < -500) {
      slideRight();
    }
  };

  useMemo(() => {
    if (typeof window != "undefined") {
      const updateQuantityPerView = () => {
        if (typeof window != "undefined" && window) {
          switch (true) {
            case window.innerWidth < 568:
              return 1;
            case window.innerWidth < 768:
              return 2;
            case window.innerWidth < 1100:
              return 3;
            case window.innerWidth > 1101:
              return Number(itemsPerView);
            default:
              return 1;
          }
        } else {
          return 1;
        }
      };

      setQuantityPerView(updateQuantityPerView());

      const handleResize = () => {
        setQuantityPerView(updateQuantityPerView());
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [itemsPerView]);

  function renderContent() {
    switch (isClient) {
      case true:
        return (
          //@ts-ignore
          <MotionDiv
            className={`${styles.slider} ${
              use4cols ? styles["slider--4col"] : ""
            }`}
            animate={{ x: `${-currentIndex * 100}%` }}
            transition={{ type: "tween", duration: 0.3 }}
            drag="x"
            suppressHydrationWarning
            onDragEnd={handleDragEnd}
          >
            {(transformedItems as (JSX.Element | null)[]) ??
              []?.map((item) => item)}
          </MotionDiv>
        );
      case false:
        return (
          <div
            className={`${styles.slider} ${
              use4cols ? styles["slider--4col"] : ""
            }`}
          >
            {(transformedItems as (JSX.Element | null)[]) ??
              []?.map((item) => item)}
          </div>
        );
    }
  }

  return (
    <Suspense fallback={<div>image skeleton</div>}>
      <div
        className={styles["wrapper-item"]}
        suppressHydrationWarning
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={styles.container} suppressHydrationWarning>
          {renderContent()}
        </div>
        <div
          className={`${styles.button} ${styles["button--left"]}`}
          onClick={slideLeft}
        >
          <PlatformsIcon className={styles.icon} icon="ic:round-chevron-left" />
        </div>
        <div
          className={`${styles.button} ${styles["button--right"]}`}
          onClick={slideRight}
        >
          <PlatformsIcon
            className={styles.icon}
            icon="ic:round-chevron-right"
          />
        </div>
      </div>
    </Suspense>
  );
}
