import styles from "../card.module.scss";
import skeleton from "./skeleton.module.scss";

export default function VehicleCardSkeleton() {
  return (
    <div className={`${styles.card}`}>
      <div className="relative">
        <div className={skeleton["image-skeleton"]} />
      </div>
      <div className={styles.content}>
        <div className={skeleton["title-skeleton"]} />
        <div className={styles["text-wrapper"]}>
          <div className={skeleton["description-skeleton"]} />
          <div className={skeleton["price-skeleton"]} />
        </div>
      </div>
    </div>
  );
}
