import { useMemo } from "react";
import { resetSyncCdnImageSize } from "~/utils";

export function useVehicleImages({
  images,
  internalImages,
}: {
  images: string | undefined;
  internalImages?: string | undefined;
}) {
  return useMemo(() => {
    const imageArray = images?.split(",") || [];
    const internalImagesArray = internalImages?.split(",") || [];
    const vehicleImagesThumb = resetSyncCdnImageSize(imageArray, "650", "500");
    const internalImagesThumb = resetSyncCdnImageSize(
      internalImagesArray,
      "650",
      "500"
    );
    const vehicleImagesLarge = resetSyncCdnImageSize(
      imageArray,
      "1440",
      "1080"
    );
    const internalImagesLarge = resetSyncCdnImageSize(
      internalImagesArray,
      "1440",
      "1080"
    );

    return {
      vehicleImagesThumb: vehicleImagesThumb,
      vehicleImagesLarge: vehicleImagesLarge,
      internalImagesThumb: internalImagesThumb,
      internalImagesLarge: internalImagesLarge,
    };
  }, [images]);
}
