import { PlatformsIcon } from "~/components/non-editable/core";
import { Footer1Props } from "../../../props";
import styles from "./social-icons-03.module.scss";

export default function SocialIconsVariant3(props: Footer1Props) {
  return (
    <div className={styles.container}>
      {(props?.linkFacebook?.value ?? props?.linkFacebook) && (
        <a
          href={String(props?.linkFacebook?.value ?? props?.linkFacebook)}
          target="_blank"
          className={styles["container__button"]}
          rel="noreferrer"
        >
          <PlatformsIcon width={30} height={30} icon="devicon:facebook" />
        </a>
      )}
      {(props?.linkInstagram?.value ?? props?.linkInstagram) && (
        <a
          href={String(props?.linkInstagram?.value ?? props?.linkInstagram)}
          target="_blank"
          className={styles["container__button"]}
          rel="noreferrer"
        >
          <PlatformsIcon width={30} height={30} icon="skill-icons:instagram" />
        </a>
      )}
    </div>
  );
}
