import { useState } from "react";
import styles from "./disclosure.module.scss";
import { DisclosureProps } from "./props";
import { PlatformsIcon } from "~/components/non-editable/core";

export default function Disclosure({
  children,
  title,
  defaultOpen,
  childrenCount,
}: DisclosureProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  function handleCollapse(): void {
    setOpen((prev) => !prev);
  }

  return (
    <div className={styles.container}>
      <div className={styles["button-container"]} onClick={handleCollapse}>
        <div className={styles["button-row"]}>
          <p>{title}</p>
          <div
            className={`${styles["button-icon"]} ${
              open ? styles["button-icon--open"] : ""
            }`}
          >
            <PlatformsIcon icon="tabler:chevron-down" height={24} width={24} />
          </div>
        </div>
      </div>
      <div
        style={{ height: "auto" }}
        className={`${styles.disclosure} disclosure ${
          !open ? styles["disclosure--closed"] : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}
