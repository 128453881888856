import styles from "./developed-by.module.scss";

export const DevelopedBy = ({
  title = "sync",
  href = "https://www.sync.com.br",
  paddingBottom = "60px",
  marginTop = "0px",
}: {
  title?: string;
  href?: string;
  paddingBottom?: string;
  marginTop?: string;
}) => (
  <div className={`${styles.developed__by}`}>
    <span style={{ paddingBottom: paddingBottom, marginTop: marginTop }}>
      Desenvolvido por{" "}
      <a target="_blank" href={href} rel="noreferrer">
        {title}
      </a>
    </span>
  </div>
);
