export const compositeBrands = [
  "Mercedes-Benz",
  "Land Rover",
  "Rolls-Royce",
  "Kia Motors",
  "Cross Lander",
  "Cbt Jipe",
  "Aston Martin",
  "Asia Motors",
  "Am Gen",
  "Alfa Romeo",
  "Hitech Electric",
  "Great Wall",
  "Cab Motors",
  "Gas Gas",
  "Harley-Davidson",
  "Jiapeng Volcano",
  "Moto Guzzi",
  "Mv Agusta",
  "Regal Raptor",
  "Super Soco",
  "D2d Motors",
  "GM Chevrolet",
  "VW Volkswagen",
];
