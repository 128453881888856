// Para popular o array abaixo, foi feita a consulta no resources e nomes que continham "-", "/", "+", foram substituidos por " ", para que entre na categoria de nomes compostos

export const compositeCarModels = [
  "Top Sport",
  "3000 Gt",
  "Eclipse Cross",
  "Eclipse Spyder",
  "I Miev",
  "L200 Outdoor",
  "L200 Savana",
  "L200 Triton",
  "Outlander Sport",
  "Pajero Dakar",
  "Pajero Full",
  "Pajero Io",
  "Pajero Sport",
  "Pajero Tr4",
  "Space Wagon",
  "John Cooper Works",
  "Mg 550",
  "Mg 6",
  "Grand Marquis",
  "170 D",
  "170 S",
  "180 C",
  "180 D",
  "190 C",
  "190 D",
  "190 E",
  "190 Sl",
  "200 D",
  "220 D",
  "220 E",
  "220 S",
  "230 Ce",
  "230 E",
  "230 S",
  "240 D",
  "240 Gd",
  "250 C",
  "250 S",
  "250 Se",
  "250 Sl",
  "260 E",
  "260 Se",
  "280 Ce",
  "280 E",
  "280 S",
  "280 Se",
  "280 Sel",
  "280 Sl",
  "280 Slc",
  "280 Te",
  "300 Cd",
  "300 Ce",
  "300 D",
  "300 E",
  "300 Gd",
  "300 Sd",
  "300 Se",
  "300 Sel",
  "300 Sl",
  "300 Td",
  "300 Te",
  "320 Ce",
  "320 E",
  "320 Sl",
  "350 Se",
  "350 Sel",
  "350 Sl",
  "350 Slc",
  "380 Se",
  "380 Sec",
  "380 Sl",
  "420 Sel",
  "450 Se",
  "450 Sel",
  "450 Sl",
  "450 Slc",
  "500 E",
  "500 Se",
  "500 Sec",
  "500 Sel",
  "500 Sl",
  "500 Slc",
  "560 Sec",
  "560 Sel",
  "560 Sl",
  "600 Sel",
  "600 Sl",
  "A 160",
  "A 180",
  "A 190",
  "A 200",
  "A 250",
  "A 35 Amg",
  "A 45 Amg",
  "Amg Gt",
  "Amg Gt 43",
  "Amg Gt 63",
  "B 170",
  "B 180",
  "B 200",
  "C 180",
  "C 180 K",
  "C 200",
  "C 200 K",
  "C 220",
  "C 230",
  "C 230 K",
  "C 240",
  "C 250",
  "C 280",
  "C 300",
  "C 320",
  "C 32K Amg",
  "C 350",
  "C 36 Amg",
  "C 43 Amg",
  "C 450",
  "C 55 Amg",
  "C 63 Amg",
  "Cl 500",
  "Cl 55 Amg",
  "Cl 600",
  "Cl 63 Amg",
  "Cl 65",
  "Cl 65 Amg",
  "Cla 180",
  "Cla 200",
  "Cla 250",
  "Cla 35 Amg",
  "Cla 45 Amg",
  "Classe A",
  "Clc 200",
  "Clc 200 K",
  "Clk 200",
  "Clk 230",
  "Clk 320",
  "Clk 350",
  "Clk 430",
  "Clk 500",
  "Clk 55 Amg",
  "Clk 550",
  "Cls 350",
  "Cls 400",
  "Cls 450",
  "Cls 500",
  "Cls 53 Amg",
  "Cls 55 Amg",
  "Cls 550",
  "Cls 63 Amg",
  "E 200",
  "E 220",
  "E 230",
  "E 240",
  "E 250",
  "E 280",
  "E 300",
  "E 320",
  "E 350",
  "E 400",
  "E 420",
  "E 43 Amg",
  "E 430",
  "E 500",
  "E 500 Eb4",
  "E 53 Amg",
  "E 55 Amg",
  "E 550",
  "E 63 Amg",
  "E 65",
  "Eqc 400",
  "G 350",
  "G 500",
  "G 55 Amg",
  "G 63 Amg",
  "Gl 320",
  "Gl 350",
  "Gl 500",
  "Gl 63 Amg",
  "Gla 200",
  "Gla 250",
  "Gla 45 Amg",
  "Glb 200",
  "Glc 220D",
  "Glc 250",
  "Glc 300",
  "Glc 43 Amg",
  "Glc 63 Amg",
  "Gle 350",
  "Gle 400",
  "Gle 400D",
  "Gle 43 Amg",
  "Gle 450",
  "Gle 53 Amg",
  "Gle 63 Amg",
  "Glk 220",
  "Glk 280",
  "Glk 300",
  "Glk 350",
  "Gls 350",
  "Gls 450",
  "Gls 500",
  "Gls 63 Amg",
  "Ml 230",
  "Ml 320",
  "Ml 350",
  "Ml 430",
  "Ml 500",
  "Ml 55 Amg",
  "Ml 550",
  "Ml 63 Amg",
  "R 500",
  "R 500 L",
  "R 63 Amg",
  "R 63 L Amg",
  "S 280",
  "S 320",
  "S 350",
  "S 400",
  "S 500",
  "S 500 L",
  "S 55 Amg",
  "S 550",
  "S 560",
  "S 600",
  "S 63 Amg",
  "S 65 Amg",
  "Sl 280",
  "Sl 320",
  "Sl 350",
  "Sl 400",
  "Sl 500",
  "Sl 55 Amg",
  "Sl 550",
  "Sl 600",
  "Sl 63 Amg",
  "Sl 65 Amg",
  "Slc 300",
  "Slc 43 Amg",
  "Slk 200",
  "Slk 230",
  "Slk 250",
  "Slk 300",
  "Slk 32 Amg",
  "Slk 320",
  "Slk 350",
  "Slk 55 Amg",
  "Sls Amg",
  "B 2200",
  "B 2500",
  "Cx 5",
  "Cx 7",
  "Cx 9",
  "Mx 3",
  "Mx 5",
  "Mx 6",
  "Rx 7",
  "Rx 8",
  "300 Zx",
  "D 21",
  "Grand Livina",
  "Gt R",
  "King Cab",
  "Pick Up",
  "X Trail",
  "Pick Up",
  "Ct200h F Sport",
  "Es 300H",
  "Es 330",
  "Es 350",
  "Gs 350",
  "Gs 460 L",
  "Gx 470",
  "Is 250",
  "Is 350",
  "Is F",
  "Is250 F Sport",
  "Ls 430",
  "Ls 460 L",
  "Ls 500H",
  "Lx 570",
  "Nx 200T",
  "Nx 300",
  "Nx 300H",
  "Rx 330",
  "Rx 350",
  "Rx 450H",
  "Rx350 F Sport",
  "Sc 300",
  "Sc 400",
  "Sc 430",
  "Ux 250H",
  "Discovery 2",
  "Discovery 3",
  "Discovery 4",
  "Discovery Sport",
  "Freelander 2",
  "Range Rover",
  "Range Rover Evoque",
  "Range Rover Sport",
  "Range Rover Velar",
  "Range Rover Vogue",
  "Series I",
  "Series Iii",
  "Grand Voyager",
  "Road Runner",
  "Special Deluxe",
  "Formula 400",
  "Formula 402",
  "Grand Am",
  "Grand Prix",
  "Le Mans",
  "Trans Sport",
  "181 Kurierwagen",
  "Cross Up",
  "Karmann Ghia",
  "Mg Spaic",
  "New Beetle",
  "Passat Variant",
  "Polo Sedan",
  "Space Cross",
  "T Cross",
  "Variant Ii",
  "T4 Expedition",
  "Corolla Cross",
  "Etios Cross",
  "Fj Cruiser",
  "Hilux Sw4",
  "Land Cruiser",
  "Land Cruiser Prado",
  "Mr 2",
  "T 100",
  "Grand Vitara",
  "S Cross",
  "Actyon Sports",
  "Rexton Ii",
  "A9 Cargo",
  "T20 Baú",
  "Sc 2",
  "Sl 2",
  "9 3",
  "9 3 Cabrio",
  "9 3 Sport Combi",
  "9 3X",
  "9 5 Sedan",
  "20 25",
  "Silver Cloud Ii",
  "Silver Cloud Iii",
  "Silver Shadow",
  "Silver Shadow Ii",
  "Silver Spirit",
  "Silver Spur",
  "Silver Wraith",
  "Silver Wraith Ii",
  "Duster Oroch",
  "Grand Scénic",
  "Pick Up",
  "Carrera Gt",
  "Cayman R",
  "Cayman S",
  "Spyder 500",
  "Spyder 550",
  "Cj 5",
  "Cj 6",
  "Grand Cherokee",
  "2 Cv",
  "3 Cv",
  "C3 Picasso",
  "C3 Sonora",
  "C4 Cactus",
  "C4 Grand Picasso",
  "C4 Lounge",
  "C4 Picasso",
  "Grand C4",
  "Traction Avant",
  "Xsara Picasso",
  "300 C",
  "300 M",
  "Grand Caravan",
  "Le Baron",
  "Pt Cruiser",
  "Town & Country",
  "Advanced Design",
  "Bel Air",
  "C 1504",
  "Chevy 500",
  "Corvette Grand Sport",
  "De Luxe",
  "El Camino",
  "Grand Blazer",
  "Monte Carlo",
  "Space Van",
  "Sport Van",
  "Van G20",
  "Ats V",
  "Cts V",
  "De Ville",
  "Series 62",
  "Bgr 5",
  "M 10",
  "M 10 1 2",
  "M 11",
  "M 12",
  "M 8",
  "1 M",
  "M 135I",
  "M 140I",
  "M 235I",
  "M 240I",
  "M 340I",
  "M 440I",
  "M 760Li",
  "M 850I",
  "New Class",
  "E Tron",
  "Rs E Tron Gt",
  "Rs Q3",
  "Rs Q8",
  "Tt Rs",
  "V12 Vantage",
  "Am 825",
  "Hi Topic",
  "2000 Gtv",
  "2300 B",
  "2300 Sl",
  "2300 Ti",
  "8C Competizione",
  "Fnm 2150",
  "Jk Fnm 2000",
  "Super Salon",
  "Super Buggy",
  "Gran Move",
  "Move Van",
  "Picape Cabine Dupla",
  "Picape Caçamba Curta",
  "Picape Caçamba Longa",
  "Picape Caçamba Longa Baú",
  "Picape Plutus",
  "E Pace",
  "E Type",
  "F Pace",
  "F Type",
  "I Pace",
  "Mark Ii",
  "Mark Ii",
  "Mark Iv",
  "Mark V",
  "Mark Vii",
  "Mark X",
  "S Type",
  "Sovereign Long",
  "X Type",
  "Xj S",
  "Xj Sc",
  "Xk 120",
  "Xkr S",
  "E Js4",
  "Iev330 P",
  "Grand Santa Fé",
  "H1 Starex",
  "I30 Cw",
  "S Coupé",
  "Santa Fé",
  "Hr V",
  "Wr V",
  "Towner Jr",
  "Br 800",
  "G 15",
  "X 15",
  "Hover Cuv",
  "Aumark 3.5",
  "Bronco Sport",
  "Club Wagon",
  "Corcel Ii",
  "Crown Victoria",
  "Del Rey",
  "E 350",
  "F 1",
  "F 100",
  "F 1000",
  "F 150",
  "F 2000",
  "F 250",
  "F 350",
  "F 4000",
  "F 75",
  "F Maxx",
  "F3 Pick Up",
  "Ka +",
  "Mustang V6",
  "Pick Up",
  "Sport Trac",
  "Super Deluxe",
  "Buggy Pronto",
  "Buggy Reindustralizado",
  "Fibravan Vip",
  "Kauipe Luxo",
  "Kit Cru(Buggy)",
  "Kit Pronto(Buggy)",
  "Grand Siena",
  "328 Gtb",
  "365 Gtb",
  "430 Scuderia",
  "458 Italia",
  "458 Speciale",
  "458 Spider",
  "488 Gtb",
  "488 Pista",
  "488 Spider",
  "550 Maranello",
  "575M Maranello",
  "599 Gtb Fiorano",
  "599 Gto",
  "812 Gts",
  "812 Superfast",
  "Dino 246 Gt",
  "Dino 308",
  "F355 F1",
  "F456 M",
  "F512 M",
  "F8 Spider",
  "F8 Tributo",
  "Super 90",
  "Engesa 4",
  "Grand Caravan",
  "Le Baron",
  "Pick Up",
  "Wc 52",
  "Arrizo 5",
  "Arrizo 5E",
  "Arrizo 6",
  "S 18",
  "Tiggo 2",
  "Tiggo 3X",
  "Tiggo 5X",
  "Tiggo 7",
  "Tiggo 8",
  "Eqv 300",
  "Gla 35 Amg",
  "R 320",
  "Arrizo 6 Pro",
  "Tiggo 7 Pro",
  "E J7",
  "E Js1",
  "Iev750 Vip",
  "Glb 35 Amg",
  "S 680",
  "Nx 350H",
  "Tiggo 5X Pro",
  "Eqs 53 Amg",
  "Partner Rapid",
  "Tiggo 8 Pro",
  "296 Gtb",
  "Song Plus",
  "Eqa 250",
  "Eqb 250",
  "Eqe 300",
  "S 580",
  "E Jv5.5",
  "E Jv7l",
  "Mustang Mach E",
  "296 Gts",
  "Yuan Plus",
  "Rx 500H",
  "Bolt Euv",
  "Haval H6",
  "Haval H6 Gt",
  "Gls 600",
  "S 420",
  "Gr Corolla",
  "E Tron Gt",
  "Q8 E Tron",
  "Megane E Tech",
  "Ora 03",
  "Eqe 300 Suv",
  "Eqs 450+ Suv",
  "Gle 450D",
  "Gs 450H",
  "C3 Aircross",
  "Fc 15",
  "Zr V",
  "Eqe 53 Amg Suv",
  "Sq8 E Tron",
  "Dolphin Mini",
  "Model S",
];
