import { Footer1Props } from "../../../props";
import styles from "./footer-working-hours-03.module.scss";

export default function FooterWorkingHours3(props: Footer1Props) {
  return (
    <div className={styles.container}>
      <div className={styles["container__wrapper"]}>
        {(props?.openingHours?.value ?? props?.openingHours) && (
          <>
            <div className={styles["container__wrapper__label"]}>
              Horários de funcionamento
            </div>
            <div className={styles["container__wrapper__hours"]}>
              Segunda à sexta das{" "}
              {String(props?.openingHours?.value ?? props?.openingHours)}
            </div>
            {props?.openingHours2?.value ??
              (props?.openingHours2 && (
                <div className={styles["container__wrapper__hours"]}>
                  Sábado das{" "}
                  {String(props?.openingHours2?.value ?? props?.openingHours2)}
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
