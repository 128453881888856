import { FooterStoreAddressProps } from "./props";
import FooterMenuVariant01 from "./variants/01";
import FooterMenuVariant03 from "./variants/03";

export default function FooterManuVariants({
  variant,
  props,
}: FooterStoreAddressProps) {
  function renderMenuVariants(): JSX.Element {
    switch (variant) {
      case "1":
      case "2":
        return <FooterMenuVariant01 {...props} />;
      case "3":
        return <FooterMenuVariant03 {...props} />;
      default:
        return <FooterMenuVariant01 {...props} />;
    }
  }

  return renderMenuVariants();
}
