"use client";
/* eslint-disable react/prop-types */
import { InputHTMLAttributes } from "react";
import { FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";

import style from "./input-text.module.scss";

export interface RHFInputTextProps {
  name: string;
  label: string;
  placeholder: string;
  register: UseFormRegister<FieldValues>;
  props?: RegisterOptions<FieldValues>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  type: string;
}

//todo: validations
export function RHFInputText({
  name,
  label,
  register,
  props,
  inputProps,
  placeholder,
  type,
}: RHFInputTextProps): JSX.Element {
  return (
    <div>
      <label htmlFor={name} className={style.label}>
        {label}
      </label>
      <input
        placeholder={placeholder}
        className={style.input}
        {...register(
          name,
          props
            ? {
                required: {
                  value: props.required as boolean,
                  message: "Campo obrigatório",
                },
                minLength: {
                  value: props.minLength as number,
                  message: `Tamanho mínimo: ${props.minLength}`,
                },
                maxLength: {
                  value: props.maxLength as number,
                  message: `Tamanho máximo: ${props.maxLength}`,
                },
              }
            : {}
        )}
      />
    </div>
  );
}
