/* eslint-disable react/prop-types */
"use client";

import { FilterItemProps } from "./props";

import FilterItemVariant1 from "./variants/01";
import FilterItemVariant2 from "./variants/02";

export default function FilterItem({
  itemKey,
  title,
  vehicles,
  queryKey,
  handleFilter,
  useListByKey = false,
  showOption,
  props,
}: FilterItemProps) {
  if (!showOption) {
    return null;
  }

  const hasVehicleTypeInInventory = () =>
    vehicles.some((v) => v[itemKey] && v[itemKey] != null && v[itemKey] != "0");

  if (!hasVehicleTypeInInventory()) {
    return <></>;
  }

  function renderFilterItem() {
    switch (queryKey) {
      case "marca":
        //@ts-ignore
        switch (Number(props?.variantsMake?.value)) {
          case 1:
            return (
              <FilterItemVariant1
                handleFilter={handleFilter}
                itemKey={itemKey}
                props={props}
                queryKey={queryKey}
                showOption={showOption}
                title={title}
                vehicles={vehicles}
                useListByKey={useListByKey}
              />
            );
          case 2:
            return (
              <FilterItemVariant2
                handleFilter={handleFilter}
                itemKey={itemKey}
                props={props}
                queryKey={queryKey}
                showOption={showOption}
                title={title}
                vehicles={vehicles}
                useListByKey={useListByKey}
              />
            );
          default:
            return (
              <FilterItemVariant1
                handleFilter={handleFilter}
                itemKey={itemKey}
                props={props}
                queryKey={queryKey}
                showOption={showOption}
                title={title}
                vehicles={vehicles}
                useListByKey={useListByKey}
              />
            );
        }
    }

    return (
      <FilterItemVariant1
        handleFilter={handleFilter}
        itemKey={itemKey}
        props={props}
        queryKey={queryKey}
        showOption={showOption}
        title={title}
        vehicles={vehicles}
        useListByKey={useListByKey}
      />
    );
  }

  return renderFilterItem();
}
