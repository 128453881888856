"use client";

import { Cover } from "~/components/non-editable/ui/cover";
import { Hero1Props } from "../../props";
import { ImagesSlider } from "~/components/non-editable/ui/image-slider-act";

export default function HeroVariant05(props: Hero1Props): JSX.Element {
  const images = [
    "https://cdn-images.sync.com.br/650x500/ea6d1771-95f9-4433-b4d5-e8592d052b81.JPG",
    "https://cdn-images.sync.com.br/650x500/ea6d1771-95f9-4433-b4d5-e8592d052b81.JPG",
    "https://cdn-images.sync.com.br/650x500/ea6d1771-95f9-4433-b4d5-e8592d052b81.JPG",
  ];

  return (
    <ImagesSlider
      images={images}
      autoplay={true}
      overlay={true}
      className="h-screen"
    >
      <div>
        <h1 className="text-4xl md:text-4xl lg:text-6xl font-semibold max-w-7xl mx-auto text-center mt-6 relative z-50 py-6 bg-clip-text text-transparent bg-gradient-to-b from-neutral-800 via-neutral-700 to-neutral-700 dark:from-neutral-800 dark:via-white dark:to-white">
          Encontre o carro dos seus sonhos <br /> na{" "}
          <Cover>Concentino Motors</Cover>
        </h1>
      </div>
    </ImagesSlider>
  );
}
