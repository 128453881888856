"use client";

import { useEffect, useRef, useState } from "react";
import { useAnimation, AnimationControls } from "framer-motion";
import VehicleGallery from "~/components/non-editable/misc/vehicle-gallery";
import { VehicleGalleryVariant } from "~/components/non-editable/misc/vehicle-gallery/props";
import { DetailsVariantsProps } from "../../../props";
import useDeviceType from "~/hooks/useDeviceType";
import {
  DoorClosed,
  Fuel,
  SlidersVertical,
  FileSpreadsheet,
  ArrowRight,
  Download,
  X,
  Eye,
} from "lucide-react";
import CTASection from "./cta";
import { DetailsTabs } from "./tabs";
import Link from "next/link";
import "../../../style.css";
import DataLayerForm from "../../../datalayer-form";
import { FloatHeader } from "./float-header";
import FeatureSlider from "./features-slider";

export function BrandNewVehicleDetailsVariant1({
  props,
  vehicle,
}: DetailsVariantsProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false);
  const { isMobile } = useDeviceType();
  const detailsRef = useRef<HTMLDivElement>(null);
  const controls: AnimationControls = useAnimation();

  const externalImages = vehicle.images;

  const features = vehicle.vehicleZeroKMOutput?.files.filter(
    (file) => file.category === 4 && file.title && file.description
  );

  const files = vehicle.vehicleZeroKMOutput?.files.filter(
    (file) => file.category === 3 && file.title
  );

  const manufactureYear =
    vehicle.vehicleZeroKMOutput?.vehicleSpecification.vehicleCategories
      .find((i) => i.name === "Dados básicos")
      ?.vehicleItems.find((i) => i.name === "Ano de fabricação")?.value ?? null;

  const modelYear =
    vehicle.vehicleZeroKMOutput?.vehicleSpecification.modelYear ?? null;

  const vehicleColors = vehicle.vehicleZeroKMOutput?.colors ?? [];

  const vehicleSpecification = [
    {
      label: "doors",
      value: vehicle.doors,
    },
    { label: "fuelType", value: vehicle.fuel },
    {
      label: "transmissionType",
      value: vehicle.transmission,
    },
  ];

  const handleIcon = (key: string) => {
    switch (key) {
      case "doors":
        return <DoorClosed color="black" size={18} strokeWidth={1.5} />;
      case "fuelType":
        return <Fuel color="black" size={18} strokeWidth={1.5} />;
      case "transmissionType":
        return <SlidersVertical color="black" size={18} strokeWidth={1.5} />;
    }
  };

  const horsePower =
    vehicle.vehicleZeroKMOutput?.vehicleSpecification?.vehicleCategories
      ?.find((i) => i.name === "Motor / Desempenho / Consumo")
      ?.vehicleItems?.find((x) => x.name === "Potência (cv/rpm)")
      ?.value?.split("/")[0] ?? null;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsHeaderVisible(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (detailsRef.current) {
      observer.observe(detailsRef.current);
    }

    return () => {
      if (detailsRef.current) {
        observer.unobserve(detailsRef.current);
      }
    };
  }, [detailsRef]);

  useEffect(() => {
    if (isHeaderVisible) {
      controls.start({ y: 0, opacity: 1 });
    } else {
      controls.start({ y: -100, opacity: 0 });
    }
  }, [isHeaderVisible, controls]);

  return (
    <>
      <style jsx global>
        {`
          .fslightboxs {
            border-radius: 4px !important;
          }
          .fslightbox-slide-btn {
            border-radius: 4px !important;
          }
          .fslightbox-toolbar {
            border-bottom-left-radius: 4px !important;
          }

          #buttonCta1 {
            background-color: var(--primary-800) !important;
            border-radius: var(--border-lg) !important;
          }
          #buttonCta1:hover {
            background-color: var(--primary-500) !important;
          }

          #containerCta1 {
            padding: 0;
          }
        `}
      </style>
      <main id="main" className="mt-6">
        <div
          className={`flex ${
            isMobile ? "flex-wrap" : ""
          } gap-3 px-3 lg:px-0 max-w-[1500px] mx-auto mb-5`}
        >
          {vehicle &&
            props.options != undefined &&
            props.options.galeryType != undefined && (
              <VehicleGallery
                variant={
                  String(
                    props.options.galeryType?.value ?? props.options.galeryType
                  ) as VehicleGalleryVariant
                }
                vehicle={vehicle}
              />
            )}
          <div
            id="details"
            ref={detailsRef}
            className="md:min-w-[500px] md:max-w-[550px] w-full flex-1 mt-6 px-4 py-4 rounded-lg mx-auto relative"
          >
            <h1 className="font-semibold text-xl">
              {vehicle.make} - {vehicle.model}{" "}
              {manufactureYear ? `${manufactureYear}/${modelYear}` : ""}
            </h1>
            <h2 className="text-xl md:text-sm">
              {vehicle.version} -{" "}
              {vehicle.vehicleZeroKMOutput?.vehicleSpecification.category}
            </h2>

            <div className="mt-5">
              <Link
                href="#technical-sheet"
                scroll={true}
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById("technical-sheet");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="text-lg md:text-sm text-gray-500 flex gap-2"
              >
                <FileSpreadsheet color="#6B7280" strokeWidth={1.5} />
                <span className="border-b-[1px] border-gray-500">
                  Ficha Técnica
                </span>
              </Link>
            </div>

            <div className="mt-5">
              <h3 className="font-semibold text-lg md:text-sm">Informações:</h3>

              <div className="flex flex-wrap gap-2 mt-2">
                {vehicleSpecification.map((i, index) => (
                  <div
                    key={`${i.label + "-" + i.value + index}`}
                    className="bg-[#DFE4E8] w-fit gap-2 px-2 py-1 flex items-center justify-center flex-nowrap rounded-lg"
                  >
                    {handleIcon(i.label)}
                    <span className="text-[12px] whitespace-nowrap">
                      {i.label === "doors" ? `${i.value} portas` : i.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div className="border-b-[1px] border-gray-200 h-[1px] w-full my-[20px] rounded-full" />

            <div className="flex justify-between items-center">
              <p className="text-lg md:text-sm">Preço</p>
              <p className="font-semibold text-lg md:text-sm">
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(vehicle.price)}
              </p>
            </div>

            <div className="border-b-[1px] border-gray-200 w-full my-[20px] rounded-full" />

            {horsePower && (
              <>
                <div className="text-lg md:text-sm flex justify-between items-center">
                  <p>Potência</p>
                  <p className="font-semibold">{horsePower}cv</p>
                </div>

                <div className="border-b-[1px] border-gray-200 w-full my-[20px] rounded-full" />
              </>
            )}

            <div>
              <h4 className="font-semibold text-lg md:text-sm">
                Cores Disponíveis:
              </h4>
              <div className="flex gap-4 mt-2 flex-wrap">
                {vehicleColors.map((color, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center justify-end min-w-[55px] min-h-[55px] w-12 h-12 p-1 rounded-lg shadow-md cursor-pointer"
                    style={{ backgroundColor: color.hexaCode }}
                  >
                    <span
                      className="text-[10px] flex justify-end items-end font-medium text-white"
                      style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)" }}
                    >
                      {color.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-20 md:mt-2 lg:!mt-[42%]">
              <button
                onClick={() => setOpen(true)}
                className="px-6 py-3 w-full bg-primary-800 text-white font-bold rounded-lg shadow-md hover:bg-primary-700 transition-colors flex items-center justify-center"
              >
                Solicitar Cotação <ArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </div>

        {/* {features &&
          features.length > 0 &&
          features.map((feature, index) => {
            const img = feature.url;

            console.log(features);

            if (!feature.title || !feature.description) return;

            return (
              <CTASection
                key={`feature-${feature.fileName}-${feature.url}-${index}`}
                imageUrls={[img]}
                make={vehicle.make}
                model={vehicle.model}
                title={feature.title}
                version="2021"
                text={feature.description}
                reverse={index % 2 !== 0}
              />
            );
          })} */}

        {features && features.length > 0 && (
          <FeatureSlider vehicle={vehicle} features={features} />
        )}

        <CTASection
          imageUrls={[
            externalImages.split(",")[0],
            externalImages.split(",")[1],
            externalImages.split(",")[2],
          ]}
          make={vehicle.make}
          model={vehicle.model}
          version="2021"
          text={`Cada detalhe foi cuidadosamente projetado para transformar suas viagens em uma experiência única. Desde o design elegante até a tecnologia de ponta, tudo se une para oferecer uma jornada que é verdadeiramente`}
          reverse
          useGradient
          action={() => setOpen(true)}
          title={`Conheça o ${vehicle.make} ${vehicle.model}`}
          flipWords={[
            "memorável.",
            "incrível.",
            "emocionante.",
            "confortável.",
            "luxuosa.",
          ]}
        />

        <DetailsTabs vehicle={vehicle} />
        <section id="downloads">
          <div className="max-w-[1500px] mx-auto px-3">
            <h5 className=" text-[#8b8b8b] mb-4 text-xl">
              <span className="underline">Down</span>loads
            </h5>
            <div className="flex flex-col space-y-1 max-w-[90%] sm:max-w-[80%] md:max-w-[50%]">
              {files &&
                files.map((file, index) => {
                  const linkForDownload = file.url.replace(
                    "?wrap=0",
                    "?download=1"
                  );
                  return (
                    <Link
                      key={`file-${index}-${file.title}-${file.fileExtension}`}
                      download={file.fileName}
                      href={file.url}
                      target="_blank"
                      className="flex items-center justify-between text-lg md:text-sm text-gray-500 hover:underline"
                    >
                      <span className="flex-1">{file.title}</span>
                      <div className="ml-2 flex gap-4 items-center">
                        <div>
                          <Link
                            key={`file-${index}-${file.title}-${file.fileExtension}`}
                            download={file.fileName}
                            href={file.url}
                            target="_blank"
                            className="flex items-center justify-between text-lg md:text-sm text-gray-500 hover:underline"
                          >
                            <Eye
                              className="hover:text-primary-500 cursor-pointer"
                              size={24}
                              strokeWidth={1.5}
                            />
                          </Link>
                        </div>
                        <div className="pb-1">
                          <Link
                            key={`file-${index}-${file.title}-${file.fileExtension}`}
                            download={file.fileName}
                            href={linkForDownload}
                            className="flex items-center justify-between text-lg md:text-sm text-gray-500 hover:underline"
                          >
                            <Download
                              className="hover:text-primary-500 cursor-pointer"
                              size={24}
                              strokeWidth={1.5}
                            />
                          </Link>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </section>
      </main>
      {open && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-stone-300/20 backdrop-blur-[2px] z-50">
          <section className="rounded-lg bg-white p-10 pb-8 pt-12 relative min-w-[350px] md:min-w-[500px]">
            <button
              className="border-none bg-transparent absolute top-4 right-4"
              onClick={() => setOpen(false)}
            >
              <X strokeWidth={1.5} />
            </button>
            <DataLayerForm
              props={props}
              variant={1}
              vehicle={vehicle}
              vehiclePrice={Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(vehicle.price)}
            />
          </section>
        </div>
      )}

      {isHeaderVisible && <FloatHeader controls={controls} setOpen={setOpen} />}
    </>
  );
}
