"use client";

import { useState } from "react";
import {
  ContactActionProps,
  DetailsVariantsProps,
  GenerateLead,
} from "../props";
import styles from "./datalayer-form.module.scss";
import { setCookie } from "cookies-next";
import { PlatformsIcon } from "~/components/non-editable/core";
import {
  maskPhone,
  removeNonNumericAndSpaces,
  replaceNewlinesWithSpaces,
} from "~/utils";
import { useForm } from "react-hook-form";
import PlatformsToast from "~/components/non-editable/core/patforms-toast";
import useDomain from "~/hooks/useSubdomain";

export default function DataLayerForm({
  props,
  vehiclePrice,
  vehicle,
}: DetailsVariantsProps) {
  const { domain } = useDomain();
  const [toast, setToast] = useState<{ message: string; type: string } | null>(
    null
  );

  const isVehicleLead = true;

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm();

  const contactActions = Array.isArray(props.contactActions)
    ? props.contactActions
    : //@ts-ignore
      props.contactActions.items;
  const whatsappAction = contactActions.find(
    (i: ContactActionProps) => i.actionType === "whatsApp"
  );

  const vendorWhatsappPhone =
    (whatsappAction?.link?.value || whatsappAction?.link) ?? "";

  const workflowId =
    typeof props.options.workflowId === "string"
      ? props.options.workflowId
      : props.options.workflowId.value;

  const onSubmit = async (data: any): Promise<void | boolean> => {
    let localUrl = "";

    if (typeof window != "undefined") {
      localUrl = window.location.href;
    }

    if (vendorWhatsappPhone && vendorWhatsappPhone != "") {
      const defaultMessage = `Olá, gostaria de saber mais sobre esse veículo que estou vendo no seu site:

${localUrl}

${data.message}`;

      const encodedFullMessage = encodeURIComponent(defaultMessage);

      window.open(
        `https://wa.me/55${removeNonNumericAndSpaces(
          String(vendorWhatsappPhone)
        )}?text=${encodedFullMessage}`,
        "_blank"
      );
    }

    setToast({
      message: "Dados enviados com sucesso! Aguarde o contado do vendedor.",
      type: "success",
    });

    let originUrl;
    if (typeof window !== "undefined") {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port;
      originUrl = `${protocol}//${hostname}${port ? ":" + port : ""}${
        window.location.pathname
      }`;
    }

    const isSyncIntegration = vehicle.id.length === 36;

    const newLead = {
      name: data.name,
      email: data.email,
      cellphone: data.cellphone,
      message: replaceNewlinesWithSpaces(data.message) ?? "",
      originUrl: originUrl,
      workflowId: workflowId as string,
      sendLeadTo:
        typeof props.options.emailToSendLead === "string"
          ? props.options.emailToSendLead
          : (props.options.emailToSendLead.value as string) ?? ("" as string),
      domain: domain,
      inventoryCode: vehicle.inventoryId,
      metadata: {
        leadId: 0,
        leadCategory: 0,
        isSyncIntegration: isSyncIntegration,
        make: vehicle.make,
        model: vehicle.model,
        version: vehicle.version,
        price: vehicle.price,
        color: vehicle.color,
        isArmored: vehicle.isArmored,
        yearModel: vehicle.modelYear,
        category: vehicle.category,
        odometer: vehicle.kilometers,
        transmission: vehicle.transmission,
        fipeCode: vehicle.fipeCode,
      },
      isVehicleLead,
    };

    const generateLead: GenerateLead = {
      event: "generate_lead",
      ecommerce: {
        currency: "BRL",
        value: vehicle?.price,
        items: [
          {
            item_id: vehicle?.externalId ?? vehicle?.id,
            item_name: vehicle?.model,
            item_brand: vehicle?.make,
            item_category:
              vehicle?.type.toLowerCase() === "car" ? "Carro" : "Moto",
            item_category2: vehicle?.category,
            item_variant: vehicle?.version,
            price: vehicle?.price,
            year: `${vehicle?.manufactureYear}/${vehicle?.modelYear}`,
            mileage: vehicle?.kilometers,
            transmission: vehicle?.transmission,
            fuel_type: vehicle?.fuel,
            color: vehicle?.color,
            plate_end: vehicle?.plate?.split("").at(-1) ?? null,
            quantity: 1,
            optional_features: vehicle?.features?.includes(",")
              ? vehicle?.features.split(",")
              : vehicle?.features,
          },
        ],
      },
      user_data: {
        fullName: data.name,
        email: data.email,
        phone: data.cellphone,
      },
    };

    if (generateLead.user_data) {
      setCookie("@user-datalayer", generateLead.user_data);
    }
    if (typeof window !== "undefined") {
      if (
        //@ts-ignore
        typeof window.dataLayer != "undefined" &&
        //@ts-ignore
        Array.isArray(window.dataLayer)
      ) {
        //@ts-ignore
        window.dataLayer.push(generateLead);
      } else {
        //@ts-ignore
        window.dataLayer = [];
        //@ts-ignore
        window.dataLayer.push(generateLead);
      }
    }

    try {
      await fetch(
        `${process.env.BASE_ADDRESS}/api/workflow-basic-contact-form`,
        {
          mode: "no-cors",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newLead),
        }
      );
    } catch (error) {
      console.error(error);
    }
    setToast(null);
    reset();

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(isSubmitting);
      }, 2000);
    });
  };

  return (
    <div className={`${styles["form-container"]} form-container-${props.id}`}>
      <div>
        {(vehiclePrice && props?.options.showVehiclePrice?.value) ??
          (props?.options.showVehiclePrice && (
            <p className={`${styles.price} price-${props.id}`}>
              {vehiclePrice}
            </p>
          ))}
        <h3 className={`${styles.contact} contact-${props.id}`}>
          Envie uma mensagem ao vendedor
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${styles.form} form-${props.id}`}>
            <div>
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                id="name"
                placeholder="Digite seu nome"
                required
                {...register("name")}
              />
            </div>

            <div>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                placeholder="Digite seu email"
                required
                {...register("email")}
              />
            </div>

            <div>
              <label htmlFor="cellphone">Telefone</label>
              <input
                type="tel"
                id="cellphone"
                placeholder="Digite seu telefone"
                required
                maxLength={15}
                {...register("cellphone", {
                  onChange: (e) => {
                    const maskedValue = maskPhone(e.target.value);
                    setValue("cellphone", maskedValue);
                  },
                  maxLength: 15,
                })}
              />
            </div>

            <div>
              <label htmlFor="email">Mensagem</label>
              <textarea
                rows={4}
                id="message"
                placeholder="Digite sua mensagem"
                {...register("message")}
              />
            </div>
            <button disabled={isSubmitting} type="submit">
              {!isSubmitting ? (
                <>
                  <PlatformsIcon
                    width={25}
                    height={25}
                    className={`${styles.icon} icon-${props.id}`}
                    icon="ic:round-whatsapp"
                  />
                  {vendorWhatsappPhone && vendorWhatsappPhone != ""}
                  Falar no Whatsapp
                </>
              ) : (
                <>
                  <PlatformsIcon
                    width={25}
                    height={25}
                    className={`${styles.icon__loading} icon-${props.id}`}
                    icon={"mingcute:loading-3-fill"}
                  />
                  Aguarde um momento...
                </>
              )}
            </button>
          </div>
        </form>
      </div>
      {toast && <PlatformsToast message={toast.message} type={toast.type} />}
    </div>
  );
}
