import { useEffect, useState } from "react";

const useDomain = (): { domain: string | undefined } => {
  const [domain, setDomain] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getDomain = () => {
      const isClient = typeof window !== "undefined";
      if (isClient) {
        setDomain(window.location.hostname);
      } else {
        const parsedUrl = new URL(
          typeof window !== "undefined" ? window.location.href : ""
        );
        setDomain(parsedUrl.hostname);
      }
    };

    getDomain();
  }, []);

  return { domain };
};

export default useDomain;
