"use client";

import { JsonLDProps } from "../props";

export const JsonLd = ({ vehicle }: JsonLDProps) => {
  const generateFakeChassis = (): string => {
    const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let chassis = "";

    for (let i = 0; i < 17; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      chassis += characters.charAt(randomIndex);
    }

    return chassis;
  };

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": vehicle?.type.toLowerCase() === "car" ? "Car" : "Motorcycle",
    name: `${vehicle?.modelYear} ${vehicle?.make} ${vehicle?.version ?? ""}`,
    vehicleIdentificationNumber: generateFakeChassis(),
    image: vehicle?.images.split(","),
    url: typeof window != "undefined" ? window.location.href : "",
    offers: {
      "@type": "Offer",
      availability: "https://schema.org/InStock",
      price: vehicle?.price,
      priceCurrency: "BRL",
    },
    itemCondition:
      vehicle?.kilometers <= 100
        ? "https://schema.org/NewCondition"
        : "https://schema.org/UsedCondition",

    brand: {
      "@type": "Brand",
      name: vehicle?.make,
    },
    model: vehicle?.model,
    vehicleConfiguration:
      vehicle?.type.toLowerCase() === "car" ? vehicle?.version : vehicle?.style,
    vehicleModelDate: vehicle?.modelYear,
    mileageFromOdometer: {
      "@type": "QuantitativeValue",
      value: vehicle?.kilometers,
      unitCode: "KMT",
    },
    color: vehicle?.color,
    vehicleInteriorColor: "Gray",
    vehicleInteriorType: "Standard",
    bodyType:
      vehicle?.type.toLowerCase() === "car"
        ? vehicle?.category
        : vehicle?.style,
    driveWheelConfiguration: "https://schema.org/FrontWheelDriveConfiguration",
    vehicleEngine: {
      "@type": "EngineSpecification",
      fuelType: vehicle?.fuel,
    },
    vehicleTransmission:
      vehicle?.type.toLowerCase() === "car" ? vehicle?.transmission : null,
    numberOfDoors:
      vehicle?.type.toLowerCase() === "car" ? vehicle?.doors : null,
    vehicleSeatingCapacity: 5,
  };

  return (
    <>
      {typeof window != "undefined" && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
        />
      )}
    </>
  );
};
