import { useEffect, useState } from "react";

export function useGetLocalUrl() {
  const [localUrl, setLocalUrl] = useState("");

  useEffect(() => {
    setLocalUrl(window.location.href as string);
  }, [setLocalUrl]);

  return {
    localUrl,
  };
}
