export const brandsMap = new Map([
  [
    "aston-martin",
    "https://cdn-images.sync.com.br/logotipos/veiculos/AstonMartin.svg",
  ],
  ["bmw", "https://cdn-images.sync.com.br/logotipos/veiculos/Bmw.svg"],
  [
    "chevrolet",
    "https://cdn-images.sync.com.br/logotipos/veiculos/Chevrolet.svg",
  ],
  ["citroen", "https://cdn-images.sync.com.br/logotipos/veiculos/Citroën.svg"],
  ["fiat", "https://cdn-images.sync.com.br/logotipos/veiculos/Fiat.svg"],
  ["ford", "https://cdn-images.sync.com.br/logotipos/veiculos/Ford.svg"],
  ["honda", "https://cdn-images.sync.com.br/logotipos/veiculos/Honda.svg"],
  ["hyundai", "https://cdn-images.sync.com.br/logotipos/veiculos/Hyundai.svg"],
  ["jaguar", "https://cdn-images.sync.com.br/logotipos/veiculos/Jaguar.svg"],
  ["jeep", "https://cdn-images.sync.com.br/logotipos/veiculos/Jeep.svg"],
  ["kia-motors", "https://cdn-images.sync.com.br/logotipos/veiculos/Kia.svg"],
  [
    "land-rover",
    "https://cdn-images.sync.com.br/logotipos/veiculos/LandRover.svg",
  ],
  ["lexus", "https://cdn-images.sync.com.br/logotipos/veiculos/Lexus.svg"],
  [
    "mercedes-benz",
    "https://cdn-images.sync.com.br/logotipos/veiculos/MercedesBenz.svg",
  ],
  [
    "mitsubishi",
    "https://cdn-images.sync.com.br/logotipos/veiculos/Mitsubishi.svg",
  ],
  ["nissan", "https://cdn-images.sync.com.br/logotipos/veiculos/Nissan.svg"],
  ["peugeot", "https://cdn-images.sync.com.br/logotipos/veiculos/Peugeot.svg"],
  ["porsche", "https://cdn-images.sync.com.br/logotipos/veiculos/Porsche.svg"],
  ["renault", "https://cdn-images.sync.com.br/logotipos/veiculos/Renault.svg"],
  ["suzuki", "https://cdn-images.sync.com.br/logotipos/veiculos/Suzuki.svg"],
  ["toyota", "https://cdn-images.sync.com.br/logotipos/veiculos/Toyota.svg"],
  [
    "volkswagen",
    "https://cdn-images.sync.com.br/logotipos/veiculos/Volkswagen.svg",
  ],
  ["volvo", "https://cdn-images.sync.com.br/logotipos/veiculos/Volvo.svg"],
  ["yamaha", "https://cdn-images.sync.com.br/logotipos/veiculos/Yamaha.svg"],
]);
