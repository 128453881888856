"use client";

import Link from "next/link";
import { SwMakes1Props } from "./props";

import { availableBrands } from "./brands";

import styles from "./sw-makes.module.scss";

import { useTheme } from "next-themes";
import {
  handleFormatStyleString,
  vehicleLinkBuilderByReference,
} from "~/utils";
import Caroussel from "~/components/non-editable/misc/carroussel";
import { PlatformsImage } from "~/components/non-editable/core";

export function SwMakes1(props: SwMakes1Props): JSX.Element {
  const vehicles = props?.data?.vehicleList ?? [];
  const { theme } = useTheme();

  const site = props.data.site;

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}
      <section className={`${styles.section} section-${props.id}`}>
        <div className={`${styles.container} container-${props.id}`}>
          <div className={`${styles.wrapper} wrapper-${props.id}`}>
            <h2>
              Comprar por{" "}
              <span>
                Marca
                <svg
                  className={`${styles["text-decorator"]} text-decorator-${props.id}`}
                  viewBox="30 2 150 4"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 6c39.898-3.018 133.755-7.244 190 0"
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                </svg>
              </span>{" "}
            </h2>

            <p>Selecione por nossas principais marcas.</p>
            <div
              className={`${styles["navigation-wrapper"]} navigation-wrapper-${props.id}`}
            >
              <Caroussel
                itemsPerView="4"
                itens={availableBrands
                  ?.filter((i) =>
                    vehicles?.some((car) =>
                      i.alteText
                        .split(" ")[1]
                        .toLowerCase()
                        .includes(car.make.toLowerCase())
                    )
                  )
                  .map((item, idx) => {
                    const currentLink =
                      vehicleLinkBuilderByReference({
                        ref: "make",
                        value: item.linkHref.replace("/", ""),
                        site: {
                          subdomain: site?.subdomain as string,
                          customDomain: site?.customDomain ?? "",
                          preferences: site?.preferences ?? [],
                        },
                        vehicleList: props.data.vehicleList,
                      }) ?? "/";

                    return (
                      <div
                        key={"slide__inner" + idx}
                        className={`${styles.card} card-${props.id}`}
                      >
                        <Link
                          className={`${styles.link} link-${props.id}`}
                          href={currentLink}
                          prefetch
                        >
                          <PlatformsImage
                            className={`${styles.image} image-${props.id} ${
                              theme == "dark"
                                ? `${styles["image--invert"]} image--invert-${props.id}`
                                : ""
                            }`}
                            src={item.srcImg}
                            alt={item.alteText}
                            width={
                              item.alteText === "Logotipo Renault" ||
                              item.alteText === "Logotipo Citroën"
                                ? 50
                                : item.alteText === "Logotipo Volkswagen" ||
                                  item.alteText === "Logotipo Fiat"
                                ? 65
                                : item.alteText === "Logotipo Honda"
                                ? 150
                                : 80
                            }
                            height={
                              item.alteText === "Logotipo Renault"
                                ? 50
                                : item.alteText === "Logotipo Honda"
                                ? 150
                                : 80
                            }
                          />
                        </Link>
                      </div>
                    );
                  })}
                useAutoScroll
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
