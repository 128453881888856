export const availableBrands = [
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/AstonMartin.svg",
    linkHref: "/aston-martin",
    alteText: "Logotipo Aston Martin",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Bmw.svg",
    linkHref: "/bmw",
    alteText: "Logotipo BMW",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Chevrolet.svg",
    linkHref: "/chevrolet",
    alteText: "Logotipo Chevrolet",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Citroën.svg",
    linkHref: "/citroen",
    alteText: "Logotipo Citroën",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Fiat.svg",
    linkHref: "/fiat",
    alteText: "Logotipo Fiat",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Ford.svg",
    linkHref: "/ford",
    alteText: "Logotipo Ford",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Honda.svg",
    linkHref: "/honda",
    alteText: "Logotipo Honda",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Hyundai.svg",
    linkHref: "/hyundai",
    alteText: "Logotipo Hyundai",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Jaguar.svg",
    linkHref: "/jaguar",
    alteText: "Logotipo Jaguar",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Jeep.svg",
    linkHref: "/jeep",
    alteText: "Logotipo Jeep",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Kia.svg",
    linkHref: "/kia-motors",
    alteText: "Logotipo Kia-Motors",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/LandRover.svg",
    linkHref: "/land-rover",
    alteText: "Logotipo LandRover",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Lexus.svg",
    linkHref: "/lexus",
    alteText: "Logotipo Lexus",
  },
  {
    srcImg:
      "https://cdn-images.sync.com.br/logotipos/veiculos/MercedesBenz.svg",
    linkHref: "/mercedes-benz",
    alteText: "Logotipo Mercedes-Benz",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Mitsubishi.svg",
    linkHref: "/mitsubishi",
    alteText: "Logotipo Mitsubishi",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Nissan.svg",
    linkHref: "/nissan",
    alteText: "Logotipo Nissan",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Peugeot.svg",
    linkHref: "/peugeot",
    alteText: "Logotipo Peugeot",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Porsche.svg",
    linkHref: "/porshe",
    alteText: "Logotipo Porsche",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Renault.svg",
    linkHref: "/renault",
    alteText: "Logotipo Renault",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Suzuki.svg",
    linkHref: "/suzuki",
    alteText: "Logotipo Suzuki",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Toyota.svg",
    linkHref: "/toyota",
    alteText: "Logotipo Toyota",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Volkswagen.svg",
    linkHref: "/volkswagen",
    alteText: "Logotipo Volkswagen",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Volvo.svg",
    linkHref: "/volvo",
    alteText: "Logotipo Volvo",
  },
  {
    srcImg: "https://cdn-images.sync.com.br/logotipos/veiculos/Yamaha.svg",
    linkHref: "/yamaha",
    alteText: "Logotipo Yamaha",
  },
];
