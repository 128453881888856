/* eslint-disable react/prop-types */
"use client";

import { useEffect, useState } from "react";
import styles from "./filter-item.module.scss";
import { groupBy, handleNormalizeString } from "~/utils";
import { PlatformsIcon } from "~/components/non-editable/core";
import { Vehicle } from "~/types";
import { FilterItemProps } from "../../props";
import Disclosure from "../../../disclosure";
import { extractVehicleDetailsFromPathname } from "@sync/utils/src/platforms";
import { usePathname } from "next/navigation";
import { FilterDefaultQueryKeys } from "../../../options";
import slugify from "slugify";

export default function FilterItemVariant1({
  itemKey,
  title,
  vehicles,
  queryKey,
  handleFilter,
  useListByKey = false,
  showOption,
}: FilterItemProps) {
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: boolean;
  }>({});
  const pathname = usePathname();

  useEffect(() => {
    const details = extractVehicleDetailsFromPathname(pathname as string);
    const filters: { [key: string]: boolean } = {};

    if (details) {
      // Verifica e atribui as propriedades se existirem
      if (details.make) filters[FilterDefaultQueryKeys.Make] = true;
      if (details.model) filters[FilterDefaultQueryKeys.Model] = true;

      if (details.filter?.category && details.filter?.category.length) {
        filters[FilterDefaultQueryKeys.Category] = true;
      }

      if (details.filter?.style && details.filter?.style.length) {
        filters[FilterDefaultQueryKeys.Style] = true;
      }

      if (details.filter?.color && details.filter?.color.length) {
        filters[FilterDefaultQueryKeys.Color] = true;
      }

      if (details.filter?.transmission && details.filter?.transmission.length) {
        filters[FilterDefaultQueryKeys.Transmission] = true;
      }

      if (details.filter?.minKm && details.filter?.minKm.length) {
        filters[FilterDefaultQueryKeys.MinKm] = true;
      }

      if (details.filter?.maxKm && details.filter?.maxKm.length) {
        filters[FilterDefaultQueryKeys.MaxKm] = true;
      }

      if (details.filter?.minPrice && details.filter?.minPrice.length) {
        filters[FilterDefaultQueryKeys.MinPrice] = true;
      }

      if (details.filter?.maxPrice && details.filter?.maxPrice.length) {
        filters[FilterDefaultQueryKeys.MaxPrice] = true;
      }
    }

    setSelectedFilters(filters);
  }, [pathname]);

  const optionsByKey = groupBy(
    vehicles.filter((v) => v[itemKey] != "0" && v[itemKey] != null),
    itemKey
  );
  const make = groupBy(vehicles, "make");

  function generateListByKey() {
    let listByKey;
    const filterItemByKey = Object.keys(make).filter(
      (makeKey) =>
        handleNormalizeString(makeKey) ===
        (selectedFilters[FilterDefaultQueryKeys.Make] ? "true" : "false")
    );

    if (filterItemByKey.length > 0 && vehicles) {
      listByKey = groupBy(
        vehicles.filter((veic: Vehicle) =>
          filterItemByKey.some(
            (item: string) => handleNormalizeString(veic.make) === item
          )
        ),
        "category"
      );
    } else {
      listByKey = optionsByKey;
    }
    return listByKey;
  }
  const options = useListByKey ? generateListByKey() : optionsByKey;

  if (!showOption) {
    return null;
  }

  return (
    <Disclosure
      defaultOpen={true}
      title={title}
      childrenCount={Object.keys(options).length ?? 0}
    >
      {Object.keys(options)
        ?.sort((a, b) => a.localeCompare(b))
        .map((item: string, idx: number) => {
          const slugString = slugify(item, { lower: true });

          const isChecked = selectedFilters[queryKey] === true;

          return (
            <div key={idx} className={styles.container}>
              <div className={styles["input-wrapper"]}>
                <input
                  id={`filter-web-${item}-${idx}`}
                  name={`${item}`}
                  type="checkbox"
                  checked={isChecked}
                  value={slugString}
                  onChange={(e) => handleFilter(e, slugString, queryKey)}
                  className={`${styles.input} input`}
                />
                <PlatformsIcon
                  icon="mingcute:check-fill"
                  className={`${styles.icon} icon ${
                    isChecked ? styles["icon--visible"] : ""
                  }`}
                />
              </div>
              <label htmlFor={`filter-web-${item}-${idx}`}>
                {item}
                <strong>
                  {`(${(options![item as keyof Vehicle] as Vehicle[]).length})`}
                </strong>
              </label>
            </div>
          );
        })}
    </Disclosure>
  );
}
