import FilterItem from "./filter-item";
import RadioItem from "./radio-item";
import RangeItem from "./range-item";

export enum FilterDefaultQueryKeys {
  Make = "marca",
  Model = "modelo",
  Version = "versao",
  Category = "carroceria",
  ManufactureYear = "anoMin",
  ModelYear = "anoMax",
  MinKm = "minKm",
  MaxKm = "maxKm",
  MinPrice = "precoMin",
  MaxPrice = "precoMax",
  Optionals = "opc",
  FuelType = "combustivel",
  Transmission = "transmissao",
  Style = "estilo",
  Color = "cor",
  Sort = "ordenar",
  Page = "pg",
}

export const filterItemsOptions = [
  {
    key: "make",
    queryKey: FilterDefaultQueryKeys.Make,
    title: "Marca",
    useListByKeys: false,
    visibleProp: "showMakeFilter",
    isKm: false,
    Component: FilterItem,
  },
  {
    key: "model",
    queryKey: FilterDefaultQueryKeys.Model,
    title: "Modelo",
    useListByKeys: false,
    visibleProp: "showModelFilter",
    isKm: false,
    Component: FilterItem,
  },
  {
    key: ["manufactureYear", "modelYear"],
    queryKey: [
      FilterDefaultQueryKeys.ManufactureYear,
      FilterDefaultQueryKeys.ModelYear,
    ],
    title: "Ano",
    useListByKeys: true,
    visibleProp: "showYearFilter",
    isKm: false,
    Component: RangeItem,
  },
  {
    key: "category",
    queryKey: FilterDefaultQueryKeys.Category,
    title: "Categoria",
    useListByKeys: true,
    visibleProp: "showCategoryFilter",
    isKm: false,
    Component: FilterItem,
  },
  {
    key: "style",
    queryKey: FilterDefaultQueryKeys.Style,
    title: "Estilo",
    useListByKeys: false,
    visibleProp: "showStyleFilter",
    isKm: false,
    Component: FilterItem,
  },
  {
    key: "transmission",
    queryKey: FilterDefaultQueryKeys.Transmission,
    title: "Transmissão",
    useListByKeys: true,
    visibleProp: "showTransmissionFilter",
    isKm: false,
    Component: FilterItem,
  },
  {
    key: "color",
    queryKey: FilterDefaultQueryKeys.Color,
    title: "Cor",
    useListByKeys: true,
    visibleProp: "showColorFilter",
    isKm: false,
    Component: FilterItem,
  },
  // {
  //   key: "version",
  //   queryKey: FilterDefaultQueryKeys.Version,
  //   title: "Versão",
  //   useListByKeys: false,
  //   visibleProp: "showVersionFilter",
  //   isKm: false,
  //   Component: FilterItem,
  // },
  // {
  //   key: "manufactureYear",
  //   queryKey: FilterDefaultQueryKeys.ManufactureYear,
  //   title: "Ano Mínimo",
  //   useListByKeys: true,
  //   visibleProp: "showYearFilter",
  //   isKm: false,
  //   Component: SlideItem,
  // },
  // {
  //   key: "modelYear",
  //   queryKey: FilterDefaultQueryKeys.ModelYear,
  //   title: "Ano Máximo",
  //   useListByKeys: true,
  //   visibleProp: "showYearFilter",
  //   isKm: false,
  //   Component: SlideItem,
  // },
  // {
  //   key: "features",
  //   queryKey: FilterDefaultQueryKeys.Optionals,
  //   title: "Opcionais",
  //   useListByKeys: true,
  //   visibleProp: "showOptionalsFilter",
  //   isKm: false,
  //   Component: FilterItem,
  // },
  // {
  //   key: "fuel",
  //   queryKey: FilterDefaultQueryKeys.FuelType,
  //   title: "Combustível",
  //   useListByKeys: true,
  //   visibleProp: "showFuelTypesFilter",
  //   isKm: false,
  //   Component: FilterItem,
  // },
  {
    key: "minKm",
    queryKey: FilterDefaultQueryKeys.MinKm,
    title: "Km Mínimo",
    useListByKeys: true,
    visibleProp: "showKmFilter",
    isKm: true,
    Component: RadioItem,
  },
  {
    key: "maxKm",
    queryKey: FilterDefaultQueryKeys.MaxKm,
    title: "Km Máximo",
    useListByKeys: true,
    visibleProp: "showKmFilter",
    isKm: true,
    Component: RadioItem,
  },
  {
    key: "minPrice",
    queryKey: FilterDefaultQueryKeys.MinPrice,
    title: "Preço Mínimo",
    useListByKeys: true,
    isKm: false,
    visibleProp: "showPriceFilter",
    Component: RadioItem,
  },
  {
    key: "maxPrice",
    queryKey: FilterDefaultQueryKeys.MaxPrice,
    title: "Preço Máximo",
    isKm: false,
    useListByKeys: true,
    visibleProp: "showPriceFilter",
    Component: RadioItem,
  },
];
