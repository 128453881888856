"use client";

import { PlatformsIcon } from "~/components/non-editable/core";
import { useGetLocalUrl } from "~/hooks/useGetOriginHref";
import { removeNonNumericAndSpaces } from "~/utils";
import styles from "./details-variant-02.module.scss";

export const WhatsappClientButton = ({
  componentId,
  label,
  link,
}: {
  componentId: string;
  label: string;
  link: string;
}) => {
  const { localUrl } = useGetLocalUrl();

  return (
    <a
      type="submit"
      target="_blank"
      href={`https://wa.me/55${removeNonNumericAndSpaces(
        //@ts-ignore
        link
      )}?text=%20Ol%C3%A1,%20tenho%20interesse%20no%20ve%C3%ADculo%20do%20link:%20${localUrl}`}
      className={`${styles["action-button"]} action-button-${componentId} gtm_event_whatsapp`}
      rel="noreferrer"
      data-umami-event="Falar no Whatsapp"
    >
      <div className={`${styles.icon} icon-${componentId}`}>
        <PlatformsIcon icon={"mdi:whatsapp"} width={30} height={30} />
      </div>
      {label}
    </a>
  );
};
