/* eslint-disable prettier/prettier */
import Head from "next/head";
import "./style.css";

import { Vehicle } from "../../../../../types";
import { InventoryProps } from "../mock";

import { handleFormatStyleString } from "~/utils";
import DetailsVariants from "./variants";

import { JsonLd } from "./json-ld";

export function VehicleDetails1(props: InventoryProps): JSX.Element {
  const vehicle =
    props && props.data && props.data.vehicle
      ? (props.data.vehicle as Vehicle)
      : null;

  if (
    (props &&
      props.data &&
      props?.data?.shouldRenderDetail === false &&
      typeof props?.variants == "undefined") ||
    props.data?.vehicle == undefined
  ) {
    return <></>;
  }

  function handleVehiclePrice() {
    if (props?.data?.vehicle) {
      let price = +props.data.vehicle.price;

      if (price <= 0) return null;

      if (
        Number(
          props.options.increaseVehiclePriceAmount?.value ??
            props.options.increaseVehiclePriceAmount
        ) != 0
      ) {
        price += Number(
          props.options.increaseVehiclePriceAmount?.value ??
            props.options.increaseVehiclePriceAmount
        );
      }

      if (
        Number(
          props.options.increaseVehiclePriceInPercentage?.value ??
            props.options.increaseVehiclePriceInPercentage
        ) != 0
      ) {
        price =
          price +
          price *
            (Number(
              props.options.increaseVehiclePriceInPercentage?.value ??
                props.options.increaseVehiclePriceInPercentage
            ) /
              100);
      }

      return price.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }

  return (
    <>
      {props && props.styles && props.styles.value && (
        <style>
          {handleFormatStyleString(props.styles.value as string, props.id)}
        </style>
      )}

      <Head>
        <meta
          property="og:title"
          content={`${props?.data?.vehicle?.make} - ${props?.data?.vehicle?.model}`}
        />

        <meta
          property="og:description"
          content={props?.data?.vehicle?.generalDescription}
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:title" content={props?.data?.vehicle?.images} />

        <meta
          name="twitter:description"
          content={props?.data?.vehicle?.generalDescription}
        />

        <meta name="twitter:image" content={props?.data?.vehicle?.images} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="robots" content="follow, index" />

        <meta property="og:type" content="website" />
      </Head>

      <div className="">
        <JsonLd vehicle={vehicle as Vehicle} />

        <DetailsVariants
          vehicle={vehicle as Vehicle}
          vehiclePrice={handleVehiclePrice() as string}
          props={props}
          variant={Number(props?.detailsVariants?.value ?? 1)}
        />
      </div>
    </>
  );
}
