"use client";

import { Suspense, useState } from "react";
import { VehicleGalleryProps, VehicleGalleryVariant } from "./props";
import FsLightbox from "fslightbox-react";
import styles from "./vehicle-gallery.module.scss";
import dynamic from "next/dynamic";
import { useVehicleImages } from "~/hooks/useVehicleImages";

const GridVariant = dynamic(() => import("./grid"), { ssr: false }) as any;
const BeehiveVariant = dynamic(() => import("./beehive"), {
  ssr: false,
}) as any;
const DefaultVariant = dynamic(() => import("./default"), {
  ssr: false,
}) as any;
const ShowroomGridVariant = dynamic(() => import("./showroom-grid"), {
  ssr: false,
}) as any;

const BrandNewGaleryVariant = dynamic(() => import("./brand-new"), {
  ssr: false,
}) as any;

export default function VehicleGallery({
  variant,
  vehicle,
}: VehicleGalleryProps): JSX.Element {
  const [showInternalImages, setShowInternalImages] = useState<boolean>(false);

  const internalImages = vehicle.vehicleZeroKMOutput?.files
    .filter((img) => img.category === 1)
    .map((img) => img.url)
    .join(",");

  const { vehicleImagesThumb, vehicleImagesLarge, internalImagesLarge } =
    useVehicleImages({
      images: vehicle.images,
      internalImages: internalImages,
    });

  const is0KM = vehicle.vehicleZeroKMOutput ? true : false;

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number: number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  function renderContent() {
    switch (variant) {
      case VehicleGalleryVariant.Default:
        return (
          <DefaultVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
      case VehicleGalleryVariant.Beehive:
        return (
          <BeehiveVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
      case VehicleGalleryVariant.Grid:
        return (
          <GridVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
      case VehicleGalleryVariant.ShowroomGrid:
        return (
          <ShowroomGridVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
            vehicleImagesLarge={vehicleImagesLarge}
          />
        );
      case VehicleGalleryVariant.BrandNew:
        return (
          <BrandNewGaleryVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
            vehicleImagesLarge={vehicleImagesLarge}
          />
        );
      default:
        return (
          <DefaultVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
    }
  }

  const renderBrandNewContent = () => {
    switch (variant) {
      case VehicleGalleryVariant.BrandNew:
        return (
          <Suspense fallback={<>Carregando...</>}>
            <BrandNewGaleryVariant
              openLightboxOnSlide={openLightboxOnSlide}
              vehicle={vehicle}
              vehicleImagesThumb={vehicleImagesThumb}
              vehicleImagesLarge={vehicleImagesLarge}
              internalImagesThumb={internalImages?.split(",")}
              showInternalImages={showInternalImages}
              setShowInternalImages={setShowInternalImages}
            />
          </Suspense>
        );
      default:
        return (
          <DefaultVariant
            openLightboxOnSlide={openLightboxOnSlide}
            vehicle={vehicle}
            vehicleImagesThumb={vehicleImagesThumb}
          />
        );
    }
  };

  return (
    <Suspense fallback={null}>
      {/* @ts-ignore */}
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={
          !showInternalImages
            ? vehicleImagesLarge.map((x) => x)
            : internalImagesLarge.map((x) => x)
        }
        slide={lightboxController.slide}
        type="image"
      />
      <div className={styles.container}>
        {is0KM ? renderBrandNewContent() : renderContent()}
      </div>
    </Suspense>
  );
}
