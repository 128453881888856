import { Footer1Props } from "../../../props";
import styles from "./footer-working-hours-02.module.scss";

export default function FooterWorkingHours2(props: Footer1Props) {
  return (
    <div className={styles.container}>
      <div className={styles["container__wrapper"]}>
        {(props?.openingHours?.value ?? props?.openingHours) && (
          <>
            <div className={styles["container__wrapper__hours"]}>
              {String(props?.openingHours?.value ?? props?.openingHours)}
            </div>
            <div className={styles["container__wrapper__label"]}>
              Seg
              <div className={styles["container__wrapper__label__divider"]} />
              Sex
            </div>
          </>
        )}
      </div>
      <div className={styles["container__wrapper"]}>
        {(props?.openingHours?.value ?? props?.openingHours) && (
          <>
            <div className={styles["container__wrapper__hours"]}>
              {String(props?.openingHours?.value ?? props?.openingHours)}
            </div>
            <div className={styles["container__wrapper__label"]}>
              Sáb <div />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
