import { FooterStoreAddressProps } from "./props";
import FooterWorkingHours1 from "./variants/01";
import FooterWorkingHours2 from "./variants/02";
import FooterWorkingHours3 from "./variants/03";
export default function WorkingHoursVariants({
  variant,
  props,
}: FooterStoreAddressProps) {
  function renderWorkingHours(): JSX.Element {
    switch (variant) {
      case "1":
        return <FooterWorkingHours1 {...props} />;
      case "2":
        return <FooterWorkingHours2 {...props} />;
      case "3":
        return <FooterWorkingHours3 {...props} />;
      default:
        return <FooterWorkingHours1 {...props} />;
    }
  }

  return renderWorkingHours();
}
