export const fadeInUpStaggered = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 140,
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 80,
      delay: 0.4,
    },
  },
};
export const fadeInUp = {
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 140,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
    },
  },
};

export const fadeInDown = {
  hidden: {
    opacity: 0,
    zIndex: 2,
    y: -100,
    transition: {
      type: "spring",
      stiffness: 80,
    },
  },
  show: {
    opacity: 1,
    zIndex: 2,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 80,
      delay: 0.2,
    },
  },
};

export const fadeInDownStaggered = {
  hidden: {
    opacity: 0,
    y: -100,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 140,
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 80,
      delay: 0.4,
    },
  },
};
