"use client";

import { useEffect } from "react";
import { Vehicle } from "~/types";
import { GenerateLead } from "../props";

export const SendGTMEvents = ({ vehicle }: { vehicle: Vehicle }) => {
  const init = () => {
    const viewItem: GenerateLead = {
      event: "view_item",
      ecommerce: {
        currency: "BRL",
        value: vehicle?.price,
        items: [
          {
            item_id: vehicle?.externalId ?? vehicle?.id,
            item_name: vehicle?.model,
            item_brand: vehicle?.make,
            item_category:
              vehicle?.type.toLowerCase() === "car" ? "Carro" : "Moto",
            item_category2: vehicle?.category,
            item_variant: vehicle?.version,
            price: vehicle?.price,
            year: `${vehicle?.manufactureYear}/${vehicle?.modelYear}`,
            mileage: vehicle?.kilometers,
            transmission: vehicle?.transmission,
            fuel_type: vehicle?.fuel,
            color: vehicle?.color,
            plate_end: vehicle?.plate?.split("").at(-1) ?? null,
            quantity: 1,
            optional_features: vehicle?.features?.includes(",")
              ? vehicle?.features.split(",")
              : vehicle?.features,
          },
        ],
      },
    };

    if (typeof window !== "undefined") {
      //@ts-ignore
      if (
        //@ts-ignore
        typeof window.dataLayer != "undefined" &&
        //@ts-ignore
        Array.isArray(window.dataLayer)
      ) {
        //@ts-ignore
        window.dataLayer.push(viewItem);
      } else {
        //@ts-ignore
        window.dataLayer = [];
        //@ts-ignore
        window.dataLayer.push(viewItem);
      }
    }
  };

  useEffect(init, []);

  return <></>;
};
