import Caroussel from "~/components/non-editable/misc/carroussel";
import { SwHomeProps } from "../../props";
import styles from "./sw-home-variant-02.module.scss";
import CardsVariants from "../../../../../non-editable/misc/card-variants";

export const SwHomeVariant02 = (props: SwHomeProps): JSX.Element => {
  const vehicleList = props?.data?.vehicleList ?? [];

  return (
    <section className={`${styles.section} section-${props.id}`}>
      <div className={`${styles.container} container-${props.id}`}>
        <div className={`${styles.wrapper} wrapper-${props.id}`}>
          <h2>Veículos em destaque</h2>

          <div
            className={`${styles["images-container"]} images-container-${props.id} navigation-wrapper`}
          >
            <Caroussel
              itemsPerView="4"
              itens={
                vehicleList &&
                vehicleList?.map((vehicle, idx: number) => (
                  <CardsVariants
                    key={idx}
                    vehicle={vehicle}
                    idx={idx}
                    variant="2"
                    props={props}
                  />
                ))
              }
              useAutoScroll
            />
          </div>
        </div>
      </div>
    </section>
  );
};
