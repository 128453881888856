import {
  handleDefaltWhatsappMessage,
  phoneFormatter,
  removeNonNumericAndSpaces,
} from "~/utils";
import { Footer1Props } from "../../../props";
import styles from "./footer-store-address-01.module.scss";

export default function FooterStoreAddressVariant01(props: Footer1Props) {
  const showPhone = props.showPhone?.value ?? true;
  const showWhatsapp = props.showWhatsapp?.value ?? true;

  const phoneStore1 = props?.phoneStore1?.value ?? props?.phoneStore1;
  const whatsappStore1 = props?.whatsappPhone1?.value ?? props?.whatsappPhone1;

  const phoneStore2 = props?.phoneStore2?.value ?? props?.phoneStore2;
  const whatsappStore2 = props?.whatsappPhone2?.value ?? props?.whatsappPhone2;

  return (
    <div className={`${styles.container} container-${props.id}`}>
      <div
        className={`${styles["container__address"]} container__address-${props.id}`}
      >
        {props?.nameStore1?.value && (
          <h4
            className={`${styles["container__address__label"]} container__address__label-${props.id}`}
          >
            <div
              className={`${styles["container__address__label__decorator"]} container__address__label__decorator-${props.id}`}
            />
            {props?.nameStore1?.value && props?.nameStore1?.value}
          </h4>
        )}
        {phoneStore1 && showPhone && (
          <a
            href={`tel:${removeNonNumericAndSpaces(
              props?.phoneStore1?.value as string
            )}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            data-umami-event="Ligar Agora"
          >
            {phoneFormatter(
              props?.phoneStore1?.value
                ? (props?.phoneStore1?.value as string)
                : ""
            )}
          </a>
        )}
        {whatsappStore1 && showWhatsapp && (
          <a
            href={`https://wa.me/55${removeNonNumericAndSpaces(
              props?.whatsappPhone1?.value as string
            )}${handleDefaltWhatsappMessage()}`}
            target="_blank"
            className={`${styles["container__address__item"]} container__address__item-${props.id} gtm_event_whatsapp`}
            rel="noreferrer"
            data-umami-event="Falar no Whatsapp"
          >
            {phoneFormatter(
              props?.whatsappPhone1?.value
                ? (props?.whatsappPhone1?.value as string)
                : ""
            )}{" "}
            (WhatsApp)
          </a>
        )}
        {(props?.addressStore1?.value ?? props?.addressStore1) && (
          <a
            href={props?.linkGPS?.value as string}
            target="_blank"
            rel="noreferrer"
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            aria-label="address 1 store"
          >
            <p>{String(props?.addressStore1?.value ?? props?.addressStore1)}</p>
          </a>
        )}
      </div>
      <div
        className={`${styles["container__address"]} container__address-${props.id}`}
      >
        {props?.nameStore2?.value && (
          <h4
            className={`${styles["container__address__label"]} container__address-${props.id}`}
          >
            <div
              className={`${styles["container__address__label__decorator"]} container__address__label__decorator-${props.id}`}
            />
            {props?.nameStore2?.value && props?.nameStore2?.value}
          </h4>
        )}
        {phoneStore2 && showPhone && (
          <a
            href={`tel:${removeNonNumericAndSpaces(
              props?.phoneStore2?.value as string
            )}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id} `}
            data-umami-event="Ligar Agora"
          >
            {phoneFormatter(
              props?.phoneStore2?.value
                ? (props?.phoneStore2?.value as string)
                : ""
            )}
          </a>
        )}
        {whatsappStore2 && showWhatsapp && (
          <a
            href={`https://wa.me/55${removeNonNumericAndSpaces(
              props?.whatsappPhone2?.value as string
            )}${handleDefaltWhatsappMessage()}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id} gtm_event_whatsapp`}
            data-umami-event="Falar no Whatsapp"
          >
            {phoneFormatter(
              props?.whatsappPhone2?.value
                ? (props?.whatsappPhone2?.value as string)
                : ""
            )}{" "}
            (WhatsApp)
          </a>
        )}
        {(props?.addressStore2?.value ?? props?.addressStore2) && (
          <a
            href={props?.linkGPS2?.value as string}
            target="_blank"
            rel="noreferrer"
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            aria-label="address 1 store"
          >
            <p>{String(props?.addressStore2?.value ?? props?.addressStore2)}</p>
          </a>
        )}
      </div>
    </div>
  );
}
