import { handleDefaltWhatsappMessage, phoneFormatter } from "~/utils";
import { Footer1Props } from "../../../props";
import styles from "./footer-store-address-03.module.scss";

export default function FooterStoreAddressVariant03(props: Footer1Props) {
  return (
    <div className={`${styles.container} container-${props.id}`}>
      <div
        className={`${styles["container__address"]} container__address-${props.id}`}
      >
        <h4
          className={`${styles["container__address__label"]} container__address__label-${props.id}`}
        >
          {props?.nameStore1?.value && props?.nameStore1?.value}
        </h4>
        {(props?.phoneStore1?.value ?? props?.phoneStore1) && (
          <a
            href={`tel:${props?.phoneStore1?.value}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            data-umami-event="Ligar Agora"
          >
            {phoneFormatter(
              props?.phoneStore1?.value
                ? (props?.phoneStore1?.value as string)
                : ""
            )}
          </a>
        )}
        {(props?.whatsappPhone1?.value ?? props?.whatsappPhone1) && (
          <a
            href={`https://wa.me/55${
              props?.whatsappPhone1?.value
            }${handleDefaltWhatsappMessage()}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id} gtm_event_whatsapp`}
            data-umami-event="Falar no Whatsapp"
          >
            {phoneFormatter(
              props?.whatsappPhone1?.value
                ? (props?.whatsappPhone1?.value as string)
                : ""
            )}{" "}
            (WhatsApp)
          </a>
        )}
        {(props?.linkGPS?.value ?? props?.linkGPS) && (
          <a
            href={props?.linkGPS?.value as string}
            target="_blank"
            rel="noreferrer"
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            aria-label="address 1 store"
          >
            <p>{props?.addressStore1?.value}</p>
          </a>
        )}
      </div>
      <div
        className={`${styles["container__address"]} container__address-${props.id}`}
      >
        <h4
          className={`${styles["container__address__label"]} container__address__label-${props.id}`}
        >
          {props?.nameStore2?.value && props?.nameStore2?.value}
        </h4>
        {(props?.phoneStore2?.value ?? props?.phoneStore2) && (
          <a
            href={`tel:${props?.phoneStore2?.value}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            data-umami-event="Ligar Agora"
          >
            {phoneFormatter(
              props?.phoneStore2?.value
                ? (props?.phoneStore2?.value as string)
                : ""
            )}
          </a>
        )}
        {(props?.whatsappPhone2?.value ?? props?.whatsappPhone2) && (
          <a
            href={`https://wa.me/55${
              props?.whatsappPhone2?.value
            }${handleDefaltWhatsappMessage()}`}
            className={`${styles["container__address__item"]} container__address__item-${props.id} gtm_event_whatsapp`}
            data-umami-event="Falar no Whatsapp"
          >
            {phoneFormatter(
              props?.whatsappPhone2?.value
                ? (props?.whatsappPhone2?.value as string)
                : ""
            )}{" "}
            (WhatsApp)
          </a>
        )}
        {(props?.linkGPS2?.value ?? props?.linkGPS2) && (
          <a
            href={props?.linkGPS2?.value as string}
            target="_blank"
            rel="noreferrer"
            className={`${styles["container__address__item"]} container__address__item-${props.id}`}
            aria-label="address 1 store"
          >
            <p>{props?.addressStore2?.value}</p>
          </a>
        )}
      </div>
    </div>
  );
}
