import { PlatformsIcon } from "~/components/non-editable/core";
import { Footer1Props } from "../../../props";
import styles from "./social-icons-02.module.scss";

export default function SocialIconsVariant2(props: Footer1Props) {
  return (
    <div className={styles.container}>
      {(props?.linkFacebook?.value ?? props?.linkFacebook) && (
        <a
          href={String(props?.linkFacebook?.value ?? props?.linkFacebook)}
          target="_blank"
          className={styles["container__button"]}
          rel="noreferrer"
        >
          <PlatformsIcon width={20} height={20} icon="ic:baseline-facebook" />
        </a>
      )}
      {(props?.linkInstagram?.value ?? props?.linkInstagram) && (
        <a
          href={String(props?.linkInstagram?.value ?? props?.linkInstagram)}
          target="_blank"
          className={styles["container__button"]}
          rel="noreferrer"
        >
          <PlatformsIcon width={20} height={20} icon="ic:baseline-whatsapp" />
        </a>
      )}
    </div>
  );
}
