import React, { useRef } from "react";
import Image from "next/image";
import { FlipWords } from "~/components/non-editable/core/flip-words";
import { CTASectionProps } from "./props";
import { ArrowRight } from "lucide-react";
import { TextGenerateEffect } from "~/components/non-editable/ui/text-effects/text-appearing";
import { motion, useInView } from "framer-motion";

const CTASection: React.FC<CTASectionProps> = ({
  imageUrls,
  make,
  model,
  version,
  reverse = false,
  text,
  flipWords,
  useGradient = false,
  title,
  action,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div
      ref={ref}
      className={`pb-32 pt-14 md:pb-14 px-14 w-full ${
        useGradient
          ? "bg-gradient-to-t from-white to-secondary-500"
          : reverse && !useGradient
          ? "bg-white"
          : "bg-secondary-500"
      } `}
    >
      <div
        className={`max-w-[1500px] mx-auto h-full flex gap-10 ${
          reverse
            ? "flex-col-reverse md:flex-row-reverse"
            : "flex-col md:flex-row"
        } items-center justify-between w-full`}
      >
        {imageUrls.length > 1 ? (
          <div className="relative w-full min-w-64 min-h-52 mt-20 md:mt-5 flex justify-center items-center">
            <div
              id="1"
              className={`cursor-pointer absolute ${
                reverse
                  ? "left-[43%] md:left-[58%]"
                  : "-right-[11%] md:right-[25%]"
              } transform -translate-x-1/2 -rotate-12 transition-transform duration-300 hover:rotate-0 hover:z-20`}
            >
              <Image
                src={imageUrls[0]}
                alt={`Imagem do veiculo ${make} ${model} - ${version}`}
                width={800}
                height={600}
                className="max-w-96 md:max-w-72 rounded-lg shadow-lg"
              />
            </div>
            <div
              id="2"
              className={`cursor-pointer absolute ${
                reverse
                  ? "left-[50%] md:left-[65%]"
                  : "-right-[17%] md:right-[17%]"
              }  z-10 transform -translate-x-1/2 transition-transform duration-300 hover:rotate-0 hover:z-30`}
            >
              <Image
                src={imageUrls[1]}
                alt={`Imagem do veiculo ${make} ${model} - ${version}`}
                width={800}
                height={600}
                className="max-w-96 md:max-w-72 rounded-lg shadow-lg"
              />
            </div>
            <div
              id="3"
              className={`cursor-pointer absolute ${
                reverse
                  ? "left-[57%] md:left-[72%]"
                  : "-right-[25%] md:right-[10%]"
              }  transform -translate-x-1/2 rotate-12 transition-transform duration-300 hover:rotate-0 hover:z-20`}
            >
              <Image
                src={imageUrls[2]}
                alt={`Imagem do veiculo ${make} ${model} - ${version}`}
                width={800}
                height={600}
                className="max-w-96 md:max-w-72 rounded-lg shadow-lg"
              />
            </div>
          </div>
        ) : (
          <motion.div
            initial={{ scale: 1.08 }}
            animate={isInView ? { scale: 1.2 } : {}}
            transition={{ duration: 1, ease: "easeOut" }}
            className={`${!reverse ? "my-10 md:my-0" : "mt-10 md:mt-0"}`}
          >
            <Image
              src={imageUrls[0]}
              alt={`Imagem do veiculo ${make} ${model} - ${version}`}
              width={800}
              height={600}
              className="w-full max-w-3xl rounded-lg shadow-lg"
            />
          </motion.div>
        )}

        <div
          className={`w-full h-full max-w-2xl flex flex-col items-start justify-center text-white ${
            !reverse ? "pt-20" : ""
          }}`}
        >
          <TextGenerateEffect
            words={title ?? ""}
            duration={1}
            className={`${
              reverse && !useGradient ? "text-black" : "!text-black"
            } !text-[2.2rem]  mb-2`}
          />
          <span
            className={`text-lg md:text-sm font-normal ${
              reverse && !useGradient ? "text-black" : "!text-black"
            }`}
          >
            {text}
            {flipWords && flipWords.length > 0 && (
              <FlipWords
                className="text-xl font-normal text-black"
                words={flipWords}
              />
            )}
          </span>
          {action && (
            <button
              className="mt-5 px-6 py-3 bg-primary-800 text-white font-bold rounded-lg shadow-md hover:bg-primary-700 transition-colors flex items-center"
              onClick={action}
            >
              Solicitar Cotação <ArrowRight className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CTASection;
