"use client";

import { Vehicle } from "~/types";
import { InventoryProps } from "../../../../mock";
import styles from "./details-header-variant-01.module.scss";
import ShareButton from "~/components/non-editable/misc/share-button";
import { useGetLocalUrl } from "~/hooks/useGetOriginHref";

export default function DetailsHeaderVariant01(props: InventoryProps) {
  const { localUrl } = useGetLocalUrl();
  const vehicle =
    props && props.data && props.data.vehicle
      ? (props.data.vehicle as Vehicle)
      : null;

  return (
    <div className={`${styles.container} container-${props.id}`}>
      <div className={`${styles["container__row"]} container__row-${props.id}`}>
        <h1
          className={`${styles["container__row__heading"]} container__row__heading-${props.id}`}
        >
          {vehicle?.make} <span>{vehicle?.model}</span>
        </h1>
        <ShareButton path={localUrl} />
      </div>
      {vehicle?.type.toLowerCase() === "car" && <p>{vehicle?.version}</p>}
    </div>
  );
}
