"use client";

import { PlatformsIcon, PlatformsImage } from "~/components/non-editable/core";
import { PopUp1Props } from "../../props";
import styles from "./pop-up-variant-01.module.scss";
import { useEffect, useState } from "react";

export const PopUpVariant01 = (props: PopUp1Props) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const popUpClosed = localStorage.getItem("popUpClosed");
    if (!popUpClosed) {
      setOpen(true);
    }
  }, []);

  const handleClosePopUp = () => {
    setOpen(false);
    localStorage.setItem("popUpClosed", "true");
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClosePopUp();
    }
  };

  return (
    <>
      {open && (
        <section
          className={`${styles.overlay} overlay-${props.id}`}
          onClick={handleOverlayClick}
        >
          <div className={`${styles.container} container-${props.id}`}>
            <PlatformsIcon
              onClick={handleClosePopUp}
              className={`${styles.icon} icon-${props.id}`}
              icon="mingcute:close-fill"
              width={30}
              height={30}
              color="white"
            />
            <PlatformsImage
              src={props.image.value as string}
              height={500}
              width={500}
              alt="PopUp image"
            />
          </div>
        </section>
      )}
    </>
  );
};
