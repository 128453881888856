/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
"use client";
import { Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";

import styles from "../client-filter.module.scss";
import skeleton from "./skeleton.module.scss";

const ClientFilterSkeleton = ({ children }: PropsWithChildren) => {
  const mobileFiltersOpen = false;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <h1>Estoque</h1>

          <div className={styles["order-wrapper"]}>
            <div className={skeleton["order-skeleton"]} />
          </div>
        </div>

        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            onClose={() => {}}
            as="div"
            style={{ zIndex: 999 }}
            className="relative z-50 lg:hidden "
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="dark:bg-stone-900 bg-white ml-auto relative z-50 max-w-xs w-full h-full shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
                  <div className="px-4 flex items-center justify-between">
                    <h2 className="text-lg font-medium ">Filtros</h2>
                    <button
                      type="button"
                      className="-mr-2 w-10 h-10 p-2 rounded-sm flex items-center justify-center text-primary-900 dark:text-primary-50"
                    >
                      <Icon icon="material-symbols:close" />
                    </button>
                  </div>

                  <form className="mt-4 border-t border-primary-400">
                    {Array.from({ length: 9 }).map((_, idx) => (
                      <div
                        className={skeleton.container}
                        key={`item-skeleton-${idx}`}
                      >
                        <div className={skeleton["title-skeleton"]} />
                        <div className={skeleton["icon-skeleton"]} />
                      </div>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className={styles["filter-wrapper"]}>
          <aside className={`${styles["filter-content"]}`}>
            {Array.from({ length: 9 }).map((_, idx) => (
              <div className={skeleton.container} key={`item-skeleton-${idx}`}>
                <div className={skeleton["title-skeleton"]} />
                <div className={skeleton["icon-skeleton"]} />
              </div>
            ))}
          </aside>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ClientFilterSkeleton;
