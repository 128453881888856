import React from "react";
import { CheckCheck } from "lucide-react";
import { BrandNewVehicleItems } from "../props";
import { VehicleDetailsTableProps } from "./props";

const VehicleDetailsTable: React.FC<VehicleDetailsTableProps> = ({
  categoryName,
  items,
}) => {
  const orderByTrueAndRemoveDuplicates = (array: BrandNewVehicleItems[]) => {
    const sortedArray = array.sort((a, b) => {
      if (a.value === "Sim" && b.value !== "Sim") {
        return -1;
      }
      if (a.value !== "Sim" && b.value === "Sim") {
        return 1;
      }
      return 0;
    });

    const uniqueItemsMap = new Map<string, BrandNewVehicleItems>();

    sortedArray.forEach((item) => {
      if (!uniqueItemsMap.has(item.name)) {
        uniqueItemsMap.set(item.name, item);
      }
    });

    return Array.from(uniqueItemsMap.values());
  };

  return (
    <div className="space-y-4">
      <h2 className="text-lg md:text-sm font-semibold">{categoryName}</h2>
      <div className="overflow-x-auto">
        <table className="w-full text-sm rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-primary-800 text-white">
              <th className="text-left p-4 md:p-2 font-bold">Item</th>
              <th className="text-left p-4 md:p-2 font-bold">Descrição</th>
            </tr>
          </thead>
          <tbody>
            {orderByTrueAndRemoveDuplicates(items).map((item, index) => (
              <tr
                key={`item-${index}-${item.name}-${item.value}`}
                className={`border-b ${index % 2 === 1 ? "bg-primary-50" : ""}`}
              >
                <td className="p-2 text-[1rem] md:text-[0.7rem] font-semibold">
                  {item.name}
                </td>
                <td className="p-2 text-[1rem] md:text-[0.7rem] font-semibold">
                  {item.value === "Sim" ? (
                    <CheckCheck strokeWidth={1.5} color="green" />
                  ) : (
                    item.value
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VehicleDetailsTable;
